import { createRef, useMemo, useState } from "react";

import { PalButton, PalIconButton, PalTooltip, PalTypography } from "@palamar/fe-library";
import { withCardon } from "cardon";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { AccountCircle, Close, Done, List, OpenInNew } from "@mui/icons-material";
import { Alert, Box, Chip, Popover, Typography } from "@mui/material";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRLink from "~components/Generic/PRLink";
import PRModal from "~components/Generic/PRModal";
import PRTable from "~components/Generic/PRTable";
import { apiUrlCompany, userRole } from "~constants";
import { chatMessageTypeMap, chatMessageTypes } from "~constants/contactCenter";
import DialogHelper from "~helpers/DialogHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import { deleteDefaultMessage } from "~store/knowledgeBase/actions";
import { selectCurrentProject, selectProjects } from "~store/user/selectors";

const ProjectsPopoverButton = ({ projects = [] }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  // const userInfo = useSelector(selectUserInfo);
  const projectList = useSelector(selectProjects);

  const handleClick = (event) => {
    //stop event propagation
    event.stopPropagation();
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "ticket-popover" : undefined;

  const hasAnyPermissionForTargetProjects = projects?.some((p) => {
    const matchedProject = projectList?.find((project) => project.id === p.id);
    return matchedProject?.permissions?.length;
  });

  const hasExistAnyGrayedOutProject = !projects?.every((p) => {
    const matchedProject = projectList?.find((project) => project.id === p.id);
    return matchedProject?.permissions?.length;
  });
  return (
    <div>
      {!!projects.length && (
        <PalButton
          color={projects.length && hasAnyPermissionForTargetProjects ? "primary" : "secondary"}
          disabled={!projects.length}
          size="small"
          sx={{
            textDecoration: "underline",
          }}
          variant="text"
          onClick={handleClick}
        >
          {projects.length} {t("dashboard.companiesList.projects")}
        </PalButton>
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        id={id}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        <Box
          sx={{
            padding: 1,
            maxWidth: "320px",
            maxHeight: "50vh",
            overflowY: "auto",
            gap: 0.5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <Box>
            {t("dashboard.companiesList.projects")} ({projects.length})
            </Box> */}
          {hasExistAnyGrayedOutProject && (
            <Alert
              severity="info"
              sx={{
                padding: "0px 4px",
              }}
            >
              {t("dashboard.companiesList.someProjectsAreNotAccessible")}
            </Alert>
          )}

          {projects.map((item) => {
            let botId = item.settings?.main_bot;
            if (!botId) {
              botId = item.settings?.chatbot_list?.[0]?.id;
            }
            const matchedProject = projectList?.find((p) => p.id === item.id);

            const LinkComp = matchedProject?.permissions?.length ? PRLink : "div";
            return (
              <LinkComp key={item.id} newTab noPrefix to={`/dashboard/project/${item.id}/bot/${botId}/`}>
                <Box
                  sx={{
                    borderRadius: 0.5,
                    border: "1px solid #e0e0e0",
                    padding: 0.5,
                    display: "flex",
                    alignItems: "center",
                    cursor: matchedProject?.permissions?.length ? "pointer" : "not-allowed",
                    opacity: !matchedProject?.permissions?.length ? 0.7 : 1,
                    userSelect: "none",
                    color: (theme) => theme.palette.secondary.main,
                    gap: 0.5,
                    ".MuiChip-label": {
                      padding: "1px 4px",
                    },
                    ".MuiChip-root": {
                      borderRadius: "12px",
                      fontSize: "10px",
                      height: "auto",
                    },
                    flexFlow: "wrap",
                  }}
                >
                  <OpenInNew fontSize="small" />
                  <PalTypography noWrap variant="body1">
                    <PalTooltip title={item.name}>
                      <span>{item.name}</span>
                    </PalTooltip>
                  </PalTypography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 0.5,
                      flexFlow: "wrap",
                      "MuiChip-label": {
                        textDecoration: "none !important",
                      },
                    }}
                  >
                    {!!matchedProject?.permissions?.length &&
                      matchedProject?.permissions.map((role) => (
                        <Chip
                          key={role.role}
                          color={[userRole.admin].includes(role) ? "error" : "primary"}
                          label={role}
                          size="small"
                        />
                      ))}
                  </Box>
                </Box>
              </LinkComp>
            );
          })}
        </Box>
      </Popover>
    </div>
  );
};

const AccountUserDetailPopover = ({ account }) => {
  //show visualize per project and related roles for account
  const { t } = useTranslation();
  const [anchorElUserDetail, setAnchorElUserDetail] = useState(null);

  const handleClick = (event) => {
    //stop event propagation
    event.stopPropagation();
    setAnchorElUserDetail(event.currentTarget.parentElement);
  };

  const handleClose = (e) => {
    setAnchorElUserDetail(null);

    e.stopPropagation();
  };

  const openUserDetail = Boolean(anchorElUserDetail);
  const idUserDetail = openUserDetail ? "ticket-popover" : undefined;

  const groupedProjectRoles = account.project_roles.reduce((acc, role) => {
    if (!acc[role.project]) {
      acc[role.project] = [];
    }
    acc[role.project].push(role);
    return acc;
  }, {});
  const hasAnyProject = !!Object.keys(groupedProjectRoles).length;

  return (
    <div>
      <PalIconButton size="small" onClick={handleClick}>
        <List />
      </PalIconButton>
      <Popover
        anchorEl={anchorElUserDetail}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        id={idUserDetail}
        open={openUserDetail}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        <Box
          sx={{
            padding: 1,
            maxWidth: "320px",
            maxHeight: "50vh",
            overflowY: "auto",
            gap: 0.5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <PalTypography color="textSecondary" variant="body1">
              {t("common.fullName")}
            </PalTypography>
            <PalTypography variant="body2">
              {account.firstname} {account.lastname}
            </PalTypography>
          </Box>
          <Box>
            <PalTypography color="textSecondary" variant="body1">
              {t("common.email")}
            </PalTypography>
            <PalTypography variant="body2">{account.email}</PalTypography>
          </Box>
          <Box>
            <PalTypography color="textSecondary" variant="body1">
              {t("dashboard.user.2FAEnabled")}
            </PalTypography>
            <PalTypography color={account.two_factor_enabled ? "success" : "error"} variant="body2">
              {account.two_factor_enabled ? t("common.yes") : t("common.no")}
            </PalTypography>
          </Box>

          <Box>
            <PalTypography color="textSecondary" variant="body1">
              {t("dashboard.companiesList.projects")} ({Object.keys(groupedProjectRoles).length})
            </PalTypography>
          </Box>

          {hasAnyProject &&
            Object.keys(groupedProjectRoles).map((projectId) => {
              const projectRoles = groupedProjectRoles[projectId];
              return (
                <Box
                  key={projectId}
                  sx={{
                    borderRadius: 0.5,
                    border: "1px solid #e0e0e0",
                    padding: 0.5,
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    cursor: "pointer",
                    color: (theme) => theme.palette.secondary.main,
                    gap: 0.5,
                  }}
                >
                  <Typography noWrap variant="body1">
                    {projectRoles[0].project_name}
                  </Typography>

                  {!!projectRoles?.length && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 0.5,
                        flexFlow: "wrap",
                        ".MuiChip-label": {
                          padding: "1px 4px",
                        },
                        ".MuiChip-root": {
                          borderRadius: "12px",
                          fontSize: "10px",
                          height: "auto",
                        },
                      }}
                    >
                      <Typography noWrap variant="caption">
                        {t("dashboard.companiesList.roles")}:{" "}
                      </Typography>
                      {projectRoles.map((role) => (
                        <Chip
                          key={role.role}
                          color={[userRole.admin].includes(role.role) ? "error" : "primary"}
                          label={role.role}
                          size="small"
                        />
                      ))}
                    </Box>
                  )}
                </Box>
              );
            })}
        </Box>
      </Popover>
    </div>
  );
};

const AccountsPopoverButton = ({ accounts = [], projects = [] }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    //stop event propagation
    event.stopPropagation();
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "ticket-popover" : undefined;

  return (
    <div>
      {!!accounts.length && (
        <PalButton
          color={accounts.length ? "primary" : "secondary"}
          disabled={!accounts.length}
          size="small"
          sx={{
            textDecoration: "underline",
          }}
          variant="text"
          onClick={handleClick}
        >
          {accounts.length} {t("dashboard.companiesList.accounts")}
        </PalButton>
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        id={id}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        <Box
          sx={{
            padding: 1,
            maxWidth: "320px",
            maxHeight: "50vh",
            overflowY: "auto",
            gap: 0.5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <Box>
            {t("dashboard.companiesList.projects")} ({projects.length})
            </Box> */}
          {accounts.map((item) => {
            const relatedProjectRoles = item.project_roles?.filter((role) => role.project === item.project);
            return (
              <Box
                key={item.id}
                sx={{
                  borderRadius: 0.5,
                  border: "1px solid #e0e0e0",
                  padding: 0.5,
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: (theme) => theme.palette.secondary.main,
                  gap: 0.5,
                }}
              >
                <AccountCircle />
                <Typography noWrap variant="body1">
                  <PalTooltip title={`${item.firstname} ${item.lastname} (${item.email})`}>
                    <span>
                      {item.firstname} {item.lastname} ({item.email})
                    </span>
                  </PalTooltip>
                </Typography>
                <AccountUserDetailPopover account={item} />
                {/* <PalIconButton>
                  <List />
                </PalIconButton> */}
              </Box>
            );
          })}
        </Box>
      </Popover>
    </div>
  );
};

function CompaniesList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = createRef();
  const currentProject = useSelector(selectCurrentProject);

  const isAdmin = useMemo(() => currentProject?.permissions?.includes(userRole.admin), [currentProject]);

  const actions = useMemo(() => {
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        link: "/settings/companies/form/",
      },
    ];
  }, [t]);

  const columns = useMemo(() => {
    const handleClickDelete = (row) => async () => {
      if (await DialogHelper.showQuestionDeleteWithFormat(row.title)) {
        dispatch(deleteDefaultMessage(currentProject.id, row.id)).then(() => {
          tableRef.current.refresh();
        });
      }
    };

    const handleClickAddProject = (row) => async () => {
      HistoryHelper.push({
        pathname: `/dashboard/setup/`,
        search: `?company=${row.id}`,
      });
    };
    return [
      {
        label: t("common.name"),
        key: "name",
      },
      {
        label: t("common.active"),
        key: "active",
        render: (row) => {
          return (
            <Box
              sx={{
                svg: {
                  fontSize: "1.25rem",
                  fill: row.active ? (theme) => theme.palette.success.main : (theme) => theme.palette.error.main,
                },
              }}
            >
              {row.active ? <Done /> : <Close />}
            </Box>
          );
        },
      },
      {
        label: t("dashboard.companiesList.allowedMailDomains"),
        key: "allowed_mail_domains",
        render: (row) => {
          return (
            <>
              {row.allowed_mail_domains.map((domain) => (
                <Chip key={domain} color={"default"} label={domain} size="small" />
              ))}
            </>
          );
        },
      },
      {
        label: t("dashboard.companiesList.projects"),
        key: "projects",
        render: (row) => {
          return (
            <>
              <ProjectsPopoverButton projects={row.projects} />
            </>
          );
        },
      },
      {
        label: t("dashboard.companiesList.accounts"),
        key: "projects",
        render: (row) => {
          return (
            <>
              <AccountsPopoverButton accounts={row.accounts} projects={row.projects} />
            </>
          );
        },
      },
      // {
      //   label: t("common.updated"),
      //   key: "updated",
      //   render: (row) =>
      //     row.updated ? (
      //       <PalTooltip title={DateHelper.getDateTimeLocal(row.updated).fromNow()}>
      //         {DateHelper.getDateTimeLocal(row.updated).format("LLL")}
      //       </PalTooltip>
      //     ) : (
      //       "-"
      //     ),
      // },
      {
        label: t("dashboard.chatMessagesList.messageType"),
        key: "message_type",
        render: (row) => chatMessageTypeMap[row.message_type] || row?.message_type || "-",
      },
      // {
      //   label: "Create date",
      //   key: "created",
      //   render: (row) => DateHelper.getDateTimeLocal(row.created).format("LLL"),
      // },
      {
        label: t("common.actions"),
        key: "actions",
        fixed: "right",
        actions: true,
        render: (row) => (
          <div className="d-flex gap-1 justify-content-center">
            <PRButton
              outline
              color="success"
              icon={MdAdd}
              size="sm"
              tooltipText={t("dashboard.companiesList.addProject")}
              onClick={handleClickAddProject(row)}
            />
            {(row?.message_type === chatMessageTypes.PRIVATE || isAdmin) && (
              <PRButton
                outline
                color="primary"
                icon={MdEdit}
                link={`/settings/companies/form/${row.id}`}
                size="sm"
                tooltipText={t("common.edit")}
              />
            )}

            <PRButton
              outline
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText={t("common.delete")}
              onClick={handleClickDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [currentProject.id, dispatch, tableRef, isAdmin, t]);
  return (
    <PRContainer actions={actions} name={t("common.settings")} parentName={t("dashboard.companies")}>
      <PRTable
        ref={tableRef}
        className="" //
        columns={columns}
        url={apiUrlCompany.get.format(currentProject.id)}
      />
    </PRContainer>
  );
}

export default CompaniesList;

const InfoModal = withCardon(
  function InfoModal({ get, item }) {
    return (
      <PRModal size="xl" submitText="" title={t("dashboard.chatMessagesList.infoModalTitle")} onClose={get(false)}>
        {item.data}
      </PRModal>
    );
  },
  { destroyOnHide: true }
);
