import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from "react";

import { PalButton } from "@palamar/fe-library";
import chroma from "chroma-js";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";

import styled from "@emotion/styled";
import { AutoAwesome, TipsAndUpdates } from "@mui/icons-material";
import { Box, Popover, Zoom } from "@mui/material";

import useQueryParams from "~common/hooks/useQueryParams";
import PRTooltip from "~components/Generic/PRTooltip";
import PalIconButton from "~components/mui/PalIconButton";
import PalTooltip from "~components/mui/PalTooltip";
import { useSessionChatTranslateOnOff } from "~components/Session/SessionChat";
import { chatbotLikeDislikeType, trendyolDeliveryStatus } from "~constants";
import DateHelper from "~helpers/DateHelper";
import StyledHelper from "~helpers/StyledHelper";

import EmoteWheel from "./EmoteItem";
import ChatFilePreviewItem from "../../ChatFilePreviewItem";
import { renderText, urlify } from "../../ChatUtils";
import { usePRChatOptionsContext, usePRMessagesContext, usePRRefContext } from "../../context";

import "./style.scss";

// function SendStatusWrapper({ children, isSent, position }) {
//   const [sendStatus, setSendStatus] = useState(isSent);

//   useDebouncedEffect(
//     () => {
//       setSendStatus(isSent);
//     },
//     [isSent],
//     250
//   );

//   let primaryColor;

//   //check if bright or dark
//   const lum = chroma(theme.colorPrimary).luminance();
//   if (lum > 0.25) {
//     primaryColor = chroma(theme.colorPrimary).brighten(1.0).hex();
//   } else {
//     primaryColor = chroma(theme.colorPrimary).brighten(2.0).hex();
//   }
//   return (
//     <div className="position-relative col">
//       {position === "right" && (
//         <span
//           className={classNames("position-absolute bottom-0", {
//             "end-0": position === "right",
//             "start-0": position === "left",
//           })}
//           style={{
//             marginBottom: "0.1rem",
//             zIndex: 10 + 1,
//             color: "var(--bs-gray-600)",
//             ...(position === "right"
//               ? {
//                   marginRight: "0.25rem",
//                   color: primaryColor,
//                 }
//               : { marginLeft: "0.25rem" }),
//           }}
//         >
//           {sendStatus ? <FaCheck className="fs-6 " /> : <BiLoaderAlt className="fs-5 spin" />}
//         </span>
//       )}
//       {children}
//     </div>
//   );
// }

const ReactionDiv = styled("div", { shouldForwardProp: (prop) => prop !== "wide" })`
  overflow: hidden;
  width: 36px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 0px #0000000a;
  border: 1px solid #e8e8e8;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  /* bottom: calc(-36px + 10px); */
  bottom: calc(-36px + 7px);
  left: 25px;
  ${(props) => (props.wide ? "width: 72px; left: 12.5px;" : "")}
  transition: all 0.2s ease-in-out;
`;

const EmoteButton = styled(PalIconButton, StyledHelper.sfw(["noClickable"]))`
  &.MuiIconButton-root.MuiIconButton-sizeMedium {
    width: 36px;
    height: 36px;
    padding: 0;
    color: #000000;
  }

  ${(props) => (props.noClickable ? "pointer-events: none;" : "")}
`;
function MessageLikeDislikeBase({
  clickable,
  internalLikeStatus,
  setInternalLikeStatus,
  messageId,
  position,
  likeStatus,
  onClick,
  reverseChat,
}) {
  const { hideLikeDislike, readonlyLikeDislike } = usePRChatOptionsContext();

  const { t } = useTranslation();

  useEffect(() => {
    const likeStatusNumber = Number(likeStatus);
    if (likeStatusNumber > -1) {
      // setInternalLikeStatus(likeStatus ? Number(likeStatus) : chatbotLikeDislikeType.unknown);
      setInternalLikeStatus(likeStatusNumber);
    }
  }, [likeStatus, setInternalLikeStatus]);

  if (!messageId || position === (reverseChat ? "left" : "right") || hideLikeDislike) return null;

  const isLiked = internalLikeStatus === chatbotLikeDislikeType.like;
  const isDisliked = internalLikeStatus === chatbotLikeDislikeType.dislike;
  const isLikedOrDisliked = isLiked || isDisliked;

  const showLike = (!readonlyLikeDislike && !isLikedOrDisliked && clickable) || isLiked;
  const showDislike = (!readonlyLikeDislike && !isLikedOrDisliked && clickable) || isDisliked;
  const handleClick = (key) => () => {
    const isRemove = isLikedOrDisliked;
    onClick?.(isRemove ? chatbotLikeDislikeType.initial : key);
  };

  if (!isLikedOrDisliked && (!clickable || readonlyLikeDislike)) return null;
  return (
    <ReactionDiv style={{ zIndex: 12 }} wide={!isLikedOrDisliked && clickable}>
      <Zoom direction="right" in={!!showLike}>
        <span>
          <PRTooltip delay={1000} title={t("chatbot.like")}>
            <Box
              sx={{
                ...(isLikedOrDisliked && showLike && { marginLeft: "36px" }),
              }}
            >
              <EmoteButton
                disableRipple
                noClickable={readonlyLikeDislike}
                onClick={handleClick(chatbotLikeDislikeType.like)}
              >
                👍
              </EmoteButton>
            </Box>
          </PRTooltip>
        </span>
      </Zoom>
      <Zoom direction="left" in={!!showDislike}>
        <span>
          <PRTooltip delay={1000} title={t("chatbot.dislike")}>
            <Box
              sx={{
                ...(isLikedOrDisliked && showDislike && { marginLeft: "-36px" }),
              }}
            >
              <EmoteButton
                disableRipple
                noClickable={readonlyLikeDislike}
                onClick={handleClick(chatbotLikeDislikeType.dislike)}
              >
                👎
              </EmoteButton>
            </Box>
          </PRTooltip>
        </span>
      </Zoom>
    </ReactionDiv>
  );
}
const MessageLikeDislike = memo(MessageLikeDislikeBase);

const AnimatedButton = styled(PalButton)`
  @property --bg-angle {
    inherits: false;
    initial-value: 0deg;
    syntax: "<angle>";
  }

  @keyframes spin {
    to {
      --bg-angle: 360deg;
    }
  }

  animation: spin 2.5s infinite linear paused;
  padding: 0 4px;
  color: ${(props) => props.theme.palette.secondary.dark};
  font-size: 0.7rem;
  font-weight: 600;
  margin-top: ${(props) => props.theme.spacing(0.5)};

  background:
    linear-gradient(to bottom, #fff, #fff) padding-box,
    conic-gradient(from var(--bg-angle) in oklch longer hue, oklch(0.65 0.37 0) 0 0) border-box;

  border: 1px solid transparent;
  text-align: center;
  &:hover {
    animation-play-state: running;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const StyledBalloonDiv = styled("div")`
  max-width: 100%;

  display: flex;
  align-items: center;
  min-width: 70px;
  min-height: 20px;
  font-size: 16px;
  line-height: 18px;

  ${(props) => props.extraBottomSpace && "margin-bottom: 30px;"}

  position: relative;
  /* padding: 10px 15px 20px; */
  padding: 6px 12px 6px;
  z-index: 10;
  display: flex;
  justify-content: center;
  border-radius: 14px;
  border-bottom-left-radius: 0px;

  box-shadow: ${(props) => props.theme.palette.chat.balloon.shadow};

  ${(props) => {
    let backgroundColor =
      props.position === "right"
        ? props.theme.palette.chat.balloon.customer.backgroundColor
        : props.sender === "AGENT"
          ? props.theme.palette.chat.balloon.agent.backgroundColor
          : props.theme.palette.chat.balloon.bot.backgroundColor;
    let color =
      props.position === "right"
        ? props.theme.palette.chat.balloon.customer.color
        : props.sender === "AGENT"
          ? props.theme.palette.chat.balloon.agent.color
          : props.theme.palette.chat.balloon.bot.color;

    const border =
      props.position === "right"
        ? props.theme.palette.chat.balloon.customer.border
        : props.sender === "AGENT"
          ? props.theme.palette.chat.balloon.agent.border
          : props.theme.palette.chat.balloon.bot.border;

    if (props.isSuggestionMessage) {
      backgroundColor = chroma(backgroundColor).alpha(0.5).css();
      color = chroma(color).alpha(0.5).css();
    }
    return `
        background-color: ${backgroundColor};
        color: ${color};
        border: ${border};
      `;
  }}
  &.balloon-right {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 0px;
  }
  &.balloon-center {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }
  &.balloon--no-background {
    background: transparent;
  }

  @keyframes highlightAnimation {
    0% {
      box-shadow: 0 0 4px 2px rgba(255, 0, 0, 0.2);
    }
    25% {
      box-shadow: 0 0 4px 2px rgba(0, 255, 0, 0.2);
    }
    50% {
      box-shadow: 0 0 4px 2px rgba(0, 0, 255, 0.2);
    }
    75% {
      box-shadow: 0 0 4px 2px rgba(255, 255, 0, 0.2);
    }
    100% {
      box-shadow: 0 0 4px 2px rgba(255, 0, 0, 0.2);
    }
  }
  &.balloon--highlight {
    animation: highlightAnimation 10s ease-in-out infinite;
  }

  .balloon_footer {
    margin-left: auto;
    display: flex;
    @keyframes animateGlow {
      0% {
        background-position: 0% 50%;
      }
      10% {
        opacity: 1;
      }
      /* 23% {
      } */
      30.1% {
        opacity: 0;
        background-position: 200% 50%;
      }
      90.9% {
        opacity: 0;
        background-position: 0% 50%;
      }
      100% {
        opacity: 1;
      }
    }

    padding-left: 5px;
    @keyframes glowing {
      0% {
        background-position: 0 0;
      }
      50% {
        background-position: 400% 0;
      }
      100% {
        background-position: 0 0;
      }
    }
    .balloon__rag {
      margin-right: 2px;
      position: relative;

      width: 16px;
      height: 16px;
      border: none;
      outline: none;
      position: relative;

      &::before {
        content: "";
        background: linear-gradient(
          45deg,
          #ff0000,
          #ff7300,
          #fffb00,
          #48ff00,
          #00ffd5,
          #002bff,
          #7a00ff,
          #ff00c8,
          #ff0000
        );
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background-size: 400%;
        z-index: -1;
        filter: blur(2.5px);
        width: 8px;
        height: 8px;

        animation: glowing 30s linear infinite;
        opacity: 0.3;
        transition: opacity 0.3s ease-in-out;
      }
      &::after {
        z-index: -1;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }

      .MuiSvgIcon-root {
        color: #28aae1;
        font-size: 14px;
        margin-top: -5px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01);
      }
    }
    .balloon__suggestion {
      margin-right: 2px;
      position: relative;

      width: 16px;
      height: 16px;
      border: none;
      outline: none;
      position: relative;
      .MuiSvgIcon-root {
        color: #aaddf3;
        font-size: 14px;
        margin-top: -5px;
        filter: drop-shadow(1px 1px 5px #666);
      }
    }
    .balloon__time {
      font: normal normal normal 13px/16px Museo Sans;
      letter-spacing: 0px;
      ${(props) => {
        let ballonBackgroundColor =
          props.position === "right"
            ? props.theme.palette.chat.balloon.customer.backgroundColor
            : props.sender === "AGENT"
              ? props.theme.palette.chat.balloon.agent.backgroundColor
              : props.theme.palette.chat.balloon.bot.backgroundColor;

        let timeColor =
          props.position === "right"
            ? props.theme.palette.chat.balloon.customer.color
            : props.sender === "AGENT"
              ? props.theme.palette.chat.balloon.agent.color
              : props.theme.palette.chat.balloon.bot.color;

        timeColor = chroma(timeColor).alpha(Number(props.theme.palette.chat.balloon.timeOpacity));

        const minContrast = 4.5; // Min contrast value for WCAG 2.0
        let contrast = chroma.contrast(ballonBackgroundColor, timeColor);

        if (contrast < minContrast) {
          // Set the color to black or white based on the contrast
          const contrastWithBlack = chroma.contrast(ballonBackgroundColor, "#000");
          const contrastWithWhite = chroma.contrast(ballonBackgroundColor, "#fff");

          if (contrastWithBlack > contrastWithWhite) {
            timeColor = chroma("#000").alpha(Number(props.theme.palette.chat.balloon.timeOpacity));
          } else {
            timeColor = chroma("#fff").alpha(Number(props.theme.palette.chat.balloon.timeOpacity));
          }
        }

        return `
        color: ${timeColor.css()};
    `;
      }}
    }
  }

  /* .markdown-wrapper {
    color: ${(props) => (props.position === "right" ? props.theme.palette.primary.contrastText : "inherit")};
  } */
  /* .text {
    a {
      text-decoration: underline !important;
      color: ${(props) =>
    props.position === "right" ? props.theme.palette.primary.contrastText : props.theme.palette.primary.dark};
    }
  } */
`;

function useHasMessageOnRightSide() {
  const messages = usePRMessagesContext();
  const [hasAnyMessage, setHasAnyMessage] = useState(false);

  useEffect(() => {
    if (messages?.length) {
      const hasAnyCustomerMsg = messages?.find((msg) => msg?.position === "right");
      if (!!hasAnyCustomerMsg !== hasAnyMessage) {
        setHasAnyMessage(!!hasAnyCustomerMsg);
      }
    }
  }, [messages, hasAnyMessage]);
  return hasAnyMessage;
}

const StyledTextBox = styled(Box, StyledHelper.sfw(["position", "sender"]))`
  color: ${({ theme, position, sender }) =>
    position === "right"
      ? theme.palette.chat.balloon.customer.color
      : sender === "AGENT"
        ? theme.palette.chat.balloon.agent.color
        : theme.palette.chat.balloon.bot.color};
  max-width: 100%;
`;

const StyledTranslatedButton = styled.a`
  /* color: #28aae1; */

  color: ${({ theme, position, sender }) =>
    position === "right"
      ? theme.palette.chat.balloon.customer.color
      : sender === "AGENT"
        ? theme.palette.chat.balloon.agent.color
        : theme.palette.chat.balloon.bot.color};
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
`;
function SimpleMessage(
  {
    text,
    children,
    position,
    className,
    onClick,
    messageTime,
    background = true,
    headerComponent,
    footerComponent,
    style,
    format,
    isSent,
    messageId,
    likeStatus,
    historyId,
    isLast,
    reverseChat,
    sender,
    isRag,
    isImage,
    isSuggestion,
    trendyolMsg,
    textDetails,
    attachments,
  },
  ref
) {
  const { messageId: selectedMessageId } = useQueryParams();
  const dispatch = useDispatch();
  //TODO: move this option to PRChat options
  const sessionChatTranslateOnOff = useSessionChatTranslateOnOff();

  const distributedRef = usePRRefContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [internalLikeStatus, setInternalLikeStatus] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const [isEmoteVisible, setIsEmoteVisible] = useState(false);
  const hasMessageOnRightSide = useHasMessageOnRightSide();
  const [showOriginalText, setShowOriginalText] = useState(false);
  const { t } = useTranslation();

  const isSuggestionMessage = useMemo(
    () => trendyolMsg?.deliveryStatus === trendyolDeliveryStatus.SUGGESTED || isSuggestion,
    [trendyolMsg, isSuggestion]
  );

  const { disableTextInput, disableDislike, disableLike } = usePRChatOptionsContext();
  const handleEnterLeave = (status) => () => {
    setIsHovered(status);
  };

  const handleClickEmote = (e) => {
    setIsEmoteVisible(!isEmoteVisible);
    setAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    setShowOriginalText(sessionChatTranslateOnOff);
  }, [sessionChatTranslateOnOff]);

  // format === "plain" ? urlify(text, position === "right" && Utils.determineTextColor(theme.colorPrimary)) : text;

  // const rightBalloonBackground = theme.palette.primary.light && chroma(theme.palette.primary.main).brighten(1.5).hex();

  const isReactionDisabled =
    // likeStatus === chatbotLikeDislikeType.like ||
    // likeStatus === chatbotLikeDislikeType.dislike ||
    disableTextInput || likeStatus === chatbotLikeDislikeType.unknown;
  const isExtraMarginForBottomLike = hasMessageOnRightSide && isLast && (messageId || historyId);
  const isEmoteWheelVisible =
    position === "left" &&
    !disableTextInput &&
    !(isLast && hasMessageOnRightSide) &&
    likeStatus !== chatbotLikeDislikeType.like &&
    likeStatus !== chatbotLikeDislikeType.dislike;

  useEffect(() => {
    if (!isHovered) {
      setIsEmoteVisible(false);
    }
  }, [isHovered]);

  const handleClickLikeDislike = useCallback(
    (likeStatus) => () => {
      distributedRef.current.disableScrollOnMessage = true;

      setInternalLikeStatus(likeStatus);
      setIsEmoteVisible(false);
      setAnchorEl(null);
      // dispatch(wsOut.action.submitMessageFeedback(historyId || messageId, likeStatus));

      setTimeout(
        () => {
          distributedRef.current.disableScrollOnMessage = false;
        },
        //Animation duration
        250
      );
    },
    [messageId, historyId, dispatch, distributedRef]
  );
  const handleClickFooterLikeDislike = useCallback(
    (likeStatus) => {
      handleClickLikeDislike(likeStatus)();
    },
    [handleClickLikeDislike]
  );

  const handleClose = () => {
    // setIsEmoteVisible(false);
    setAnchorEl(null);
  };
  const hourInfo = useMemo(() => DateHelper.getDateTimeLocal(messageTime).format("LT"), [messageTime]);

  const isLikeDislikeComponentVisible = !disableLike || !disableDislike;

  const isLikedOrDisliked =
    internalLikeStatus === chatbotLikeDislikeType.like || internalLikeStatus === chatbotLikeDislikeType.dislike;
  // const isLikeDislikeAlwaysVisible = isLast && !isReactionDisabled;

  const enableExtraMarginForLastMessage = (isExtraMarginForBottomLike || isLikedOrDisliked) && position === "left";

  const handleClickApplySuggestion = () => {
    distributedRef.current.setText(text);
    distributedRef.current.trendyolMsg = { ...trendyolMsg, historyId };
  };

  const handleClickToggleOriginalText = () => {
    setShowOriginalText(!showOriginalText);
  };

  const isTranslated = !!textDetails?.sessionText && !!text && textDetails?.sessionText !== text;
  const showingCurrentText = useMemo(() => {
    if (!isTranslated) return text;
    return (showOriginalText ? textDetails?.sessionText : text) || text;
  }, [text, isTranslated, textDetails, showOriginalText]);

  const content = useMemo(() => {
    return format === "plain" ? urlify(showingCurrentText) : showingCurrentText;
  }, [format, showingCurrentText]);

  const originalLanguage = (textDetails?.sessionLanguage || "").toUpperCase();
  const sessionLanguage = (textDetails?.translatedLanguage || "").toUpperCase();
  return (
    <div
      ref={ref}
      className="position-relative"
      onMouseEnter={handleEnterLeave(true)}
      onMouseLeave={handleEnterLeave(false)}
    >
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        elevation={0}
        open={!!anchorEl}
        slotProps={{
          paper: {
            style: {
              backgroundColor: "transparent",
              border: "none",
              borderRadius: "24px",
              // overflow: "visible",
              overflow: "hidden",
            },
          },
        }}
        transformOrigin={{ vertical: 102, horizontal: "center" }}
        onClose={handleClose}
      >
        <Box
          sx={{
            display: "flex",
            background: "#F8F8F8 0% 0% no-repeat padding-box",
            boxShadow: "2px 2px 0px #0000000A",
            border: "1px solid #E8E8E8",
            borderRadius: 24,
            // position: "absolute",
            // top: -54,
            // right: 0,
            // height: 47,
            alignItems: "center",
            justifyContent: "center",

            gap: "3px",
            padding: "3px 3px",
          }}
        >
          <PalIconButton
            className="d-flex align-items-center justify-content-center"
            color="secondary"
            sx={{
              background: "#f8f8f8",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                border: "1px solid #E8E8E8",
              },
              borderRadius: "100%",
              boxSize: "border-box",
              fontSize: 26,
              lineHeight: 20,
              "&.MuiIconButton-sizeMedium": {
                width: 40,
                height: 40,
              },
            }}
            onClick={handleClickLikeDislike(chatbotLikeDislikeType.like)}
          >
            👍
          </PalIconButton>
          <PalIconButton
            className="d-flex align-items-center justify-content-center"
            color="secondary"
            sx={{
              background: "#f8f8f8",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                border: "1px solid #E8E8E8",
              },
              borderRadius: "100%",
              boxSize: "border-box",
              fontSize: 26,
              lineHeight: 20,
              "&.MuiIconButton-sizeMedium": {
                width: 40,
                height: 40,
              },
            }}
            onClick={handleClickLikeDislike(chatbotLikeDislikeType.dislike)}
          >
            👎
          </PalIconButton>
        </Box>
      </Popover>
      <Row
        className={classNames("pr-simple-message", "flex-nowrap", "g-0", `pr-simple-message-${format}`)}
        style={{
          display: "flex",
          flexDirection: position === "left" ? "row" : "row-reverse",
          alignItems: "center",
          columnGap: "5px",
          ...style,
        }}
      >
        <Col
          xs
          className={classNames({
            "d-flex": true,
            "flex-column": true,
            "align-items-start": position === "left",
            "align-items-end": position === "right",
          })}
        >
          {headerComponent}
          {/* <SendStatusWrapper isSent={isSent} position={position}> */}
          <StyledBalloonDiv
            // extraBottomSpace={likeStatus === chatbotLikeDislikeType.like || likeStatus === chatbotLikeDislikeType.dislike || isLast}
            className={classNames(
              // `balloon-${format}`,
              {
                "balloon-right": position === "right",
                "balloon-center": position === "center",
                "balloon--no-background": !background,
                "balloon--highlight": messageId && Number(selectedMessageId) === Number(messageId),
              },
              className
            )}
            extraBottomSpace={enableExtraMarginForLastMessage}
            isSuggestionMessage={isSuggestionMessage}
            position={position}
            sender={sender}
            style={{
              alignItems: "flex-end",
              flexWrap: "wrap",
              justifyContent: "left",
              ...((isImage || attachments?.length) && {
                flexDirection: "column",
                gap: "5px",
              }),
            }}
            onClick={onClick}
          >
            {!!text && (
              <StyledTextBox
                className={classNames("title markdown-wrapper", {
                  text: format === "plain",
                })}
                position={position}
                sender={sender}
              >
                {renderText(content, format, { position, sender })}
                {isTranslated && (
                  <PalTooltip
                    title={
                      showOriginalText
                        ? t("chatbot.showTranslatedHoverText").format(originalLanguage, sessionLanguage)
                        : t("chatbot.showOriginalHoverText").format(sessionLanguage, originalLanguage)
                    }
                  >
                    <StyledTranslatedButton position={position} sender={sender} onClick={handleClickToggleOriginalText}>
                      {/* Show Translated */}
                      {showOriginalText //
                        ? t("chatbot.showTranslatedText")
                        : t("chatbot.showOriginalText")}
                    </StyledTranslatedButton>
                  </PalTooltip>
                )}
              </StyledTextBox>
            )}
            {!!attachments?.length && (
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                {attachments.map((attachment, index) => {
                  return <ChatFilePreviewItem key={index} wide file={attachment} />;
                })}
              </Box>
            )}
            {children}

            <div className="balloon_footer">
              {isRag && (
                <PalTooltip title={t("chatbot.rag")}>
                  <span className="balloon__rag">
                    <AutoAwesome />
                  </span>
                </PalTooltip>
              )}
              {!isRag && isSuggestionMessage && (
                <PalTooltip title={t("chatbot.suggestion")}>
                  <span className="balloon__suggestion">
                    <TipsAndUpdates />
                  </span>
                </PalTooltip>
              )}
              <PalTooltip
                title={
                  DateHelper.getDateTimeLocal(messageTime).format("L") +
                  " " +
                  DateHelper.getDateTimeLocal(messageTime).format("LT")
                }
              >
                <span className="balloon__time">{hourInfo}</span>
              </PalTooltip>
            </div>

            {isLikeDislikeComponentVisible && (
              <MessageLikeDislike
                clickable={isExtraMarginForBottomLike}
                internalLikeStatus={internalLikeStatus}
                isSent={isSent}
                likeStatus={likeStatus}
                messageId={messageId}
                position={position}
                reverseChat={reverseChat}
                setInternalLikeStatus={setInternalLikeStatus}
                onClick={handleClickFooterLikeDislike}
              />
            )}
          </StyledBalloonDiv>
          {/* </SendStatusWrapper> */}

          {isSuggestionMessage && !trendyolMsg.disableSuggestionButton && (
            <AnimatedButton onClick={handleClickApplySuggestion}>
              <span>{t("chatbot.useSuggestion")}</span>
            </AnimatedButton>
          )}
          {footerComponent}
        </Col>
        <Col className="overflow-hidden" xs="auto">
          <Box visibility={isEmoteWheelVisible && isLikeDislikeComponentVisible ? "visible" : "hidden"}>
            <EmoteWheel
              dateTime={messageTime}
              disabled={isReactionDisabled || !messageId}
              position={position}
              setAnchorEl={setAnchorEl}
              visible={isHovered}
              onToggleEmote={handleClickEmote}
            />
          </Box>
        </Col>
      </Row>
    </div>
  );
}

export default forwardRef(SimpleMessage);
