import { Fragment, useEffect, useMemo, useRef, useState } from "react";

import {
  PalAutoComplete,
  PalButton,
  PalCodeField,
  PalIconButton,
  PalMenu,
  PalMenuItem,
  PalPagination,
  PalSelect,
  PalSwitch,
  PalTextField,
  PalTimePicker,
  PalTooltip,
  PalTypography,
} from "@palamar/fe-library";
import { withCardon } from "cardon";
import chroma from "chroma-js";
import { useFormik } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";

import styled from "@emotion/styled";
import {
  Add,
  Cancel,
  CloudQueue,
  Delete,
  DescriptionOutlined,
  Done,
  Edit,
  ErrorOutline,
  Help,
  Info,
  Language,
  Menu,
  MoreVert,
  NavigateBefore,
  NavigateNext,
  PanoramaFishEye,
  Settings,
  SupportAgent,
  TaskAlt,
  TextFields,
  Widgets,
} from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  Fade,
  FormControlLabel,
  FormHelperText,
  Grid,
  lighten,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";

import { getBotSettings, updateBotSettings } from "~apiRequests/bot";
import { getIntentDetails } from "~apiRequests/intent";
import { getInvitedUsers, postInviteUser } from "~apiRequests/permissions";
import { ReactComponent as FacebookMessengerIcon } from "~assets/images/icons/facebook-messenger.svg";
import { ReactComponent as InstagramIcon } from "~assets/images/icons/instagram.svg";
import { ReactComponent as TelegramIcon } from "~assets/images/icons/telegram.svg";
import { ReactComponent as WhatsAppIcon } from "~assets/images/icons/whatsapp.svg";
import PalamarLogo from "~assets/images/logo-filled-notext.svg";
import { ReactComponent as Img1 } from "~assets/images/onboarding/1.svg";
import { ReactComponent as Logo } from "~assets/images/palmate-logo.svg";
import LegoLottie from "~assets/lottie/lego.json";
import useQueryParams from "~common/hooks/useQueryParams";
import IntegrationLoadingModal from "~common/modals/IntegrationLoadingModal";
import PRDropZone from "~components/Generic/PRDropZone";
import PRModal from "~components/Generic/PRModal";
import PRProfile, { PRProfileLottie } from "~components/Generic/PRProfile";
import PalLink from "~components/mui/PalLink";
import {
  dialogComponentsRagFactGroupStatus,
  eCommerceDefinitionList,
  eCommerceProject,
  OnboardingContentSeparator,
  onboardingProjectStatus,
  onboardingRagStatus,
  onboardingRagStatusMap,
  onboardingRagType,
  onboardingStatus,
  popupIconType,
  popupIconTypeOptions,
  popupPositionOptions,
  popupSettingsMapper,
  projectLanguageOptions,
  userRole,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import FileHelper from "~helpers/FileHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import LoadingHelper from "~helpers/LoadingHelper";
import StyledHelper from "~helpers/StyledHelper";
import ThemeMapper from "~helpers/ThemeMapper";
import Utils from "~helpers/Utils";
import {
  getRagFactGroup,
  postMessageToRag,
  processRag,
  stopRag,
} from "~store/dialogComponents/scenarioManager/actions";
import {
  createOrUpdateSourceFile,
  createOrUpdateSourceWebPage,
  deleteSourceFile,
  deleteSourceWebPage,
  getRagState,
  getSourceFile,
  getSourceWebPage,
  updateFacebookMessengerOnboarding,
  updateInstagramOnboarding,
  updateOnboardingStatus,
  updateRagState,
  updateWhatsappOnboarding,
} from "~store/onboarding/actions";
import { createOrUpdateChannel, getChannelList } from "~store/settings/channels/actions";
import { selectChannelList } from "~store/settings/channels/selectors";
import { createOrUpdateCompany, getCompany } from "~store/settings/company/actions";
import { selectCompany } from "~store/settings/company/selectors";
import { createOrUpdateECommerceIntegration, getECommerceList } from "~store/settings/eCommerce/actions";
import { selectECommerceList } from "~store/settings/eCommerce/selectors";
import { getPopupSettings, initPopupSettingsState, patchPopupSettings } from "~store/settings/popupSettings/actions";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import {
  createOrUpdateProject,
  getProject,
  getProjectLanguages,
  getProjectTemplates,
  updateProjectSettings,
} from "~store/settings/projectSettings/actions";
import {
  selectProject,
  selectProjectLanguages,
  selectProjectTemplates,
} from "~store/settings/projectSettings/selectors";
import { getCallcenterSettings, updateCallcenterSettings } from "~store/socket/livechat/actions";
import { selectCallcenterSettings } from "~store/socket/livechat/selectors";
import { selectUserInfo } from "~store/user/selectors";

const asyncReader = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      let data = e.target.result;
      resolve(data);
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsText(file);
  });
};
const headerHeight = 80;
const stepbarHeight = 60;
const actionBarHeight = 40 + 16 * 2;
const borderColor = "#e0e0e0";

// #region StepBasic

const TemplateCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  ${(props) => props.disabled && `pointer-events: none; opacity: ${props.theme.palette.action.disabledOpacity};`}
`;
const TemplateCardItem = styled("div", StyledHelper.sfw("selected"))`
  user-select: none;
  display: flex;
  flex-direction: column;
  border-radius: ${(props) => props.theme.spacing(0.5)};
  border: 1px solid #e0e0e0;
  padding: 16px;

  align-items: start;
  cursor: pointer;
  transition: all 0.15s;
  &:hover {
    border-color: #50c8f4;
  }
  height: 140px;
  ${(props) =>
    props.selected &&
    `
    border-color: #50c8f4;
    background-color: #f5f5f5; 
    box-shadow: 0 0 5px 0 #50c8f4; 
  `}

  .name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .logo {
    display: flex;
    justify-content: center;
    svg {
      font-size: 24px;
    }
  }
`;

function StepBasic({ formik, setFieldValue, stepKey }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const projectTemplates = useSelector(selectProjectTemplates);
  const projectLanguages = useSelector(selectProjectLanguages);
  const project = useSelector(selectProject);

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const translatedProjectLanguageOptions = useMemo(() => {
    let langs = projectLanguageOptions.map((item) => ({ ...item, label: t(item.label) }));
    if (selectedTemplate) {
      1;
      langs = langs.filter((item) => selectedTemplate.bot_languages.includes(item.value));
    }
    return langs;
  }, [t, selectedTemplate]);

  const handleChangeLanguage = (e, value) => {
    setFieldValue("assistant_languages", value || null);
  };

  useEffect(() => {
    if (!project || !projectTemplates) return;
    const fromTemplateId = project?.from_template;
    if (fromTemplateId) {
      setSelectedTemplate(projectTemplates.find((item) => item.id === fromTemplateId));
    }
  }, [project, projectTemplates]);

  useEffect(() => {
    if (formik.touched?.[stepKey]?.assistant_languages) {
      formik.setTouched({ ...formik.touched, [stepKey]: { ...formik.touched[stepKey], assistant_template: true } });
    }
  }, [formik.touched?.[stepKey]?.assistant_languages]);

  useEffect(() => {
    setFieldValue("assistant_template", selectedTemplate?.id);
  }, [selectedTemplate]);

  useEffect(() => {
    dispatch(getProjectTemplates());
  }, [dispatch]);

  const handleClickSelectTemplate = (template) => () => {
    if (selectedTemplate?.key === template.key) {
      setSelectedTemplate(null);
    } else {
      setSelectedTemplate(template);
      //remove primary language if not supported by the template
      if (!template.bot_languages.includes(formik.values[stepKey].assistant_languages)) {
        setFieldValue("assistant_languages", null);
      }
    }
  };

  return (
    <StepContainer>
      <Grid container spacing={2}>
        <Grid item mb={2} xs={12}>
          <PalTypography color="textPrimary" fontWeight={600} variant="h5">
            Start with the Basics
          </PalTypography>
          <PalTypography gutterBottom variant="body1">
            Please fill out the following information to get started.
          </PalTypography>
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Project Name
            <RequiredComp />
          </PalTypography>
          <PalTextField
            fullWidth
            invalid={formik.touched[stepKey]?.assistant_name && formik.errors[stepKey]?.assistant_name}
            name={`${stepKey}.assistant_name`}
            placeholder="Enter Project Name"
            value={formik.values[stepKey].assistant_name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Primary Language
            <RequiredComp />
          </PalTypography>
          <PalAutoComplete
            fullWidth
            isPrimitiveValue
            invalid={formik.touched[stepKey]?.assistant_languages && formik.errors[stepKey]?.assistant_languages}
            name={`${stepKey}.assistant_languages`}
            options={translatedProjectLanguageOptions}
            placeholder="Enter Primary Language"
            value={formik.values[stepKey].assistant_languages}
            onBlur={formik.handleBlur}
            onChange={handleChangeLanguage}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} m="0" variant="body1">
            <PalSwitch
              checked={showTemplate}
              disabled={!!project?.from_template}
              onChange={handleClickToggleTemplateShow}
            />
            Create Assistant from Template
          </PalTypography>
        </Grid> */}
        <Fade mountOnEnter unmountOnExit in={!!selectedTemplate || project?.id}>
          <Grid item xs={12}>
            <Alert severity="info">Selected template cannot be changed after the assistant is created.</Alert>
          </Grid>
        </Fade>
        <Grid item xs={12}>
          <TemplateCardContainer disabled={!!project?.from_template || project?.id}>
            {[...projectTemplates].map((item) => (
              <TemplateCardItem
                key={item.id}
                selected={selectedTemplate?.key === item.key}
                onClick={handleClickSelectTemplate(item)}
              >
                <Box alignItems="start" display="flex" gap={1}>
                  <div className="logo">
                    <Widgets />
                  </div>
                  <PalTypography gutterBottom className="name" fontWeight={600} variant="body1">
                    {item.name}
                  </PalTypography>
                </Box>

                <Box mt="auto">
                  <PalTypography gutterBottom variant="caption">
                    Supported Languages
                  </PalTypography>
                  <br />
                  {item.bot_languages.map((lang) => (
                    <Chip key={lang} label={lang} size="small" />
                  ))}
                </Box>
              </TemplateCardItem>
            ))}
          </TemplateCardContainer>
          {formik.touched[stepKey]?.assistant_template && formik.errors[stepKey]?.assistant_template && (
            <FormHelperText error>{formik.errors[stepKey]?.assistant_template}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </StepContainer>
  );
}
// #endregion StepBasic

// #region StepGeneralInformation

const IndustryList = [
  { value: "1", label: "Food & Beverage" },
  { value: "2", label: "Health & Wellness" },
  { value: "3", label: "Retail" },
  { value: "4", label: "Real Estate" },
  { value: "5", label: "Automotive" },
  { value: "6", label: "Education" },
  { value: "7", label: "Finance" },
  { value: "8", label: "Hospitality" },
  { value: "9", label: "Technology" },
  { value: "10", label: "Other" },
];

const CompanySizeList = [
  { value: "1", label: "1-10" },
  { value: "2", label: "11-50" },
  { value: "3", label: "51-200" },
  { value: "4", label: "201-500" },
  { value: "5", label: "501-1000" },
  { value: "6", label: "1000+" },
];

const UsageCountList = [
  { value: "1", label: "1-3" },
  { value: "2", label: "4-10" },
  { value: "3", label: "11-20" },
  { value: "4", label: "21-50" },
  { value: "5", label: "51-100" },
  { value: "6", label: "100+" },
];

const DepartmentList = [
  { value: "1", label: "Marketing" },
  { value: "2", label: "Sales" },
  { value: "3", label: "Customer Service" },
  { value: "4", label: "IT" },
  { value: "5", label: "Other" },
];

const RoleList = [
  { value: "1", label: "Owner" },
  { value: "2", label: "Manager" },
  { value: "3", label: "Employee" },
  { value: "4", label: "Other" },
];

function StepGeneralInformation({ formik, setFieldValue, stepKey }) {
  const dispatch = useDispatch();
  const project = useSelector(selectProject);
  const projectLanguages = useSelector(selectProjectLanguages);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getProjectLanguages());
  }, [dispatch]);
  // const project = useSelector(selectProject);
  const handleHeaderFileChange = async (file, e) => {
    const img = await FileHelper.getImage(file);
    const width = img.width;
    const height = img.height;
    if (width !== height || width < 144) {
      AlertHelper.showError(t("dashboard.onboarding.logoSizeError"));
      e.preventDefault();
      return;
    }

    // setFiles({ ...files, popup_header_icon_path: [file] });
    setFieldValue("company_logo_file", file);
    const blobUri = URL.createObjectURL(file);
    // dispatch(setPopupSettingsState("popup_header_icon_path", blobUri));
    setFieldValue("company_logo", blobUri);
  };

  const handleChangeSupportedLanguages = (e, value) => {
    setFieldValue("assistant_supported_languages", value);
  };

  // const translatedProjectLanguageOptions = useMemo(() => {
  //   return projectLanguageOptions.map((item) => ({ ...item, label: t(item.label) }));
  // }, [t]);

  const handleChangeTextField = (key) => (e) => {
    setFieldValue(key, e.target.value);
  };

  const handleChangeCompanyDetailsOption = (key) => (e, item) => {
    setFieldValue(`company_details.${key}`, item);
  };

  return (
    <StepContainer>
      <Grid container spacing={2}>
        <Grid item mb={2} xs={12}>
          <PalTypography color="textPrimary" fontWeight={600} variant="h5">
            General Information
          </PalTypography>
          <PalTypography gutterBottom variant="body1">
            Please fill out the following information to get started.
          </PalTypography>
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Branding Logo
          </PalTypography>
          <PRProfile img={formik.values[stepKey]?.company_logo} onFileChange={handleHeaderFileChange} />
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Supported Languages
          </PalTypography>
          <PalAutoComplete
            fullWidth
            multiple
            label="Enter Supported Languages"
            labelSelector="name"
            options={projectLanguages.filter(
              (i) =>
                i.code?.toLowerCase() !==
                formik.values[stepsDefinition.stepBasic.key].assistant_languages?.toLowerCase()
            )}
            value={formik.values[stepKey].assistant_supported_languages}
            valueSelector="code"
            onChange={handleChangeSupportedLanguages}
          />
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            <HelpIconWrapper title="Enter domains to able to use company email addresses.">
              Allowed Domain(s)
              <RequiredComp />
            </HelpIconWrapper>
          </PalTypography>
          <PalTextField
            fullWidth
            multiline
            invalid={formik.touched[stepKey]?.allowed_mail_domains && formik.errors[stepKey]?.allowed_mail_domains}
            label="Enter explicit domains like example.com"
            maxRows={5}
            minRows={2}
            value={formik.values[stepKey].allowed_mail_domains}
            onChange={handleChangeTextField("allowed_mail_domains")}
          />
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Industry
            {/* <RequiredComp /> */}
          </PalTypography>
          <PalSelect
            fullWidth
            label="Select Industry"
            options={IndustryList}
            value={formik.values[stepKey].company_details?.industry}
            onChange={handleChangeCompanyDetailsOption("industry")}
          />
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Company Size
            {/* <RequiredComp /> */}
          </PalTypography>
          <PalSelect
            fullWidth
            label="Select Company Size"
            options={CompanySizeList}
            value={formik.values[stepKey].company_details?.size}
            onChange={handleChangeCompanyDetailsOption("size")}
          />
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Usage Count
            {/* <RequiredComp /> */}
          </PalTypography>
          <PalSelect
            fullWidth
            label="Select Usage Count"
            options={UsageCountList}
            value={formik.values[stepKey].company_details?.usage_count}
            onChange={handleChangeCompanyDetailsOption("usage_count")}
          />
        </Grid>
        <Grid item xs={6}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Department
            {/* <RequiredComp /> */}
          </PalTypography>
          <PalSelect
            fullWidth
            label="Select Department"
            options={DepartmentList}
            value={formik.values[stepKey].company_details?.department}
            onChange={handleChangeCompanyDetailsOption("department")}
          />
        </Grid>
        <Grid item xs={6}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Role
          </PalTypography>
          <PalSelect
            fullWidth
            label="Select Role"
            options={RoleList}
            value={formik.values[stepKey].company_details?.role}
            onChange={handleChangeCompanyDetailsOption("role")}
          />
        </Grid>
      </Grid>
    </StepContainer>
  );
}
// #endregion StepGeneralInformation

// #region StepBotSettings

function StepBotSettings({ formik, stepKey, setFieldValue }) {
  return (
    <StepContainer>
      <Grid container spacing={2}>
        <Grid item mb={2} xs={12}>
          <PalTypography color="textPrimary" fontWeight={600} variant="h5">
            Bot Settings
          </PalTypography>
          <PalTypography gutterBottom variant="body1">
            Please enter the AI assistant's name and customize the assistant's prompt.
          </PalTypography>
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Assistant Name
            <RequiredComp />
          </PalTypography>
          <PalTextField
            fullWidth
            invalid={formik.touched[stepKey]?.bot_name && formik.errors[stepKey]?.bot_name}
            name={`${stepKey}.bot_name`}
            placeholder="Enter Assistant Name"
            value={formik.values[stepKey].bot_name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Assistant Purpose Prompt
          </PalTypography>
          <PalTextField
            fullWidth
            multiline
            invalid={formik.touched[stepKey]?.awareness_prompt && formik.errors[stepKey]?.awareness_prompt}
            maxRows={9}
            minRows={5}
            name={`${stepKey}.awareness_prompt`}
            placeholder="Enter Assistant Purpose and Goals"
            value={formik.values[stepKey].awareness_prompt}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
    </StepContainer>
  );
}
// #endregion StepBotSettings

// #region StepLivechat
const AgentAddModal = withCardon(
  function TemplateUploadModalContent({ get }) {
    const formik = useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
      }),
      onSubmit: async (values) => {
        get(values.email)();
      },
    });

    const handleChange = (e) => {
      formik.setFieldValue("email", e.target.value);
    };
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        formik.handleSubmit();
      }
    };
    return (
      <PRModal title={"Invite Agent"} onClick={formik.handleSubmit} onClose={get(false)}>
        <PalTextField
          fullWidth
          invalid={formik.touched.email && formik.errors.email}
          label="Enter Email"
          value={formik.values.email}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

function StepLivechat({ formik, stepKey, setFieldValue }) {
  const dispatch = useDispatch();
  const project = useSelector(selectProject);
  // const callcenterSettings = useSelector(selectCallcenterSettings);
  const handleAddAgent = async () => {
    const email = await AgentAddModal.show();
    if (email) {
      setFieldValue("agentList", [...formik.values[stepKey].agentList, email]);
    }
  };

  const handleChipDelete = (index) => () => {
    setFieldValue(
      "agentList",
      formik.values[stepKey].agentList.filter((_, i) => i !== index)
    );
  };

  const handleChangeTimePicket = (key) => (date) => {
    setFieldValue(key, date);
  };

  useEffect(() => {
    if (!project?.id) return;
    dispatch(getCallcenterSettings(project.id));
  }, [dispatch, project?.id]);

  const handleChangeAgentCanLeaveSession = (key) => (e) => {
    setFieldValue(key, !e.target.checked);
  };
  return (
    <StepContainer>
      <Grid container spacing={2}>
        <Grid item mb={2} xs={12}>
          <PalTypography color="textPrimary" fontWeight={600} variant="h5">
            Livechat
          </PalTypography>
          <PalTypography gutterBottom variant="body1">
            Please specify whether you would like to enable live chat for your assistant. This will allow users to
            interact with your assistant in real-time.
          </PalTypography>
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            <HelpIconWrapper title="Default behavior is to keep the agent in the session until the user ends the chat. If enabled, the agent can leave the session and the user will be transferred to the bot.">
              Disable Agent-to-Bot Transfer
            </HelpIconWrapper>
          </PalTypography>
          <PalSwitch
            checked={!formik.values[stepKey].agent_can_leave_session}
            onChange={handleChangeAgentCanLeaveSession("agent_can_leave_session")}
          />
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Work Hours
          </PalTypography>
          <Box display="flex" flexDirection="row" gap={2}>
            <PalTimePicker
              fullWidth
              label="Select Start Time"
              value={formik.values[stepKey].work_start_time}
              onChange={handleChangeTimePicket("work_start_time")}
            />
            <PalTimePicker
              fullWidth
              label="Select End Time"
              value={formik.values[stepKey].work_end_time}
              onChange={handleChangeTimePicket("work_end_time")}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Invite Agents for Livechat
          </PalTypography>
          {!formik.values[stepKey].agentList?.length && (
            <Box
              mt={2}
              p={2}
              sx={{
                borderRadius: 1,
                border: "1px solid #e0e0e0",
                textAlign: "center",
              }}
            >
              <PalTypography color="textSecondary">No agents invited yet.</PalTypography>
            </Box>
          )}
          {formik.values[stepKey].agentList?.length > 0 && (
            <Box
              display={"flex"}
              flexWrap={"wrap"}
              gap={1}
              mt={2}
              p={2}
              sx={{
                borderRadius: 1,
                border: "1px solid #e0e0e0",
              }}
            >
              {formik.values[stepKey].agentList.map((email, index) => (
                <Box
                  key={index}
                  alignItems={"center"}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Box>
                    <Chip
                      deleteIcon={<Cancel />}
                      icon={<SupportAgent />}
                      label={email}
                      onDelete={handleChipDelete(index)}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"} mt={1}>
            <PalButton color="primary" size="medium" variant="text" onClick={handleAddAgent}>
              Invite
            </PalButton>
          </Box>
        </Grid>
      </Grid>
    </StepContainer>
  );
}
// #endregion StepLivechat

// #region StepPopup

function StepPopup({ formik, setFieldValue, stepKey }) {
  const { t } = useTranslation();
  // const handleFileChange = (key) => async (file) => {
  //   const img = await FileHelper.getImage(file);
  //   const width = img.width;
  //   const height = img.height;
  //   if (width !== height || width < 144) {
  //     AlertHelper.showError(t("dashboard.popup.errors.headerIconSize"));
  //     return;
  //   }

  //   await formik.setFieldValue(`${key}_file`, file);
  //   const blobUri = URL.createObjectURL(file);
  //   await formik.setFieldValue(key, blobUri);
  // };
  const handleClickShowBalloon = () => {
    showPopupPreviewBalloon(false);
  };

  const { popupPalActions, popupInternal } = useMemo(() => {
    const iframeDom = document.getElementById(IFRAME_PREVIEW_ID);
    const palActions = iframeDom?.contentWindow?.palActions;
    const internalDoc = iframeDom?.contentWindow?.document;

    const innerIframe = internalDoc?.getElementById("_palamar_chat_iframe");
    const innerWindow = innerIframe?.contentWindow;
    const popup = innerWindow?.popup;
    if (popup) {
      popup.setPreviewState({ enableInput: false });
    }
    return { popupPalActions: palActions, popupInternal: popup };
  }, []);

  const handleFileChange = (key, isLottie) => async (file, e) => {
    if (!isLottie) {
      const img = await FileHelper.getImage(file);
      const width = img.width;
      const height = img.height;
      if (width !== height || width < 144) {
        AlertHelper.showError(t("dashboard.popup.errors.headerIconSize"));
        e.preventDefault();
        return;
      }
    } else {
      const lottieObj = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          let json;
          try {
            json = JSON.parse(e.target.result);
          } catch {}
          resolve(json);
        };
        reader.readAsText(file);
      });
      if (!lottieObj?.v) {
        AlertHelper.showError(t("dashboard.popup.errors.invalidLottie"));
        return;
      }
    }

    // setFiles({ ...files, popup_button_icon_path: [file] });
    // if (popupSettings?.popup_style_info?.popup_icon_type === popupIconType.lottie) {
    //   dispatch(setPopupSettingsState("popup_button_icon_path", file));
    // } else {
    //   const blobUri = URL.createObjectURL(file);
    //   dispatch(setPopupSettingsState("popup_button_icon_path", blobUri));
    // }
    await formik.setFieldValue(`${key}___file`, file);
    const blobUri = URL.createObjectURL(file);
    await formik.setFieldValue(key, blobUri);
  };

  const handleChangeRadio = (key) => async (e) => {
    const value = e.target.value;
    await formik.setFieldValue(key, value);
    if (key === "welcomeForm.popup_style_info.popup_position") {
      popupPalActions?.setMinimize(true);
    } else if (key === "welcomeForm.popup_style_info.popup_icon_type") {
      popupPalActions?.setMinimize(true);
      if (value === popupIconType.icon) {
        const fileItem = await Utils.urlToFile(PalamarLogo, "defaultHeaderIcon");
        await formik.setFieldValue("files", { ...formik.values.files, popup_button_icon_path: [fileItem] });
        await formik.setFieldValue("welcomeForm.popup_button_icon_path", URL.createObjectURL(fileItem));
      } else if (value === popupIconType.lottie) {
        const file = new File([JSON.stringify(LegoLottie)], "lottie.json", { type: "text/plain" });
        await formik.setFieldValue("files", { ...formik.values.files, popup_button_icon_path: [file] });
        const blobUrl = URL.createObjectURL(file);
        await formik.setFieldValue("welcomeForm.popup_button_icon_path", blobUrl);
      }
    } else if (key === "welcomeForm.popup_style_info.popup_header_icon_type") {
      popupPalActions?.setMinimize(false);
      if (value === popupIconType.icon) {
        const fileItem = await Utils.urlToFile(PalamarLogo, "defaultHeaderIcon");
        await formik.setFieldValue("files", { ...formik.values.files, popup_header_icon_path: [fileItem] });
        await formik.setFieldValue("welcomeForm.popup_header_icon_path", URL.createObjectURL(fileItem));
      } else if (value === popupIconType.lottie) {
        const file = new File([JSON.stringify(LegoLottie)], "lottie.json", { type: "text/plain" });
        await formik.setFieldValue("files", { ...formik.values.files, popup_header_icon_path: [file] });
        const blobUrl = URL.createObjectURL(file);
        await formik.setFieldValue("welcomeForm.popup_header_icon_path", blobUrl);
      }
    } else if (key === "welcomeForm.popup_style_info.popup_bot_icon_type") {
      popupPalActions?.setMinimize(false);
      if (value === popupIconType.icon) {
        const fileItem = await Utils.urlToFile(PalamarLogo, "defaultHeaderIcon");
        await formik.setFieldValue("files", { ...formik.values.files, popup_bot_icon_path: [fileItem] });
        await formik.setFieldValue("welcomeForm.popup_bot_icon_path", URL.createObjectURL(fileItem));
      } else if (value === popupIconType.lottie) {
        const file = new File([JSON.stringify(LegoLottie)], "lottie.json", { type: "text/plain" });
        await formik.setFieldValue("files", { ...formik.values.files, popup_bot_icon_path: [file] });
        const blobUrl = URL.createObjectURL(file);
        await formik.setFieldValue("welcomeForm.popup_bot_icon_path", blobUrl);
      }
    }
  };

  const handleChangeTextField = (key) => (e) => {
    formik.setFieldValue(key, e.target.value);
    popupPalActions?.setMinimize(true);
    if (key === "welcomeForm.welcome_msg") {
      // popupInternal.IFrameHelper.sendActionToBalloon({
      //   type: "visibility",
      //   payload: true,
      // });
      // popupInternal.IFrameHelper.sendActionToBalloon({
      //   type: "messages",
      //   payload: [
      //     {
      //       text: e.target.value,
      //     },
      //   ],
      // });
    }
  };
  const handleChangeHeaderText = (key) => (e) => {
    formik.setFieldValue(key, e.target.value);
    popupPalActions?.setMinimize(false);
  };

  return (
    <StepContainer>
      <Grid container spacing={2}>
        <Grid item mb={2} xs={12}>
          <PalTypography color="textPrimary" fontWeight={600} variant="h5">
            Popup Styling
          </PalTypography>
          <PalTypography gutterBottom variant="body1">
            Please provide the styling details for the popup, including colors, position, and any other relevant
            information.
          </PalTypography>
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Header Text
          </PalTypography>
          <PalTextField
            fullWidth
            placeholder="Enter Popup Header Text"
            value={formik.values.welcomeForm.popup_title}
            onChange={handleChangeHeaderText("welcomeForm.popup_title")}
          />
          {/* <PalButton color="primary" variant="text" onClick={handleClickShowBalloon}>
            Show
          </PalButton> */}
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Position
          </PalTypography>
          <RadioGroup
            row
            aria-labelledby="popup-position"
            name="welcomeForm.popup_style_info?.popup_position"
            value={formik.values.welcomeForm.popup_style_info?.popup_position}
            onChange={handleChangeRadio("welcomeForm.popup_style_info.popup_position")}
          >
            {[...popupPositionOptions].reverse().map((item) => (
              <FormControlLabel key={item.value} control={<Radio />} label={t(item.label)} value={item.value} />
            ))}
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Popup Icon Type
          </PalTypography>

          <RadioGroup
            row
            aria-labelledby="popup-header-icon-type"
            name="welcomeForm.popup_style_info?.popup_icon_type"
            value={formik.values.welcomeForm.popup_style_info?.popup_icon_type}
            onChange={handleChangeRadio("welcomeForm.popup_style_info.popup_icon_type")}
          >
            {popupIconTypeOptions
              .filter((item) => ![popupIconType.none].includes(item.value))
              .map((item) => (
                <FormControlLabel key={item.value} control={<Radio />} label={t(item.label)} value={item.value} />
              ))}
          </RadioGroup>
        </Grid>
        {formik.values.welcomeForm?.popup_style_info?.popup_icon_type !== popupIconType.default && (
          <Grid item xs={12}>
            <PalTypography gutterBottom fontWeight={600} variant="body1">
              Popup Icon
            </PalTypography>
            {formik.values.welcomeForm?.popup_style_info?.popup_icon_type === popupIconType.icon && (
              <PRProfile
                img={formik.values.welcomeForm.popup_button_icon_path}
                onFileChange={handleFileChange("welcomeForm.popup_button_icon_path")}
              />
            )}
            {formik.values.welcomeForm?.popup_style_info?.popup_icon_type === popupIconType.lottie && (
              <PRProfileLottie
                img={formik.values.welcomeForm.popup_button_icon_path}
                onFileChange={handleFileChange("welcomeForm.popup_button_icon_path", true)}
              />
            )}
          </Grid>
        )}

        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Header Icon Type
          </PalTypography>

          <RadioGroup
            row
            aria-labelledby="popup-header-icon-type"
            name="welcomeForm.popup_style_info?.popup_header_icon_type"
            value={formik.values.welcomeForm.popup_style_info?.popup_header_icon_type}
            onChange={handleChangeRadio("welcomeForm.popup_style_info.popup_header_icon_type")}
          >
            {popupIconTypeOptions
              .filter((item) => ![popupIconType.lottie].includes(item.value))
              .map((item) => (
                <FormControlLabel key={item.value} control={<Radio />} label={t(item.label)} value={item.value} />
              ))}
          </RadioGroup>
        </Grid>
        {formik.values.welcomeForm?.popup_style_info?.popup_header_icon_type === popupIconType.icon && (
          <Grid item xs={12}>
            <PalTypography gutterBottom fontWeight={600} variant="body1">
              Header Icon
            </PalTypography>
            {formik.values.welcomeForm?.popup_style_info?.popup_header_icon_type === popupIconType.icon && (
              <PRProfile
                img={formik.values.welcomeForm.popup_header_icon_path}
                onFileChange={handleFileChange("welcomeForm.popup_header_icon_path")}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Bot Joined Icon Type
          </PalTypography>

          <RadioGroup
            row
            aria-labelledby="popup-header-icon-type"
            name="welcomeForm.popup_style_info?.popup_bot_icon_type"
            value={formik.values.welcomeForm.popup_style_info?.popup_bot_icon_type}
            onChange={handleChangeRadio("welcomeForm.popup_style_info.popup_bot_icon_type")}
          >
            {popupIconTypeOptions
              .filter((item) => ![popupIconType.none, popupIconType.lottie].includes(item.value))
              .map((item) => (
                <FormControlLabel key={item.value} control={<Radio />} label={t(item.label)} value={item.value} />
              ))}
          </RadioGroup>
        </Grid>
        {formik.values.welcomeForm?.popup_style_info?.popup_bot_icon_type !== popupIconType.default && (
          <Grid item xs={12}>
            <PalTypography gutterBottom fontWeight={600} variant="body1">
              Bot Joined
            </PalTypography>
            {formik.values.welcomeForm?.popup_style_info?.popup_bot_icon_type === popupIconType.icon && (
              <PRProfile
                img={formik.values.welcomeForm.popup_bot_icon_path}
                onFileChange={handleFileChange("welcomeForm.popup_bot_icon_path")}
              />
            )}
            {formik.values.welcomeForm?.popup_style_info?.popup_bot_icon_type === popupIconType.lottie && (
              <PRProfileLottie
                img={formik.values.welcomeForm.popup_bot_icon_path}
                onFileChange={handleFileChange("welcomeForm.popup_bot_icon_path", true)}
              />
            )}
          </Grid>
        )}
        {/* <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Balloon Message
          </PalTypography>
        </Grid> */}
        <Grid item xs={12}>
          <PalTypography gutterBottom fontWeight={600} variant="body1">
            Balloon Message
          </PalTypography>
          <PalTextField
            fullWidth
            placeholder="Enter Balloon Message"
            value={formik.values.welcomeForm.welcome_msg}
            onChange={handleChangeTextField("welcomeForm.welcome_msg")}
          />
          {/* <PalButton color="primary" variant="text" onClick={handleClickShowBalloon}>
            Show
          </PalButton> */}
        </Grid>
      </Grid>
    </StepContainer>
  );
}
// #endregion StepPopup

// #region StepRag

const KnowledgeBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => (props.selected ? props.theme.palette.primary.main : props.theme.palette.grey[300])};
  border-radius: 4px;
  padding: ${(props) => props.theme.spacing(4)};
  cursor: pointer;
  ${(props) => props.selected && `background-color: #e8f6fc;`}
`;

const AddKnowledgeSourceModal = withCardon(
  function AddKnowledgeSourceModalContent({ get }) {
    const formik = useFormik({
      initialValues: {
        type: onboardingRagType.webpage,
        url: "",
        files: null,
        content: "",
      },
      onSubmit: async (values) => {
        get({
          ...values,
        })();
      },
    });

    const handleSelectBox = (type) => () => {
      formik.setFieldValue("type", type);
    };

    const handleChangeUrl = (e) => {
      formik.setFieldValue("url", e.target.value);
    };
    const handleChangeFile = (files) => {
      formik.setFieldValue("files", files);
    };

    // const handleChangeContent = (e) => {
    //   formik.setFieldValue("content", e.target.value);
    // };

    return (
      <PRModal size="xl" title={"Add Knowledge Source"} onClick={formik.handleSubmit} onClose={get(false)}>
        {/* <PalTextField fullWidth label="Enter Email" value={email} onChange={handleChange} /> */}

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <KnowledgeBox
              selected={formik.values.type === onboardingRagType.webpage}
              onClick={handleSelectBox(onboardingRagType.webpage)}
            >
              <Language />
              <PalTypography fontWeight={600} variant="body1">
                Webpage
              </PalTypography>
            </KnowledgeBox>
          </Grid>
          <Grid item xs={4}>
            <KnowledgeBox
              selected={formik.values.type === onboardingRagType.file}
              onClick={handleSelectBox(onboardingRagType.file)}
            >
              <DescriptionOutlined />
              <PalTypography fontWeight={600} variant="body1">
                File
              </PalTypography>
            </KnowledgeBox>
          </Grid>
          <Grid item xs={4}>
            <KnowledgeBox
              selected={formik.values.type === onboardingRagType.content}
              onClick={handleSelectBox(onboardingRagType.content)}
            >
              <TextFields />
              <PalTypography fontWeight={600} variant="body1">
                Text
              </PalTypography>
            </KnowledgeBox>
          </Grid>
          {formik.values.type === onboardingRagType.webpage && (
            <Grid item xs={12}>
              <PalTypography variant="body2">Public URL</PalTypography>
              <PalTextField fullWidth placeholder="Enter URL" value={formik.values.url} onChange={handleChangeUrl} />
            </Grid>
          )}

          {formik.values.type === onboardingRagType.file && (
            <Grid item xs={12}>
              <PRDropZone
                // accept={[".zip"]}
                multiple
                maxFiles={10}
                onFileChange={handleChangeFile}
              />
            </Grid>
          )}
          {formik.values.type === onboardingRagType.content && (
            <Grid item xs={12}>
              <PalCodeField
                editorMode
                fullWidth
                editorProps={{
                  defaultHeight: 400,
                }}
                name="content"
                value={formik.values.content}
                onChange={formik.handleChange}
              />
            </Grid>
          )}
        </Grid>
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

const SourceBox = styled.div`
  border-radius: 4px;
  padding: ${(props) => props.theme.spacing(2)};
  /* cursor: pointer; */
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

function StepRagItem({ item, projectId, formik, onModified, stepKey, setFieldValue }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const onClickOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  const handleClickEditRagContent = async () => {
    setAnchorEl(null);
    if (item.type === onboardingRagType.file) {
      const newPages = await EditRagFilePagesModal.show({ item: item.payload });
      if (newPages) {
        const newItem = { ...item };
        newItem.payload.source_pages = newPages;
        await dispatch(createOrUpdateSourceFile(projectId, newItem.payload));
        setFieldValue(
          "sourceList",
          formik.values[stepKey].sourceList.map((source) => (source.payload.id === item.payload.id ? newItem : source))
        );
        onModified?.();
      }
    } else {
      const content = item.type === onboardingRagType.content ? item.payload.text : item.payload.content;
      const newContent = await EditRagContentModal.show({ content, item: item.payload });
      if (newContent) {
        const newItem = { ...item };
        if (item.type === onboardingRagType.content) {
          newItem.payload.text = newContent;
        } else if (item.type === onboardingRagType.webpage) {
          newItem.payload.content = newContent;
        }
        setFieldValue(
          "sourceList",
          formik.values[stepKey].sourceList.map((source) => (source.payload.id === item.payload.id ? newItem : source))
        );

        onModified?.();
      }
    }
  };

  const handleClickDeleteRagItem = async () => {
    setAnchorEl(null);
    if (!(await DialogHelper.showQuestionDelete())) {
      return;
    }
    if (item.type === onboardingRagType.webpage) {
      await dispatch(deleteSourceWebPage(projectId, item.payload.id));
    } else if (item.type === onboardingRagType.file) {
      await dispatch(deleteSourceFile(projectId, item.payload.id));
    }
    setFieldValue(
      "sourceList",
      formik.values[stepKey].sourceList.filter((source) => source.payload.id !== item.payload.id)
    );
  };

  const isProcessingMain = [onboardingRagStatus.Processing, onboardingRagStatus.Fresh].includes(item.payload.status);

  const isProcessingFilePages =
    item.type === onboardingRagType.file &&
    item.payload.source_pages.some((page) =>
      [onboardingRagStatus.Processing, onboardingRagStatus.Fresh].includes(page.status)
    );

  const isProcessing = isProcessingMain || isProcessingFilePages;
  const isError = item.payload.status === onboardingRagStatus.Error;

  const processedPageCount =
    item.type === onboardingRagType.file &&
    !!item.payload.source_pages &&
    item.payload.source_pages.filter(
      (i) => ![onboardingRagStatus.Processing, onboardingRagStatus.Fresh].includes(i.status)
    );

  const processingProgress =
    item.type === onboardingRagType.file && !!item.payload.source_pages?.length
      ? ` (${processedPageCount?.length}/${item.payload.source_pages?.length})`
      : "";

  return (
    // <Grid key={index} item xs={12}>
    <SourceBox>
      <Grid container alignItems="center" spacing={2} wrap="nowrap">
        <Grid item xs={"auto"}>
          <PalTooltip
            title={`${
              item.type === onboardingRagType.webpage
                ? "Webpage"
                : item.type === onboardingRagType.file
                  ? "File"
                  : "Content"
            }`}
          >
            <span>
              {item.type === onboardingRagType.webpage && <Language />}
              {item.type === onboardingRagType.file && <DescriptionOutlined />}
              {item.type === onboardingRagType.content && <TextFields />}
            </span>
          </PalTooltip>
        </Grid>
        <Grid item xs overflow={"hidden"} textOverflow={"ellipsis"}>
          {/* Status : {onboardingRagStatusMap[item.payload.status] || item.payload.status} */}

          {item.type === onboardingRagType.webpage && (
            <PalTypography noWrap href={item.payload.url} variant="body1">
              <PalTooltip title={item.payload.url}>
                <span>{item.payload.url}</span>
              </PalTooltip>
            </PalTypography>
          )}
          {item.type === onboardingRagType.file && (
            <PalTypography noWrap variant="body1">
              <PalTooltip title={`${item.payload.media_file.split("/").pop()}${processingProgress}`}>
                <span>
                  {item.payload.media_file.split("/").pop()}
                  <PalTypography variant="caption">{processingProgress}</PalTypography>
                </span>
              </PalTooltip>
            </PalTypography>
          )}
          {item.type === onboardingRagType.content && (
            <PalTypography noWrap variant="body1">
              {item.payload.text}
              <PalTooltip
                title={item.payload.text?.length > 250 ? `${item.payload.text.slice(0, 250)}...` : item.payload.text}
              >
                <span></span>
              </PalTooltip>
            </PalTypography>
          )}
        </Grid>

        <Grid item alignItems="center" display="flex" justifyContent="center" xs="auto">
          <PalTooltip title={t(onboardingRagStatusMap[item.payload.status]) || item.payload.status}>
            <span>
              {isError ? (
                <ErrorOutline color="error" />
              ) : isProcessing ? (
                <CircularProgress color="secondary" size={20} />
              ) : (
                <Done color="success" />
              )}
            </span>
          </PalTooltip>
        </Grid>
        <Grid item alignItems="center" display="flex" justifyContent="center" xs="auto">
          <PalIconButton onClick={onClickOptions}>
            <MoreVert />
          </PalIconButton>
          <PalMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
            <PalMenuItem disabled={isProcessing} onClick={handleClickEditRagContent}>
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText> {t("dashboard.onboarding.updateRagContent")}</ListItemText>
            </PalMenuItem>
            <Divider />
            <PalMenuItem onClick={handleClickDeleteRagItem}>
              <ListItemIcon>
                <Delete color="error" fontSize="small" />
              </ListItemIcon>
              <ListItemText color="error"> {t("dashboard.onboarding.deleteRag")}</ListItemText>
            </PalMenuItem>
          </PalMenu>
        </Grid>
      </Grid>
    </SourceBox>
    // </Grid>
  );
}

const EditRagContentModal = withCardon(
  function EditRagContentModalContent({ get, content, item }) {
    const formik = useFormik({
      initialValues: {
        content,
      },
      onSubmit: async (values) => {
        get(values.content)();
      },
    });

    const handleChangeContent = (e) => {
      formik.setFieldValue("content", e.target.value);
    };
    const totalContentWordCount = formik.values.content.split(/\s+/).filter(Boolean)?.length;

    return (
      <PRModal size="xl" title={"Edit Content"} onClick={formik.handleSubmit} onClose={get(false)}>
        {item.url && (
          <PalTypography mb={1}>
            Source: <strong>{item.url}</strong>
          </PalTypography>
        )}
        <PalTypography mb={1}>
          Total word count: <strong>{totalContentWordCount}</strong>
        </PalTypography>
        <PalCodeField
          editorMode
          fullWidth
          editorProps={{
            defaultHeight: 800,
          }}
          name="content"
          value={formik.values.content}
          onChange={handleChangeContent}
        />
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

const EditRagFilePagesModal = withCardon(
  function EditRagFilePagesModalContent({ get, item }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [showBlankPages, setShowBlankPages] = useState(false);

    const formik = useFormik({
      initialValues: {
        sourcePages: item.source_pages,
      },
      onSubmit: async (values) => {
        get(values.sourcePages)();
      },
    });

    const visiblePages = useMemo(() => {
      if (showBlankPages) {
        return formik.values.sourcePages;
      }
      const visiblePages = formik.values.sourcePages.filter((page) => page.content.trim()?.length > 0);
      return visiblePages;
    }, [formik.values.sourcePages, showBlankPages]);

    const handleChangeContent = (item) => (e) => {
      const newItem = { ...item };
      newItem.content = e.target.value;
      const newSourcePages = formik.values.sourcePages.map((i) => (i.id === item.id ? newItem : i));
      formik.setFieldValue("sourcePages", newSourcePages);
    };

    const totalContentWordCount = visiblePages.reduce(
      (acc, item) => acc + item.content.split(/\s+/).filter(Boolean)?.length,
      0
    );

    const handleToggleShowBlankPages = () => {
      setShowBlankPages((prev) => !prev);
    };

    return (
      <PRModal
        size="xl"
        title={`Edit Content - ${currentPage}/${visiblePages?.length}`}
        onClick={formik.handleSubmit}
        onClose={get(false)}
      >
        <PalTypography alignItems={"center"} display={"flex"} gap={0.5} mb={1}>
          Show Blank pages
          <PalSwitch checked={showBlankPages} onChange={handleToggleShowBlankPages} />
        </PalTypography>
        <PalTypography mb={1}>
          Source file: <strong>{item.media_file?.split("/").pop()}</strong>
        </PalTypography>
        <PalTypography mb={1}>
          Total word count: <strong>{totalContentWordCount}</strong>
        </PalTypography>
        <Box position={"relative"}>
          <PalCodeField
            editorMode
            fullWidth
            disabled={!visiblePages?.length}
            editorProps={{
              defaultHeight: 800,
            }}
            name="content"
            value={visiblePages[currentPage - 1]?.content}
            onChange={handleChangeContent(visiblePages[currentPage - 1])}
          />
          {!visiblePages.length && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgb(224 224 224 / 30%)",
                padding: 1,
                borderRadius: 0.5,
                textAlign: "center",
              }}
            >
              <PalTypography variant="h6">No content found in this file</PalTypography>
              {/* {!showBlankPages && (
                <PalTypography color={"textSecondary"} variant="body1">
                  Please enable "Show Blank pages" to view all pages
                </PalTypography>
              )} */}
            </Box>
          )}
        </Box>
        <Box alignItems="center" display="flex" justifyContent="center" mt={1}>
          {!!visiblePages?.length && (
            <PalPagination
              count={visiblePages?.length}
              page={currentPage}
              onChange={(e, page) => setCurrentPage(page)}
            />
          )}
        </Box>
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

const AlertButton = styled(PalButton)`
  padding: 0 ${(props) => props.theme.spacing(0.5)};
  margin: 0 ${(props) => props.theme.spacing(0.5)};
`;
const knowledgeSourceMaxCount = 10;
function StepRag({ formik, nextAction, stepKey, setFieldValue }) {
  const project = useSelector(selectProject);
  const projectId = project.id;
  const [ragFactGroup, setRagFactGroup] = useState(formik.values[stepKey].ragFactGroup);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const ragMessageListRef = useRef([]);

  const { popupPalActions, popupInternal } = useMemo(() => {
    const iframeDom = document.getElementById(IFRAME_PREVIEW_ID);
    const palActions = iframeDom?.contentWindow?.palActions;
    const internalDoc = iframeDom?.contentWindow?.document;

    const innerIframe = internalDoc?.getElementById("_palamar_chat_iframe");
    const innerWindow = innerIframe?.contentWindow;
    const popup = innerWindow?.popup;

    ragMessageListRef.current = [
      {
        type: "notification",
        position: "center",
        messageTime: DateHelper.getDateTime().add(-1, "minute").toISOString(),
        text: "Use the chatbot to interact with the RAG model.",
      },
    ];
    palActions?.setMinimize(false);
    if (popup) {
      popup.setMessageList(ragMessageListRef.current);
      popup.setPreviewState({ enableInput: true });

      popup.onMessage = async (message) => {
        ragMessageListRef.current.push({
          type: "text",
          position: "right",
          text: message.text,
          messageTime: DateHelper.getDateTime().toISOString(),
          sender_type: "CUS",
          isSent: true,
          format: "markdown",
        });

        popup.setMessageList(ragMessageListRef.current);
        popup.setPreviewState({ typing: true });

        let response;

        try {
          response = await dispatch(postMessageToRag(projectId, formik.values[stepKey].ragFactGroupId, message.text));
        } catch {}

        popup.setPreviewState({ typing: false });
        // messages.push({
        //   type: "text",
        //   position: "left",
        //   sender_type: "BOT",
        //   messageTime: DateHelper.getDateTime(),
        //   text: response?.output,
        //   isSent: true,
        //   format: "markdown",
        // });
        if (response) {
          ragMessageListRef.current.push({
            type: "text",
            position: "left",
            text: response?.output,
            messageTime: DateHelper.getDateTime().toISOString(),
            sender_type: "BOT",
            isSent: true,
            format: "markdown",
          });

          popup.setMessageList(ragMessageListRef.current);
        }
      };
    }
    return { popupPalActions: palActions, popupInternal: popup };
  }, [dispatch, projectId, formik.values[stepKey].ragFactGroupId]);

  const handleAddKnowledgeSource = async () => {
    const data = await AddKnowledgeSourceModal.show();
    if (data.type === onboardingRagType.webpage) {
      const response = await dispatch(createOrUpdateSourceWebPage(projectId, { url: data.url }));
      setFieldValue("sourceList", [
        ...formik.values[stepKey].sourceList,
        { type: onboardingRagType.webpage, payload: response },
      ]);
      setRagFactGroup((prev) => ({ ...prev, status: dialogComponentsRagFactGroupStatus.Modified }));
    } else if (data.type === onboardingRagType.file) {
      for (const file of data.files) {
        const response = await dispatch(createOrUpdateSourceFile(projectId, { file }));
        setFieldValue("sourceList", [
          ...formik.values[stepKey].sourceList,
          { type: onboardingRagType.file, payload: response },
        ]);
      }
      setRagFactGroup((prev) => ({ ...prev, status: dialogComponentsRagFactGroupStatus.Modified }));
    } else if (data.type === onboardingRagType.content) {
      const allContentItems = formik.values[stepKey].sourceList.filter(
        (item) => item.type === onboardingRagType.content
      );
      const highestId = allContentItems.length > 0 ? Math.max(...allContentItems.map((item) => item.id)) : 0;
      setFieldValue("sourceList", [
        ...formik.values[stepKey].sourceList,
        {
          type: onboardingRagType.content,
          payload: {
            id: highestId + 1,
            text: data.content,
            status: onboardingRagStatus.Processed,
          },
        },
      ]);
      setRagFactGroup((prev) => ({ ...prev, status: dialogComponentsRagFactGroupStatus.Modified }));
    }
  };
  useEffect(() => {
    const processingSources = formik.values[stepKey].sourceList.filter(
      (item) =>
        [onboardingRagStatus.Processing, onboardingRagStatus.Fresh].includes(item.payload.status) ||
        (item.type === onboardingRagType.file &&
          item.payload.source_pages.some((page) =>
            [onboardingRagStatus.Processing, onboardingRagStatus.Fresh].includes(page.status)
          ))
    );
    if (processingSources.length > 0) {
      const interval = setInterval(() => {
        processingSources.forEach(async (item, index) => {
          const response =
            item.type === onboardingRagType.webpage
              ? await dispatch(getSourceWebPage(projectId, item.payload.id))
              : await dispatch(getSourceFile(projectId, item.payload.id));
          const isProcessing = [onboardingRagStatus.Processing, onboardingRagStatus.Fresh].includes(response.status);
          const isPagesProcessing =
            item.type === onboardingRagType.file
              ? response.source_pages.some((page) =>
                  [onboardingRagStatus.Processing, onboardingRagStatus.Fresh].includes(page.status)
                )
              : false;

          if (!isProcessing || isPagesProcessing) {
            const currentSourceList = [...formik.values[stepKey].sourceList];
            const itemIndex = currentSourceList.findIndex((item) => item.payload.id === response.id);
            if (itemIndex !== -1) {
              currentSourceList[itemIndex].payload = response;
            } else {
              currentSourceList[index].payload = response;
            }
            setFieldValue("sourceList", currentSourceList);
          }
        });
      }, 5000);
      return () => clearInterval(interval);
    }

    return () => {};
  }, [projectId, dispatch, formik.values[stepKey].sourceList]);

  useEffect(() => {
    const enableInput =
      [dialogComponentsRagFactGroupStatus.Modified, dialogComponentsRagFactGroupStatus.Processed].includes(
        ragFactGroup?.status
      ) && formik.values[stepKey]?.sourceList?.length > 0;

    popupInternal?.setPreviewState?.({ enableInput });
  }, [ragFactGroup?.status, popupInternal, formik.values[stepKey]?.sourceList, stepKey]);

  // useEffect(() => {
  //   if (ragFactGroup?.status === dialogComponentsRagFactGroupStatus.Processing) {
  //     const interval = setInterval(async () => {
  //     // const ragFactGroup = dispatch(getRagFactGroup(projectId, formik.values[stepKey].ragIntentId));
  //   }
  // }, [ragFactGroup]);

  useEffect(() => {
    if (ragFactGroup && ragFactGroup?.status === dialogComponentsRagFactGroupStatus.Processing) {
      const interval = setInterval(async () => {
        const response = await dispatch(getRagFactGroup(projectId, ragFactGroup.id));
        if (response?.status === dialogComponentsRagFactGroupStatus.Processed) {
          clearInterval(interval);
          AlertHelper.showSuccess(t("component.rag.ragProcessedAlert"));
          setRagFactGroup(response);
        } else if (response?.status === dialogComponentsRagFactGroupStatus.Error) {
          clearInterval(interval);
          AlertHelper.showWarning(t("component.rag.ragErrorAlert"));
          setRagFactGroup(response);
        }
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [ragFactGroup, dispatch, projectId, t]);

  const handleTrain = async (e) => {
    await nextAction(e, { preventContinue: true });
    await dispatch(processRag(projectId, formik.values[stepKey].ragFactGroupId));
    const ragFactGroup = await dispatch(getRagFactGroup(projectId, formik.values[stepKey].ragFactGroupId));
    setRagFactGroup(ragFactGroup);
  };

  const handleStopTrain = async () => {
    await dispatch(stopRag(projectId, formik.values[stepKey].ragFactGroupId));
    const ragFactGroup = await dispatch(getRagFactGroup(projectId, formik.values[stepKey].ragFactGroupId));
    setRagFactGroup(ragFactGroup);
  };

  const handleRagModified = () => {
    setRagFactGroup((prev) => ({ ...prev, status: dialogComponentsRagFactGroupStatus.Modified }));
  };
  return (
    <StepContainer>
      <Grid container spacing={2}>
        <Grid item mb={2} xs={12}>
          <PalTypography color="textPrimary" fontWeight={600} variant="h5">
            Chatbot: KnowledgeBase
          </PalTypography>
          <PalTypography gutterBottom variant="body1">
            Please provide information about your chatbot's Retrieval-Augmented Generation (RAG) capabilities.
          </PalTypography>
        </Grid>
        <Grid item xs={12}>
          {[dialogComponentsRagFactGroupStatus.Processed].includes(ragFactGroup?.status) && (
            <Alert severity="success">
              RAG model has been trained successfully. You can now interact with the chatbot to test the RAG model.
            </Alert>
          )}
          {[dialogComponentsRagFactGroupStatus.Modified, dialogComponentsRagFactGroupStatus.Fresh].includes(
            ragFactGroup?.status
          ) &&
            !!formik.values[stepKey]?.sourceList?.length && (
              <Alert severity="warning">
                RAG model has been modified. Please train the model to apply the changes.
                <AlertButton color="primary" size="medium" variant="text" onClick={handleTrain}>
                  Train
                </AlertButton>
              </Alert>
            )}
          {[dialogComponentsRagFactGroupStatus.Error].includes(ragFactGroup?.status) && (
            <Alert severity="warning">
              RAG model has an error. Please train the model again to enable the chatbot to use the RAG model.
              <AlertButton color="primary" size="medium" variant="text" onClick={handleTrain}>
                Train
              </AlertButton>
            </Alert>
          )}
          {[dialogComponentsRagFactGroupStatus.Processing].includes(ragFactGroup?.status) && (
            <Alert severity="warning">
              <Box alignItems="center" display="flex">
                <CircularProgress size={18} sx={{ mr: 1 }} />
                Training the RAG model. Please wait...
                <AlertButton color="primary" size="medium" variant="text" onClick={handleStopTrain}>
                  Stop Train
                </AlertButton>
              </Box>
            </Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <PalTypography gutterBottom fontWeight={600} variant="body1">
              Knowledge Sources ({formik.values[stepKey]?.sourceList?.length}/{knowledgeSourceMaxCount})
            </PalTypography>
          </Box>

          <PalButton
            fullWidth
            color="primary"
            disabled={formik.values[stepKey]?.sourceList?.length >= knowledgeSourceMaxCount}
            size="medium"
            startIcon={<Add />}
            variant="contained"
            onClick={handleAddKnowledgeSource}
          >
            Add Knowledge Source
          </PalButton>
        </Grid>

        {formik.values[stepKey].sourceList.map((item, index) => {
          return (
            <Grid key={index} item xs={12}>
              <StepRagItem
                formik={formik}
                item={item}
                projectId={projectId}
                setFieldValue={setFieldValue}
                stepKey={stepKey}
                onModified={handleRagModified}
              />
            </Grid>
          );
        })}
      </Grid>
    </StepContainer>
  );
}

// #endregion StepRag

// #region StepChannels

export function IntegrationItem({ image: Image, name, desc, onClick, onClickConfigure, onClickDetail, isDone }) {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      spacing={1}
      sx={{
        userSelect: "none",
        border: "1px solid",
        borderColor: "grey.300",
        borderRadius: 1,
        padding: 2,
        height: 230,
        ...(isDone && {
          backgroundColor: (theme) => lighten(theme.palette.success.light, 0.91),
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginRight: "auto",
            alignItems: "anchor-center",
            svg: {
              width: 64,
              height: 64,
            },
          }}
        >
          <Image />

          <PalTypography fontWeight={600} ml={1} variant="h6">
            {name}
          </PalTypography>
        </Box>
        <Box>
          {isDone && <TaskAlt color="success" />}
          {!isDone && <PanoramaFishEye color="secondary" />}
        </Box>
      </Box>

      <PalTypography my={1} overflowY="auto" variant="body2">
        {desc}
      </PalTypography>
      <Box alignItems={"center"} display="flex" gap={1} mt={"auto"} width={1}>
        <PalTooltip {...(!isDone && { open: false })} title="Revoke the access from the integration's dashboard">
          <Box
            sx={{
              ...(isDone && {
                cursor: "not-allowed !important",
              }),
            }}
            width={1}
          >
            <PalButton
              fullWidth
              disabled={onClickConfigure ? false : isDone}
              sx={{
                flex: 1,

                // pointerEvents: isDone ? "none" : "auto",
                // opacity: isDone ? 0.5 : 1,
              }}
              onClick={onClickConfigure || onClick}
              color={onClickConfigure ? "primary" : !isDone ? "primary" : "error"}
              // disabled={isDone}
              {...(onClickConfigure && { startIcon: <Settings /> })}
              variant="outlined"
            >
              <PalTypography fontWeight={600} variant="body1">
                {onClickConfigure ? "Configure" : isDone ? "Disconnect" : "Connect"}
              </PalTypography>
            </PalButton>
          </Box>
        </PalTooltip>
        {isDone && (
          <PalTooltip title="Show integration details">
            <PalIconButton color="primary" sx={{ flex: "0 0 auto" }} variant="text" onClick={onClickDetail}>
              <Info />
            </PalIconButton>
          </PalTooltip>
        )}
      </Box>
    </Box>
  );
}

export const IntegrationDetailModal = withCardon(
  function IntegrationDetailModalContent({ get, integration, labelDefinition }) {
    const { t } = useTranslation();
    const excludeKeyList = ["id", "access_token", "secret", "project"];
    const entries = Object.entries(integration);
    const filteredEntries = entries.filter(([key]) => !excludeKeyList.includes(key));
    //order entries. first one must be "integration_type"
    const orderedEntries = filteredEntries.sort(([key1], [key2]) => {
      if (key1 === "integration_type") return -1;
      if (key2 === "integration_type") return 1;
      return 0;
    });
    const getLabel = (key) => {
      if (labelDefinition) {
        const label = labelDefinition[key];
        if (label) return label;
      }
      switch (key) {
        case "integration_type":
          return t("dashboard.onboarding.integration.integrationDetail.integrationType");
        case "name":
          return t("dashboard.onboarding.integration.integrationDetail.name");
        case "app_id":
          return t("dashboard.onboarding.integration.integrationDetail.appId");
        case "number":
          return t("dashboard.onboarding.integration.integrationDetail.number");
        default:
          return key;
      }
    };

    return (
      <PRModal size="lg" title={"Integration Details"} onClose={get(false)}>
        <Grid container spacing={1}>
          {orderedEntries.map(([key, value]) => {
            if (value === null || value === undefined) return null;
            return (
              <Fragment key={key}>
                <Grid key={key} item md={5} xs={12}>
                  <PalTypography fontWeight={600} variant="body1">
                    {getLabel(key)}
                  </PalTypography>
                </Grid>
                <Grid key={key} item md={7} xs={12}>
                  <PalTypography variant="body2">
                    {value === "" ? (
                      <Box color="secondary.main" component={"span"}>
                        N/A
                      </Box>
                    ) : (
                      value
                    )}
                  </PalTypography>
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

export const ManualIntegrationModal = withCardon(
  function ManualIntegrationModalContent({ integrationName, get, inputFields, state, showDelete }) {
    const { t } = useTranslation();
    const formik = useFormik({
      initialValues: inputFields.reduce((acc, item) => {
        acc[item.key] = state?.[item.key] || "";
        return acc;
      }, {}),
      validationSchema: Yup.object().shape(
        inputFields.reduce((acc, item) => {
          if (item.required) {
            if (item.type === "switch") {
              acc[item.key] = Yup.boolean().required("Required").nullable();
            } else {
              acc[item.key] = Yup.string().required("Required").nullable();
            }
          }
          return acc;
        }, {})
      ),
      onSubmit: async (values) => {
        get(values)();
      },
    });
    const toCapitalCase = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleOnDelete = async () => {
      //set all values as "";
      const emptyValues = inputFields.reduce((acc, item) => {
        acc[item.key] = "";
        return acc;
      }, {});
      formik.setValues(emptyValues);
      get(emptyValues)();
    };
    const modalTitle = t("dashboard.onboarding.integration.manualIntegration").format(toCapitalCase(integrationName));
    return (
      <PRModal
        size="xl"
        title={modalTitle}
        onClick={formik.handleSubmit}
        onClose={get(false)}
        {...(showDelete && { onDelete: handleOnDelete })}
      >
        <Grid container spacing={2}>
          {inputFields.map((item, index) => {
            return (
              <Grid
                key={index}
                item
                {...(!item?.fullWidth
                  ? {
                      md: 6,
                      sm: 12,
                      xs: 12,
                    }
                  : {
                      xs: 12,
                    })}
              >
                {(() => {
                  if (item?.type === "switch") {
                    const handleCheck = (e) => {
                      formik.setFieldValue(item.key, e.target.checked);
                    };
                    return (
                      <Box alignItems="center" display="flex" gap={1}>
                        <PalSwitch
                          fullWidth
                          checked={formik.values[item.key]}
                          invalid={formik.errors[item.key]}
                          name={item.key}
                          required={item.required}
                          onChange={handleCheck}
                        />
                        <PalTypography variant="body2">{item.label}</PalTypography>
                      </Box>
                    );
                  } else {
                    return (
                      <PalTextField
                        fullWidth
                        invalid={formik.touched[item.key] && formik.errors[item.key]}
                        label={item.label}
                        name={item.key}
                        required={item.required}
                        value={formik.values[item.key]}
                        onChange={formik.handleChange}
                      />
                    );
                  }
                })()}
              </Grid>
            );
          })}
        </Grid>
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

export const FacebookMessengerPageSelectModal = withCardon(
  function ManualIntegrationModalContent({ pages, get }) {
    const { t } = useTranslation();
    const formik = useFormik({
      initialValues: {
        page: null,
        pageId: null,
      },
      validationSchema: Yup.object().shape({
        pageId: Yup.string().required("Required").nullable(),
      }),
      onSubmit: async (values) => {
        get(values?.page)();
      },
    });
    const modalTitle = t("dashboard.onboarding.integration.facebookPageSelect.title");

    const handleChangeSelect = (e, item) => {
      formik.setFieldValue("page", item);
      formik.setFieldValue("pageId", item.id);
    };
    return (
      <PRModal size="md" title={modalTitle} onClick={formik.handleSubmit} onClose={get(false)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PalSelect
              fullWidth
              required
              invalid={formik.errors.pageId}
              label={t("dashboard.onboarding.integration.facebookPageSelect.placeholder")}
              labelSelector="name"
              options={pages}
              value={formik.values.page}
              valueSelector="id"
              onChange={handleChangeSelect}
            />
          </Grid>
        </Grid>
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

function StepChannels({ formik, stepKey }) {
  const { t } = useTranslation();
  const project = useSelector(selectProject);

  const dispatch = useDispatch();

  const [authState, setAuthState] = useState({
    whatsapp: false,
    Instagram: false,
    messenger: false,
  });

  useEffect(() => {
    const loadFacebookSDK = () => {
      if (document.getElementById("facebook-jssdk")) return;

      const script = document.createElement("script");
      script.id = "facebook-jssdk";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;

      script.onload = () => {
        console.log("Facebook SDK yüklendi.");
        initializeFacebookSDK();
      };

      document.body.appendChild(script);
    };

    const initializeFacebookSDK = () => {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: import.meta.env.VITE_APP_FACEBOOK_APP_ID || "1427714218189046",
          autoLogAppEvents: true,
          xfbml: true,
          version: "v16.0",
        });
      };
    };

    loadFacebookSDK();
  }, []);

  const startWhatsappSignupFlow = async () => {
    if (!window.FB) {
      console.error("Facebook SDK not loaded yet.");
      return;
    }

    setAuthState({ ...authState, whatsapp: true });
    IntegrationLoadingModal.show({ permanent: true }).then(() => {
      setAuthState({ ...authState, whatsapp: false });
    });

    const fbPromise = new Promise((resolve) => {
      const tempCallback = (event) => {
        if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") {
          return;
        }
        try {
          const data = JSON.parse(event.data);
          if (data.type === "WA_EMBEDDED_SIGNUP") {
            if (data.event === "FINISH") {
              const { phone_number_id, waba_id } = data.data;
              // console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
              window.removeEventListener("message", tempCallback);
              resolve({ phone_number_id, waba_id });
            } else if (data.event === "CANCEL") {
              // const { current_step } = data.data;
              // console.warn("Cancel at ", current_step);
              window.removeEventListener("message", tempCallback);
              resolve(null);
            } else if (data.event === "ERROR") {
              const { error_message } = data.data;
              console.error("error ", error_message);
              window.removeEventListener("message", tempCallback);
              resolve(null);
            }
          }
        } catch {}
      };
      window.addEventListener("message", tempCallback);
    });

    const loginPromise = new Promise((resolve) => {
      window.FB.login(
        function (response) {
          setAuthState({ ...authState, whatsapp: false });
          IntegrationLoadingModal.hide();
          if (response.authResponse) {
            resolve(response.authResponse.code);
          } else {
            console.error("Whatsapp login failed.");
            resolve(null);
          }
        },
        {
          config_id: "1101788468095291",
          response_type: "code",
          override_default_response_type: true,
          extras: {
            setup: {},
            featureType: "",
            sessionInfoVersion: "2",
          },
        }
      );
    });

    await Promise.all([fbPromise, loginPromise]).then(([fbData, accessToken]) => {
      const { phone_number_id, waba_id } = fbData;
      dispatch(
        updateWhatsappOnboarding(project?.id, {
          code: accessToken,
          phone_number_id,
          waba_id,
        })
      );
    });
    dispatch(getChannelList(project?.id));
  };
  const startFacebookMessengerSignupFlow = async () => {
    // await FacebookMessengerPageSelectModal.show({
    //   pages: [
    //     {
    //       id: "23412321321",
    //       name: "AAAAA-palmate",
    //       category: "VVVVVV' market",
    //     },
    //     {
    //       id: "534340979768488",
    //       name: "Test-palmate",
    //       category: "Farmers' market",
    //     },
    //   ],
    // });
    if (!window.FB) {
      console.error("Facebook SDK not loaded yet.");
      return;
    }

    setAuthState({ ...authState, messenger: true });
    IntegrationLoadingModal.show({ permanent: true }).then(() => {
      setAuthState({ ...authState, messenger: false });
    });

    const loginPromise = new Promise((resolve) => {
      window.FB.login(
        function (response) {
          setAuthState({ ...authState, messenger: false });
          IntegrationLoadingModal.hide();
          if (response.authResponse) {
            resolve(response.authResponse.code);
          } else {
            console.error("Whatsapp login failed.");
            resolve(null);
          }
        },
        {
          config_id: "927777429135954",
          response_type: "code",
          override_default_response_type: true,
          extras: {
            setup: {},
            featureType: "",
            sessionInfoVersion: "2",
          },
        }
      );
    });

    //   {
    //     "status": "success",
    //     "pages": [
    //         {
    //             "id": "534340979768488",
    //             "name": "Test-palmate",
    //             "category": "Farmers' market"
    //         }
    //     ]
    //   }

    const accessToken = await loginPromise;
    if (accessToken) {
      const response = await dispatch(
        updateFacebookMessengerOnboarding(project?.id, {
          action_type: "business_integration",
          code: accessToken,
        })
      );
      if (response?.status === "success") {
        if (!response?.pages) {
          AlertHelper.showWarning(t("common.errorIntegrationMessage.facebookMessenger.noPage"));

          return;
        } else {
          const selectedPage = await FacebookMessengerPageSelectModal.show({ pages: response.pages });
          if (!selectedPage) return;
          await dispatch(
            updateFacebookMessengerOnboarding(project?.id, {
              action_type: "page_integration",
              page_id: selectedPage.id,
            })
          );
        }
      } else {
        AlertHelper.showError(t("common.errorIntegrationMessage"));
      }

      dispatch(getChannelList(project?.id));
    }
  };
  const startInstagramLogin = async () => {
    if (window.INS_POPUP) {
      window.INS_POPUP.focus();
      return;
    }
    const clientId = import.meta.env.VITE_APP_INSTAGRAM_CLIENT_ID || "1848589722341734";
    const redirectUri = window.location.origin + import.meta.env.VITE_APP_PUBLIC_URL + "/instagram-callback/";

    const baseAddress = "https://www.instagram.com/oauth/authorize";
    const params = {
      enable_fb_login: 0,
      force_authentication: 1,
      client_id: clientId,
      redirect_uri: redirectUri,
      response_type: "code",
      scope:
        "instagram_business_basic,instagram_business_manage_messages,instagram_business_manage_comments,instagram_business_content_publish,instagram_business_manage_insights",
    };

    const authUrl = `${baseAddress}?${new URLSearchParams(params).toString()}`;

    const popupWidth = 600;
    const popupHeight = 700;
    const left = window.screenX + (window.innerWidth - popupWidth) / 2;
    const top = window.screenY + (window.innerHeight - popupHeight) / 2;

    IntegrationLoadingModal.show().then(() => {
      setAuthState({ ...authState, Instagram: false });
      if (window.INS_POPUP && !window.INS_POPUP.closed) {
        window.INS_POPUP.close();
      }
    });
    setAuthState({ ...authState, Instagram: true });
    window.INS_POPUP = window.open(
      authUrl,
      "InstagramLogin",
      `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`
    );

    const popupEventListener = async (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }
      if (event.data?.code) {
        window.removeEventListener("message", popupEventListener);
        IntegrationLoadingModal.hide();
        // setIntegrationState({ ...integrationState, Instagram: event.data.code });
        // formik.setFieldValue("instagram.access_token", event.data.code);
        await dispatch(
          updateInstagramOnboarding(project?.id, {
            code: event.data.code,
          })
        );
        await dispatch(getChannelList(project?.id));
      }
    };
    window.addEventListener("message", popupEventListener);

    const interval = setInterval(() => {
      if (!window.INS_POPUP || window.INS_POPUP.closed) {
        clearInterval(interval);
        setAuthState({ ...authState, Instagram: false });
        IntegrationLoadingModal.hide();
        window.INS_POPUP = null;
      }
    }, 500);
  };
  const formikValues = formik.values?.[stepKey];

  const handleClickShowDetails = (integration) => () => {
    IntegrationDetailModal.show({ integration });
  };

  const handleManualIntegration = (integrationName) => async () => {
    let inputFields = [];
    let state = {};
    if (integrationName === "telegram") {
      state = formikValues?.telegram;
      inputFields = [
        {
          label: "Name",
          key: "name",
          required: true,
        },
        {
          label: "App ID",
          key: "app_id",
          required: true,
        },
        {
          label: "Access Token",
          key: "access_token",
          required: true,
        },
        {
          label: "Secret",
          key: "secret",
          required: true,
        },
      ];
    }

    const responseState = await ManualIntegrationModal.show({ integrationName, inputFields, state });
    if (responseState) {
      const newState = {
        id: state.id,
        ...responseState,
      };
      if (newState?.access_token && newState?.access_token === state?.access_token) {
        delete newState.access_token;
      }
      if (newState?.secret && newState?.secret === state?.secret) {
        delete newState.secret;
      }
      await dispatch(createOrUpdateChannel(project.id, newState));
      await dispatch(getChannelList(project.id));
    }
  };

  return (
    <StepContainer>
      <Grid container spacing={2}>
        <Grid item mb={2} xs={12}>
          <PalTypography color="textPrimary" fontWeight={600} variant="h5">
            Channel Integrations
          </PalTypography>
          <PalTypography gutterBottom variant="body1">
            Please select the channels you would like to integrate with your assistant. This will allow your assistant
            to interact with users on these platforms.
          </PalTypography>
        </Grid>
        <Grid item xs={12}>
          <Box
            gap={3}
            sx={{
              borderRadius: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <IntegrationItem
                  desc="Connect your Instagram account to chat with your customers."
                  image={InstagramIcon}
                  isDone={formikValues?.instagram?.access_token}
                  name="Instagram"
                  onClick={startInstagramLogin}
                  onClickDetail={handleClickShowDetails(formikValues?.instagram)}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <IntegrationItem
                  desc="Connect your Facebook Messenger account to chat with your customers."
                  image={FacebookMessengerIcon}
                  isDone={formikValues?.messenger?.access_token}
                  name="Facebook Messenger"
                  onClick={startFacebookMessengerSignupFlow}
                  onClickDetail={handleClickShowDetails(formikValues?.messenger)}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <IntegrationItem
                  desc="Connect your WhatsApp business account to communicate with your customers from your business number."
                  image={WhatsAppIcon}
                  isDone={formikValues?.whatsapp?.access_token}
                  name="WhatsApp"
                  onClick={startWhatsappSignupFlow}
                  onClickDetail={handleClickShowDetails(formikValues?.whatsapp)}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <IntegrationItem
                  desc="Connect your Telegram account to chat with your customers."
                  image={TelegramIcon}
                  isDone={formikValues?.telegram?.access_token}
                  onClickConfigure={handleManualIntegration("telegram")}
                  name="Telegram"
                  // onClick={handleManualIntegration("telegram")}
                  onClickDetail={handleClickShowDetails(formikValues?.telegram)}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </StepContainer>
  );
}

// #endregion StepChannels

// #region stepECommerce

export function ECommerceItem({
  image: Image,
  imageStyles,
  name,
  onClick,
  onClickConfigure,
  onClickTest,
  onClickDetail,
  isDone,
  isDisabled,
}) {
  const { t } = useTranslation();
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      spacing={1}
      sx={{
        userSelect: "none",
        border: "1px solid",
        borderColor: "grey.300",
        borderRadius: 1,
        padding: 2,
        height: 120,
        ...(isDone &&
          !isDisabled && {
            backgroundColor: (theme) => lighten(theme.palette.success.light, 0.91),
          }),

        ...(isDisabled && {
          backgroundColor: (theme) => lighten(theme.palette.error.light, 0.91),
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          position: "relative",
          justifyContent: "center",
          width: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            maxWidth: "calc(100% - 60px)",
            svg: {
              // width: 64,
              height: 36,
              ...imageStyles,
            },
          }}
        >
          <PalTooltip title={name}>
            <Image />
          </PalTooltip>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 2,
            right: 2,
          }}
        >
          <PalTooltip
            title={
              isDone
                ? isDisabled
                  ? t("dashboard.onboarding.integration.integrationItem.disabled")
                  : t("dashboard.onboarding.integration.integrationItem.connected")
                : t("dashboard.onboarding.integration.integrationItem.disconnected")
            }
          >
            {isDone && !isDisabled && <TaskAlt color="success" />}
            {isDone && isDisabled && <HighlightOffIcon color="error" />}
            {!isDone && <PanoramaFishEye color="secondary" />}
          </PalTooltip>
        </Box>
      </Box>
      <Box alignItems={"center"} display="flex" gap={1} mt={"auto"} width={1}>
        <PalTooltip {...(!isDone && { open: false })} title="Revoke the access from the integration's dashboard">
          <Box
            sx={{
              ...(isDone && {
                cursor: "not-allowed !important",
              }),
            }}
            width={1}
          >
            <PalButton
              fullWidth
              color={onClickConfigure ? "primary" : !isDone ? "primary" : "error"}
              disabled={onClickConfigure ? false : isDone}
              sx={{
                flex: 1,
              }}
              onClick={onClickConfigure || onClick}
              {...(onClickConfigure && { startIcon: <Settings /> })}
              variant="outlined"
            >
              <PalTypography fontWeight={600} variant="body1">
                {onClickConfigure ? "Configure" : isDone ? "Disconnect" : "Connect"}
              </PalTypography>
            </PalButton>
          </Box>
        </PalTooltip>
        {isDone && (
          <PalTooltip title="Test the integration">
            <PalButton
              color="primary"
              sx={{ minWidth: "auto", flex: "0 0 auto" }}
              variant="outlined"
              onClick={onClickTest}
            >
              <CloudQueue />
            </PalButton>
          </PalTooltip>
        )}
        {isDone && (
          <PalTooltip title="Show integration details">
            <PalIconButton color="primary" sx={{ flex: "0 0 auto" }} variant="text" onClick={onClickDetail}>
              <Info />
            </PalIconButton>
          </PalTooltip>
        )}
      </Box>
    </Box>
  );
}

function StepECommerce({ formik, stepKey, nextAction, setFieldValue }) {
  const project = useSelector(selectProject);
  const dispatch = useDispatch();
  const formikValues = formik.values?.[stepKey];

  useEffect(() => {
    if (project?.id) {
      dispatch(getECommerceList(project.id));
    }
  }, [dispatch, project?.id]);

  const handleClickShowDetails = (integration) => () => {
    IntegrationDetailModal.show({ integration: integration?.credentials });
  };

  const handleManualIntegration = (integrationName) => async () => {
    const integration = eCommerceDefinitionList.find((item) => item.key === integrationName);
    const inputFields = integration.configParams.map((item) => {
      return {
        ...item,
        label: item.label,
        key: item.key,
        required: item.required,
      };
    });
    const integrationData = formikValues?.[integrationName];
    const state = integrationData?.credentials || {};
    const responseState = await ManualIntegrationModal.show({ integrationName: integration.name, inputFields, state });
    if (responseState) {
      const newState = {
        ...responseState,
      };
      if (newState?.access_token && newState?.access_token === state?.access_token) {
        delete newState.access_token;
      }
      if (newState?.refresh_token && newState?.refresh_token === state?.refresh_token) {
        delete newState.refresh_token;
      }
      if (newState?.client_secret && newState?.client_secret === state?.client_secret) {
        delete newState.client_secret;
      }
      if (newState?.password && newState?.password === state?.password) {
        delete newState.password;
      }
      if (newState?.member_code && newState?.member_code === state?.member_code) {
        delete newState.member_code;
      }
      await dispatch(
        createOrUpdateECommerceIntegration(project.id, {
          ...integrationData,
          credentials: newState,
        })
      );
      await dispatch(getECommerceList(project.id));
    }
  };
  return (
    <StepContainer>
      <Grid container spacing={2}>
        <Grid item mb={2} xs={12}>
          <PalTypography color="textPrimary" fontWeight={600} variant="h5">
            E-Commerce Integration
          </PalTypography>
          <PalTypography gutterBottom variant="body1">
            Please select the e-commerce platform you would like to integrate with your assistant. This will allow your
            assistant to access your store's extra information and provide better customer service.
          </PalTypography>
        </Grid>
        <Grid item xs={12}>
          <Box
            gap={3}
            sx={{
              borderRadius: 1,
            }}
          >
            <Grid container spacing={2}>
              {/* <Grid item md={6} sm={12} xs={12}>
                <IntegrationItem
                  desc="Connect your Instagram account to chat with your customers."
                  image={InstagramIcon}
                  isDone={formikValues?.instagram?.access_token}
                  name="Instagram"
                  onClick={startInstagramLogin}
                  onClickDetail={handleClickShowDetails(formikValues?.instagram)}
                />
              </Grid> */}
              {eCommerceDefinitionList.map((item) => {
                const isAllCredentialsFilled = item.configParams.every((param) => {
                  return formikValues?.[item.key]?.credentials?.[param.key];
                });
                return (
                  <Grid key={item.key} item md={4} sm={6} xs={12}>
                    <ECommerceItem
                      desc={`Connect your ${item.name} account to chat with your customers.`}
                      image={item.logo}
                      imageStyles={item.logoStyles}
                      isDone={isAllCredentialsFilled}
                      name={item.name}
                      onClick={handleManualIntegration(item.key)}
                      onClickDetail={handleClickShowDetails(formikValues?.[item.key])}
                      {...(isAllCredentialsFilled && { onClickConfigure: handleManualIntegration(item.key) })}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </StepContainer>
  );
}
// #endregion stepECommerce
// // #region Step7
// const PricingBox = styled.div`
//   background-color: #f5f5f5;
//   padding: ${(props) => props.theme.spacing(3)};
//   border-radius: 4px;
//   height: 500px;
// `;

// function PricingBoxSimple() {
//   return (
//     <PricingBox>
//       <PalTypography gutterBottom color="textPrimary" fontWeight={600} variant="h5">
//         Creator
//       </PalTypography>

//       <PalTypography gutterBottom color="textSecondary" variant="body2">
//         Powerful tools for creators to build and grow their business.
//       </PalTypography>

//       <Box alignItems={"center"} display="flex">
//         <PalTypography color="textPrimary" fontWeight={600} variant="h5">
//           $19.99
//           <PalTypography color="textSecondary" component="span" variant="body2">
//             /month
//           </PalTypography>
//         </PalTypography>
//         <Chip color="primary" label="Save 20%" size="small" sx={{ ml: 0.5 }} />
//       </Box>

//       <PalTypography gutterBottom color="textSecondary" variant="body2">
//         Includes:
//       </PalTypography>
//       <Box>
//         <ul>
//           <li>Unlimited chatbot conversations</li>
//           <li>Unlimited knowledge sources</li>
//           <li>Unlimited integrations</li>
//           <li>24/7 live chat support</li>
//         </ul>
//       </Box>
//     </PricingBox>
//   );
// }

// function PricingBoxPro() {
//   return (
//     <PricingBox>
//       <PalTypography gutterBottom color="textPrimary" fontWeight={600} variant="h5">
//         Pro
//       </PalTypography>

//       <PalTypography gutterBottom color="textSecondary" variant="body2">
//         Advanced tools for businesses to scale their operations.
//       </PalTypography>

//       <Box alignItems={"center"} display="flex">
//         <PalTypography color="textPrimary" fontWeight={600} variant="h5">
//           $49.99
//           <PalTypography color="textSecondary" component="span" variant="body2">
//             /month
//           </PalTypography>
//         </PalTypography>
//         <Chip color="primary" label="Save 20%" size="small" sx={{ ml: 0.5 }} />
//       </Box>

//       <PalTypography gutterBottom color="textSecondary" variant="body2">
//         Includes:
//       </PalTypography>
//       <Box>
//         <ul>
//           <li>Unlimited chatbot conversations</li>
//           <li>Unlimited knowledge sources</li>
//           <li>Unlimited integrations</li>
//           <li>24/7 live chat support</li>
//           <li>Custom branding</li>
//           <li>Advanced analytics</li>
//         </ul>
//       </Box>
//     </PricingBox>
//   );
// }

// function PricingBoxEnterprise() {
//   return (
//     <PricingBox>
//       <PalTypography gutterBottom color="textPrimary" fontWeight={600} variant="h5">
//         Enterprise
//       </PalTypography>

//       <PalTypography gutterBottom color="textSecondary" variant="body2">
//         Custom solutions for businesses with unique requirements.
//       </PalTypography>

//       <Box alignItems={"center"} display="flex">
//         <PalTypography color="textPrimary" fontWeight={600} variant="h5">
//           Contact Us
//         </PalTypography>
//       </Box>

//       <PalTypography gutterBottom color="textSecondary" variant="body2">
//         Includes:
//       </PalTypography>
//       <Box>
//         <ul>
//           <li>Unlimited chatbot conversations</li>
//           <li>Unlimited knowledge sources</li>
//           <li>Unlimited integrations</li>
//           <li>24/7 live chat support</li>
//           <li>Custom branding</li>
//           <li>Advanced analytics</li>
//           <li>Custom solutions</li>
//         </ul>
//       </Box>
//     </PricingBox>
//   );
// }
// function Step7({ formik }) {
//   const handleAddKnowledgeSource = async () => {
//     const email = await AddKnowledgeSourceModal.show();
//     // if (email) {
//     //   formik.setFieldValue("agentList", [...formik.values.agentList, email]);
//     // }
//   };
//   return (
//     <StepContainer>
//       <Grid container spacing={2}>
//         <Grid item mb={2} xs={12}>
//           <PalTypography color="textPrimary" fontWeight={600} variant="h5">
//             Subscription Plan
//           </PalTypography>
//           <PalTypography gutterBottom variant="body1">
//             Choose a subscription plan that best fits your needs. You can upgrade or downgrade your plan at any time.
//           </PalTypography>
//         </Grid>
//         <Grid item xs={4}>
//           <PricingBoxSimple />
//         </Grid>
//         <Grid item xs={4}>
//           <PricingBoxPro />
//         </Grid>
//         <Grid item xs={4}>
//           <PricingBoxEnterprise />
//         </Grid>
//       </Grid>
//     </StepContainer>
//   );
// }
// // #endregion Step7

// #region Onboarding

const stepsDefinition = {
  stepBasic: {
    key: "stepBasic",
    order: 1,
    component: StepBasic,
    isOptional: false,
  },
  stepGeneralInformation: {
    key: "stepGeneralInformation",
    order: 2,
    component: StepGeneralInformation,
    isOptional: false,
  },
  stepBotSettings: {
    key: "stepBotSettings",
    order: 3,
    component: StepBotSettings,
    isOptional: false,
  },
  stepLivechat: {
    key: "stepLivechat",
    order: 4,
    component: StepLivechat,
    isOptional: true,
  },
  stepChatbot: {
    key: "stepChatbot",
    order: 5,
    component: StepPopup,
    isOptional: false,
  },
  stepRag: {
    key: "stepRag",
    order: 6,
    component: StepRag,
    isOptional: false,
  },
  stepChannels: {
    key: "stepChannels",
    order: 7,
    component: StepChannels,
    isOptional: true,
  },
  stepECommerce: {
    key: "stepECommerce",
    order: 8,
    component: StepECommerce,
    isOptional: true,
  },
};
const stepsList = Object.values(stepsDefinition).sort((a, b) => a.order - b.order);

// #region Onboarding Component
const StyledDiv = styled.div`
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 100%;
`;

const LogoWrapper = styled.div`
  display: flex;
  margin-top: 0px;
  margin-left: 0px;
  svg {
    width: 160px;
  }
`;

const HeaderBarGrid = styled(Grid)`
  height: ${headerHeight}px;

  padding: 0 ${(props) => props.theme.spacing(3)};
  border-bottom: 1px solid ${borderColor};
`;

const PanelLeft = styled(Box)`
  width: 100%;
  height: calc(100% - ${actionBarHeight}px);
  position: relative;
  overflow: visible;
  max-width: ${(props) => props.theme.breakpoints.values.md}px; //600px
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
`;

const panelRightWidthSmall = 400;
const panelRightWidthLarge = 500;
const PanelRight = styled(Box, StyledHelper.sfw("fullHeight"))`
  background-color: #f5f5f5;
  height: ${(props) => (props.fullHeight ? "100%" : "calc(100vh - " + headerHeight + "px)")};
  border-left: 1px solid ${borderColor};
  position: relative;
  animation: animateBg 21s linear infinite;
  background-image: linear-gradient(
    90deg,
    #cf5c5c44,
    #c19b4a44,
    #def2a044,
    #c6ee4a44,
    #42eca644,
    #64b3d944,
    #208ea244,
    #498ada44,
    #5b73df44,
    #897ed344,
    #cf5c5c44,
    #c19b4a44
  );
  background-size: 1100% 100%;

  @keyframes animateBg {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 0%;
    }
  }
`;

const RightImgWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`;

const RightStepInfo = styled.div`
  position: absolute;
  bottom: ${40}px;
  left: 50%;
  transform: translateX(-50%);
  padding: ${(props) => props.theme.spacing(2)};
  background-color: rgba(255, 255, 255);
  border-radius: 4px;
  border: 1px solid ${borderColor};
  width: calc(100% - 40px * 2);
`;

const StepContainer = styled.div`
  padding: ${(props) => props.theme.spacing(3)};
  padding-top: 0;
`;

const StepFooterContainerGrid = styled(Grid)`
  border-top: 1px solid ${borderColor};
  height: ${actionBarHeight}px;
  padding: 0 ${(props) => props.theme.spacing(2)};
`;

const NavigateButton = styled(PalButton)`
  height: 40px;
  font-size: ${(props) => props.theme.typography.body1.fontSize};
  line-height: 1.5;
`;

const LightTooltip = styled(({ className, ...props }) => <PalTooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#444444",
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(14),
    },
  })
);

const RequiredComp = () => {
  return (
    <Box color="error.main" component="span">
      &nbsp;*
    </Box>
  );
};
const setPreviewSettings = async (state, { botName } = {}) => {
  const iframeDom = document.getElementById(IFRAME_PREVIEW_ID);
  const iframeWindow = iframeDom?.contentWindow;
  const frameDocument = iframeWindow?.document;
  if (!frameDocument) return;
  // const framePopup = iframeWindow?.popup;
  const framePopup = frameDocument.getElementById("_palamar_chat_iframe")?.contentWindow?.popup;
  await framePopup?.enablePreviewMessages({ botName: botName || state.popup_title });
  // const settingState = selectPopupSettingsState(store.getState());
  const welcomeFormBodyForPopup = {
    // form_items: settingState.welcome_form?.form_items,
    // popup_button_icon_path: settingState.popup_button_icon_path,
    // popup_button_type: settingState.popup_button_type,
    // popup_header_icon_path: settingState.popup_header_icon_path,
    // popup_header_icon_type: settingState.popup_header_icon_type,
    // popup_bot_icon_type: settingState.popup_bot_icon_type,
    // popup_bot_icon_path: settingState.popup_bot_icon_path,
    // popup_primary_color: settingState.popup_primary_color,
    // popup_title: settingState.popup_title,
    // popup_welcome_text: settingState.popup_welcome_text,
    // start_anonymous_chat: settingState.welcome_form?.optional,
    // welcome_msg: settingState.welcome_msg,
    // popup_style_info: settingState.popup_style_info,
    // chatbot_info: settingState.chatbot_info,
    // system_chatbot_info: settingState.system_chatbot_info,
    ...state,
  };

  const primaryColor = "#50C8F4";
  const light = chroma(primaryColor).brighten(1.5).hex();
  // const dark = chroma(primaryColor).darken(0.7).hex();
  const titleColor = Utils.determineTextColor(primaryColor);
  const contrastColor = Utils.determineTextColor(light);
  const advancedStyle = ThemeMapper.initializeStyle({
    primary_color: primaryColor,
    header_color: titleColor,
    header_background_color: primaryColor,
    chat_balloon_customer_text_color: contrastColor,
    chat_balloon_customer_background_color: primaryColor,
    chat_balloon_customer_border: `1px solid ${light}`,
    header_icon_color: titleColor,
  });
  //delete specific style bot and agent configuration. these options will be filled using chat_ballon_left_... variants.
  delete advancedStyle.chat_balloon_bot_text_color;
  delete advancedStyle.chat_balloon_bot_background_color;
  delete advancedStyle.chat_balloon_bot_border;

  delete advancedStyle.chat_balloon_agent_text_color;
  delete advancedStyle.chat_balloon_agent_background_color;
  delete advancedStyle.chat_balloon_agent_border;

  welcomeFormBodyForPopup.chatbot_info ??= {};
  welcomeFormBodyForPopup.chatbot_info = {
    ...welcomeFormBodyForPopup.chatbot_info,
    enable_advanced_style: true,
    style: advancedStyle,
  };

  if (welcomeFormBodyForPopup.popup_button_icon_path instanceof File) {
    const file = welcomeFormBodyForPopup.popup_button_icon_path;
    const result = await asyncReader(file);
    welcomeFormBodyForPopup.popup_button_icon_path = result;
  }

  const mappedData = popupSettingsMapper(welcomeFormBodyForPopup);
  framePopup.IFrameHelper.setPropertyToRoot([
    ["--webchat-icon-margin-x", `${mappedData.popup_style_info.popup_margin_x}px`],
    ["--webchat-icon-margin-y", `${mappedData.popup_style_info.popup_margin_y}px`],
    ["--webchat-icon-size", `${mappedData.popup_style_info.popup_icon_size}px`],
    ["--webchat-height", `${mappedData.popup_style_info.chat_height}px`],
  ]);
  framePopup.setWelcomeOptions(mappedData);
  // framePopup.setViewModeDelayed(settingState.welcome_form?.optional ? "chat" : "welcome");
  framePopup.setViewModeDelayed("chat");
  // framePopup.setWelcomeMsgShowedStatus(false);
  framePopup.setPopupSizeModePosition(mappedData?.popup_style_info?.popup_position);
};
const showPopupPreviewBalloon = (state) => {
  const iframeDom = document.getElementById(IFRAME_PREVIEW_ID);
  const iframeWindow = iframeDom?.contentWindow;
  const frameDocument = iframeWindow?.document;
  if (!frameDocument) return;
  const framePopup = frameDocument.getElementById("_palamar_chat_iframe")?.contentWindow?.popup;

  framePopup.setWelcomeMsgShowedStatus(state);
};
const HelpIconWrapper = ({ title, children }) => {
  return (
    <Box alignItems={"center"} display="flex" gap={0.5}>
      {children}
      <LightTooltip
        enterDelay={0}
        leaveDelay={0}
        placement="top"
        title={title}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 0 }}
      >
        <Help fontSize="small" />
      </LightTooltip>
    </Box>
  );
};

// #endregion Onboarding Component

const IFRAME_PREVIEW_ID = "popup-preview";
const DEFAULT_STEP = stepsList[0].key;

function Onboarding() {
  const { id: activeProjectId } = useParams();
  const { company: companyId } = useQueryParams();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // const [currentStep, setCurrentStep] = useState(0);
  const [currentStepKey, setCurrentStepKey] = useState(DEFAULT_STEP);
  const [invitedUserList, setInvitedUserList] = useState([]);
  const [hasIframeError, setHasIframeError] = useState(false);
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  // const [showPopupPreviewDevMode, setShowPopupPreviewDevMode] = useState(false);
  const [direction, setDirection] = useState(1);
  const [isPreviewBotReady, setIsPreviewBotReady] = useState(false);

  const callcenterSettings = useSelector(selectCallcenterSettings);
  const popupSettingsState = useSelector(selectPopupSettingsState);
  const project = useSelector(selectProject);
  const company = useSelector(selectCompany);
  const channelList = useSelector(selectChannelList);
  const eCommerceList = useSelector(selectECommerceList);

  const [botSettings, setBotSettings] = useState(null);

  const { currentStepIndex, currentStepItem, nextStepItem, previousStepItem } = useMemo(() => {
    const currentStepIndex = stepsList.findIndex((item) => item.key === currentStepKey);
    const currentStepItem = stepsList[currentStepIndex];
    const nextStepItem = stepsList[currentStepIndex + 1];
    const previousStepItem = stepsList[currentStepIndex - 1];

    return { currentStepIndex, currentStepItem, nextStepItem, previousStepItem };
  }, [currentStepKey]);

  useEffect(() => {
    const mainBot = project?.settings?.main_bot;
    if (!mainBot || !project?.id) return;

    getBotSettings(project.id, mainBot).then((response) => {
      setBotSettings(response);
    });
  }, [project?.settings?.main_bot, project?.id]);

  useEffect(() => {
    if (!project?.id) return;
    dispatch(getCompany(project?.company));
  }, [dispatch, project?.id, project?.company]);
  const userInfo = useSelector(selectUserInfo);

  useEffect(() => {
    if (!userInfo.is_superuser && !companyId) {
      const firstProject = userInfo.project_roles?.[0]?.project;
      if (firstProject) {
        HistoryHelper.replace(`/dashboard/setup/${firstProject}`);
      }
    }
  }, [t, dispatch, activeProjectId, userInfo, companyId]);

  useEffect(() => {
    if (activeProjectId) {
      dispatch(getProject(activeProjectId));
      dispatch(getChannelList(activeProjectId));

      dispatch(getPopupSettings(activeProjectId, { skipUpdate: true })).then(() => {
        if (project.status === onboardingProjectStatus.ONB) {
          dispatch(initPopupSettingsState());
        }
      });
    }
  }, [t, dispatch, activeProjectId, project?.status]);

  const { whatsappData, messengerData, telegramData, instagramData } = useMemo(() => {
    const whatsappData = channelList.find((item) => item.integration_type === "whatsapp");
    const messengerData = channelList.find((item) => item.integration_type === "messenger");
    const telegramData = channelList.find((item) => item.integration_type === "telegram");
    const instagramData = channelList.find((item) => item.integration_type === "instagram");
    return { whatsappData, messengerData, telegramData, instagramData };
  }, [channelList]);

  // useEffect(() => {
  //   if (userInfo.project_roles?.length > 0) {
  //     dispatch(getProject(userInfo.project_roles[0].project));
  //   }
  // }, [t, dispatch, userInfo]);

  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      //regular settings;
      welcomeForm: {
        ...popupSettingsState,
        welcome_msg: popupSettingsState.welcome_msg || t("dashboard.onboarding.welcomeMsg"),
      },

      files: {},
      [stepsDefinition.stepBasic.key]: {
        assistant_name: project?.name,
        assistant_languages: project?.main_language || project?.settings?.chatbot_languages?.[0] || null,
        assistant_template: null,
      },
      [stepsDefinition.stepGeneralInformation.key]: {
        assistant_supported_languages: project?.settings?.supported_languages?.map((a) => a.language) || [],
        allowed_mail_domains: company?.allowed_mail_domains?.join("\n") || userInfo.email.split("@")?.[1] || "",
        address: company?.address || "",
        company_logo: company?.company_logo || "",
        company_logo_file: null,

        company_details: {
          // industry: "",
          // company_size: "",
          // usage_count: "",
          // department: "",
          // role: "",
          ...(company?.company_details || {}),
        },
      },
      [stepsDefinition.stepLivechat.key]: {
        agent_can_leave_session: callcenterSettings?.agent_can_leave_session,
        work_start_time: callcenterSettings?.working_hours?.start
          ? DateHelper.getDateTime(callcenterSettings?.working_hours?.start, "HH:mm")
          : DateHelper.getDateTime().startOf("day").add(8, "hours"),
        work_end_time: callcenterSettings?.working_hours?.end
          ? DateHelper.getDateTime(callcenterSettings?.working_hours?.end, "HH:mm")
          : DateHelper.getDateTime().startOf("day").add(18, "hours"),
        agentList: [],
      },
      [stepsDefinition.stepRag.key]: {
        sourceList: [],
        ragIntentId: null,
        ragFactGroup: {},
        ragFactGroupId: null,
      },
      [stepsDefinition.stepBotSettings.key]: {
        bot_name: botSettings?.name || "",
        awareness_prompt: botSettings?.awareness_prompt || "",
      },
      [stepsDefinition.stepChannels.key]: {
        whatsapp: {
          ...whatsappData,
          access_token: whatsappData?.access_token || "",
        },
        messenger: {
          ...messengerData,
          access_token: messengerData?.access_token || "",
        },
        telegram: {
          ...telegramData,
          access_token: telegramData?.access_token || "",
        },
        instagram: {
          ...instagramData,
          access_token: instagramData?.access_token || "",
        },
      },
      [stepsDefinition.stepECommerce.key]: {
        [eCommerceProject.YT]: eCommerceList.find((item) => item.platform_type === eCommerceProject.YT),
        [eCommerceProject.PD]: eCommerceList.find((item) => item.platform_type === eCommerceProject.PD),
        [eCommerceProject.PP]: eCommerceList.find((item) => item.platform_type === eCommerceProject.PP),
        [eCommerceProject.PT]: eCommerceList.find((item) => item.platform_type === eCommerceProject.PT),
        [eCommerceProject.PS]: eCommerceList.find((item) => item.platform_type === eCommerceProject.PS),
        [eCommerceProject.PI]: eCommerceList.find((item) => item.platform_type === eCommerceProject.PI),
        [eCommerceProject.PTS]: eCommerceList.find((item) => item.platform_type === eCommerceProject.PTS),
      },
    },
    validationSchema: Yup.object({
      [stepsDefinition.stepBasic.key]: Yup.mixed().when({
        is: () => currentStepKey === stepsDefinition.stepBasic.key,
        then: Yup.object({
          assistant_name: Yup.string().required("Required").min(3, "Must be at least 3 characters"),
          assistant_languages: Yup.string().nullable().required("Required"),
          assistant_template: Yup.number()
            .when({
              is: () => !project?.id,
              then: Yup.number().nullable().required("Required"),
            })
            .nullable(),
        }),
      }),
      [stepsDefinition.stepGeneralInformation.key]: Yup.mixed().when({
        is: () => currentStepKey === stepsDefinition.stepGeneralInformation.key,
        then: Yup.object({
          allowed_mail_domains: Yup.string()
            .required("Required")
            .min(3, "Must be at least 3 characters")
            .test("is-valid-domain", "Please enter a valid domain", (value) => {
              const domains = value.split(/\r?\n/);
              return domains.every((domain) => Utils.isValidDomain(domain));
            }),
        }),
      }),
      [stepsDefinition.stepBotSettings.key]: Yup.mixed().when({
        is: () => currentStepKey === stepsDefinition.stepBotSettings.key,
        then: Yup.object({
          bot_name: Yup.string().required("Required").min(3, "Must be at least 3 characters"),
        }),
      }),
    }),
    onSubmit: async (values) => {},
  });

  useEffect(() => {
    if (project?.id) {
      // setFirstProject(userInfo.projects[0]);
      getInvitedUsers(project.id).then((res) => {
        const list = [...new Set(res.results.map((item) => item.email))];
        setInvitedUserList(list);
        formik.setFieldValue(`${stepsDefinition.stepLivechat.key}.agentList`, list);
      });
    }
    // dispatch(getCurrentUser());
  }, [dispatch, project?.id]);

  useEffect(() => {
    document.title = t("layout.sidebar.onboarding");
    if (company?.id) {
      document.title = `${t("layout.sidebar.onboarding")} - ${company.name}`;
    }
  }, [t, dispatch, company]);

  const saveRagState = async () => {
    const sourceList = formik.values[stepsDefinition.stepRag.key].sourceList;
    const mainBotId = project.settings.main_bot;

    await dispatch(
      updateRagState(
        project.id,
        mainBotId,
        {
          source_webpages: [
            ...sourceList
              .filter((item) => item.type === onboardingRagType.webpage)
              .map((item) => ({ id: item.payload.id })),
          ],
          source_files: [
            ...sourceList
              .filter((item) => item.type === onboardingRagType.file)
              .map((item) => ({ id: item.payload.id })),
          ],
          text_content: sourceList
            .filter((item) => item.type === onboardingRagType.content)
            .map((item) => item.payload.text)
            .join(OnboardingContentSeparator),
        },
        { successMsg: false }
      )
    );
  };
  const handleClickNextOrPrevious =
    (isNextSkipOrPrevious) =>
    async (e, { preventContinue } = { preventContinue: false }) => {
      //go forward if clicked next or skip
      const directionValue = isNextSkipOrPrevious !== false ? 1 : -1;
      const isForward = directionValue === 1;
      const isSkip = isNextSkipOrPrevious === null;

      const validationResult = await formik.validateForm();
      if (Object.keys(validationResult).length > 0) {
        formik.submitForm();
        return;
      }

      if (currentStepItem.key === stepsDefinition.stepChatbot.key) {
      }

      if (currentStepItem.key === stepsDefinition.stepBasic.key && isForward) {
        const newProject = await dispatch(
          createOrUpdateProject(
            {
              name: formik.values[stepsDefinition.stepBasic.key].assistant_name,
              id: project.id,
              main_language: formik.values[stepsDefinition.stepBasic.key].assistant_languages,
              ...(formik.values[stepsDefinition.stepBasic.key].assistant_template && {
                from_template: formik.values[stepsDefinition.stepBasic.key].assistant_template,
              }),
              ...(companyId && { company_id: Number(companyId) }),
            },
            { successMsg: false }
          )
        );
        if (!activeProjectId) {
          HistoryHelper.replace(`/dashboard/setup/${newProject.id}`);
        }
      } else if (currentStepItem.key === stepsDefinition.stepGeneralInformation.key && isForward) {
        const supportedLangs = formik.values[
          stepsDefinition.stepGeneralInformation.key
        ].assistant_supported_languages.map((lang) => ({ language: lang }));
        await dispatch(
          updateProjectSettings(
            project?.id,
            {
              projectSettingsId: project?.settings?.id,
              chatbot_languages: [formik.values[stepsDefinition.stepBasic.key].assistant_languages],
              supported_languages: supportedLangs,
            },
            { successMsg: false }
          )
        );
        await dispatch(getPopupSettings(project.id));

        const allowedMailDomainArray = (
          formik.values[stepsDefinition.stepGeneralInformation.key]?.allowed_mail_domains || ""
        )
          .split(/\r?\n/)
          .map((item) => item.trim());
        const trimmedAllowedMailDomains = allowedMailDomainArray.join("\n");
        formik.setFieldValue(
          `${stepsDefinition.stepGeneralInformation.key}.allowed_mail_domains`,
          trimmedAllowedMailDomains
        );
        await dispatch(
          createOrUpdateCompany(
            {
              id: company.id,
              name: company.name,
              // address: company.address,
              company_details: formik.values[stepsDefinition.stepGeneralInformation.key].company_details,
              allowed_mail_domains: allowedMailDomainArray,
            },
            { successMsg: false }
          )
        );

        if (formik.values[stepsDefinition.stepGeneralInformation.key].company_logo_file) {
          const form = new FormData();
          form.append("id", company.id);
          form.append("name", company.name);
          form.append("company_logo", formik.values[stepsDefinition.stepGeneralInformation.key].company_logo_file);
          await dispatch(createOrUpdateCompany(form));
          formik.setFieldValue(`${stepsDefinition.stepGeneralInformation.key}.company_logo_file`, null);
        }
        await dispatch(getProject(project?.id));
      } else if (currentStepItem.key === stepsDefinition.stepBotSettings.key && isForward) {
        const mainBotId = project.settings?.main_bot;
        LoadingHelper.open();
        try {
          await updateBotSettings(project?.id, mainBotId, {
            ...botSettings,
            name: formik.values[stepsDefinition.stepBotSettings.key].bot_name,
            awareness_prompt: formik.values[stepsDefinition.stepBotSettings.key].awareness_prompt,
          });
        } catch {
        } finally {
          LoadingHelper.close();
        }
      } else if (currentStepItem.key === stepsDefinition.stepLivechat.key && isForward) {
        if (!isSkip) {
          dispatch(
            updateCallcenterSettings(
              project.id,
              {
                ...callcenterSettings,
                agent_can_leave_session: formik.values[stepsDefinition.stepLivechat.key].agent_can_leave_session,
                working_hours: {
                  start: formik.values[stepsDefinition.stepLivechat.key].work_start_time.format("HH:mm"),
                  end: formik.values[stepsDefinition.stepLivechat.key].work_end_time.format("HH:mm"),
                },
              },
              { successMsg: false }
            )
          );
          for (let i = 0; i < formik.values[stepsDefinition.stepLivechat.key].agentList.length; i++) {
            const item = formik.values[stepsDefinition.stepLivechat.key].agentList[i];
            let payload = { email: item, roles: [userRole.callCenterAgent] };

            if (!invitedUserList.includes(item)) {
              await postInviteUser(project.id, payload);
            }
          }
        }
      } else if (currentStepItem.key === stepsDefinition.stepChatbot.key) {
        setPreviewSettings(
          {
            ...formik.values.welcomeForm,
          },
          {
            botName: formik.values[stepsDefinition.stepBotSettings.key].bot_name,
          }
        );

        if (isForward) {
          const rawFileKeys = Object.keys(formik.values.welcomeForm).filter((key) => key.endsWith("___file"));
          const rawFileObj = rawFileKeys.reduce((acc, key) => {
            const keyWithoutFile = key.replace("___file", "");
            acc[keyWithoutFile] = [formik.values.welcomeForm[key]];
            return acc;
          }, {});

          await dispatch(
            patchPopupSettings(
              project.id,
              project?.settings?.id,
              formik.values.welcomeForm,
              FileHelper.getFormDataByFileObj(rawFileObj),
              { successMsg: false }
            )
          );

          // await dispatch(
          //   updateProjectSettings(
          //     project?.settings?.id,
          //     {
          //       ...formik.values.welcomeForm,
          //       chatbot_languages: formik.values[stepsDefinition.stepBasic.key].assistant_languages
          //         ? [formik.values[stepsDefinition.stepBasic.key].assistant_languages]
          //         : formik.values.welcomeForm?.chatbot_languages,
          //       supported_languages:
          //         formik.values[stepsDefinition.stepGeneralInformation.key].assistant_supported_languages ||
          //         formik.values.welcomeForm?.supported_languages,
          //     },
          //     {
          //       successMsg: false,
          //       loading: false,
          //     }
          //   )
          // );

          const mainBotId = project.settings.main_bot;
          let responseRagState = await dispatch(getRagState(project.id, mainBotId, {}));
          if (!responseRagState?.id) {
            await dispatch(
              updateRagState(
                project.id,
                mainBotId,
                {
                  source_webpages: [],
                  source_files: [],
                  text_content: "",
                },
                { successMsg: false }
              )
            );
            responseRagState = await dispatch(getRagState(project.id, mainBotId, {}));
          }
          const sourceList = [];
          sourceList.push(
            ...responseRagState.source_webpages.map((item) => ({ type: onboardingRagType.webpage, payload: item }))
          );
          sourceList.push(
            ...responseRagState.source_files.map((item) => ({ type: onboardingRagType.file, payload: item }))
          );

          if (responseRagState.text_content) {
            sourceList.push(
              ...responseRagState.text_content.split(OnboardingContentSeparator).map((item, index) => ({
                type: onboardingRagType.content,
                payload: {
                  id: index + 1,
                  text: item,
                },
              }))
            );
          }

          const responseRagIntent = await getIntentDetails(project.id, mainBotId, responseRagState.id);
          formik.setFieldValue(`${stepsDefinition.stepRag.key}.sourceList`, sourceList);
          formik.setFieldValue(`${stepsDefinition.stepRag.key}.ragIntentId`, responseRagState.id);
          formik.setFieldValue(`${stepsDefinition.stepRag.key}.ragFactGroup`, responseRagIntent.rag_fact_group);
          formik.setFieldValue(`${stepsDefinition.stepRag.key}.ragFactGroupId`, responseRagIntent.rag_fact_group?.id);
        }
      } else if (currentStepItem.key === stepsDefinition.stepRag.key && isForward) {
        await saveRagState();
      } else if (currentStepItem.key === stepsDefinition.stepRag.key && !isForward) {
      }

      if (preventContinue) return;

      //Finish step
      if (!nextStepItem && (isForward || isSkip)) {
        const isInOnboarding = project?.status === onboardingProjectStatus.ONB;
        if (isInOnboarding) {
          await dispatch(updateOnboardingStatus(project.id, { action: onboardingStatus.complete }));
        }
        // HistoryHelper.push("/dashboard");
        window.location.href = "/dashboard";
        if (isInOnboarding) {
          AlertHelper.showSuccess("Onboarding completed successfully");
        }
        return;
      }
      setCurrentStepKey(isForward || isSkip ? nextStepItem.key : previousStepItem.key);
      setDirection(directionValue);
    };

  const variants = {
    enter: (direction) => ({
      x: direction === 1 ? "100%" : "-100%",
      opacity: 0,
      position: "absolute",
      width: "100%",
    }),
    center: {
      x: 0,
      opacity: 1,
      position: "absolute",
      width: "100%",
    },
    exit: (direction) => ({
      x: direction === 1 ? "-100%" : "100%",
      opacity: 0,
      position: "absolute",
      width: "100%",
    }),
  };

  const isPopupPreviewVisible = [stepsDefinition.stepChatbot.key, stepsDefinition.stepRag.key].includes(currentStepKey);

  const showPrevious = !!previousStepItem;
  const showNext = !!nextStepItem;
  const rightSideWidth = isPopupPreviewVisible ? panelRightWidthLarge : panelRightWidthSmall;

  useEffect(() => {
    if (!isPreviewBotReady || hasIframeError) return;
    setPreviewSettings(
      {
        ...formik.values.welcomeForm,
      },
      {
        botName: formik.values[stepsDefinition.stepBotSettings.key].bot_name,
      }
    );
  }, [formik.values.welcomeForm, isPreviewBotReady, hasIframeError]);

  const handleIFrameLoad = async (e) => {
    const targetIFrame = e.target;
    const targetWindow = targetIFrame.contentWindow;
    targetWindow.addEventListener("palmate-chatbot", (event) => {
      //replay event
      const type = event?.detail?.type;
      if (type === "init") {
        const targetDocument = targetWindow.document;
        const iframe = targetDocument.getElementById("_palamar_chat_iframe");

        iframe.contentWindow.dashboard ??= {};
        iframe.contentWindow.dashboard.reloadPreviewSettings = () => {
          setIsPreviewBotReady(true);
        };
      }
    });
  };

  const handleIFrameError = (e) => {
    setHasIframeError(true);
  };

  const handleClickToggleDrawer = () => {
    setDrawerVisibility((prev) => !prev);
  };

  const handleExitOnboarding = async () => {
    if (!(await DialogHelper.showQuestionYesNo("Are you sure you want to exit the onboarding process?"))) {
      return;
    }

    HistoryHelper.push("/dashboard");

    // dispatch(stopRag(project.id, botList?.[0]?.id));
  };
  const iframeContent = (
    <Box alignItems="center" display="flex" height={1} justifyContent="center" width={1}>
      <Box
        defer
        component={"iframe"}
        id={IFRAME_PREVIEW_ID}
        src={import.meta.env.VITE_APP_PUBLIC_URL + "/dashboard/setup/bot-preview"}
        sx={{
          borderRadius: "24px",
          // borderRadius: "8px",
          // height: 650,
          width: 450,
          border: isMdDown ? "none" : "2px dashed #39393933",
          height: isMdDown ? "calc(100% - 120px)" : "calc(100% - 200px)",
          marginBottom: isMdDown ? "-40px" : "135px",
        }}
        title="Popup Preview"
        zIndex={1}
        onError={handleIFrameError}
        onLoad={handleIFrameLoad}
      />
    </Box>
  );

  return (
    <StyledDiv>
      <HeaderBarGrid container alignItems={"center"}>
        <Grid item xs>
          <LogoWrapper>
            <PalLink noPrefix to="/">
              <Logo />
            </PalLink>
          </LogoWrapper>
        </Grid>
        <Grid item xs="auto">
          <PalIconButton size="large" onClick={handleExitOnboarding}>
            <Cancel
              sx={{
                width: 32,
                height: 32,
              }}
            />
          </PalIconButton>
        </Grid>
      </HeaderBarGrid>
      <Grid container height={`calc(100% - ${headerHeight}px)`}>
        <Grid item xs>
          <Grid container flexDirection={"column"} height={1}>
            <Grid
              item
              xs
              sx={{
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <LinearProgress
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 10,
                }}
                value={(currentStepIndex / (stepsList.length - 1)) * 100}
                variant="determinate"
              />
              <Box
                alignItems={"center"}
                display={"flex"}
                height={stepbarHeight}
                justifyContent={"space-between"}
                sx={{
                  position: "sticky",
                  top: "4px",
                  backgroundColor: "#fff",
                  zIndex: 10,
                }}
              >
                <Box mx={3} my={2}>
                  <PalTypography gutterBottom color="textSecondary" fontWeight={600} variant="subtitle2">
                    Step {currentStepIndex + 1}/{stepsList.length}
                  </PalTypography>
                </Box>
                {isMdDown && currentStepKey === stepsDefinition.stepChatbot.key && (
                  <Box>
                    <PalIconButton size="large" onClick={handleClickToggleDrawer}>
                      <Menu />
                    </PalIconButton>
                  </Box>
                )}
              </Box>
              <PanelLeft>
                <AnimatePresence custom={direction} initial={false}>
                  <motion.div
                    key={currentStepKey}
                    animate="center"
                    custom={direction}
                    exit="exit"
                    initial="enter"
                    style={{
                      maxHeight: "100%",
                    }}
                    transition={{ duration: 0.3 }}
                    variants={variants}
                  >
                    {stepsList.map((step, index) => {
                      const StepComponent = step.component;
                      const handleSetFieldValue = (key, value) => {
                        formik.setFieldValue(`${step.key}.${key}`, value);
                      };

                      const handleNextAction = async (...args) => {
                        return handleClickNextOrPrevious(true)(...args);
                      };
                      return (
                        <Fragment key={step.key}>
                          {currentStepKey === step.key && (
                            <StepComponent
                              formik={formik}
                              nextAction={handleNextAction}
                              setFieldValue={handleSetFieldValue}
                              stepKey={step.key}
                            />
                          )}
                        </Fragment>
                      );
                    })}
                    <Box height={"200px"} visibility={"hidden"} />
                  </motion.div>
                </AnimatePresence>
              </PanelLeft>
            </Grid>

            <Grid item xs="auto">
              <StepFooterContainerGrid container alignItems={"center"}>
                <Grid item xs>
                  {showPrevious && (
                    <NavigateButton
                      color="primary"
                      size="medium"
                      startIcon={<NavigateBefore />}
                      variant="text"
                      onClick={handleClickNextOrPrevious(false)}
                    >
                      Previous
                    </NavigateButton>
                  )}
                </Grid>
                <Grid item xs="auto">
                  {currentStepItem.isOptional && (
                    <>
                      <Box component={"span"} mr={2}>
                        <NavigateButton
                          color="secondary"
                          size="medium"
                          variant="text"
                          onClick={handleClickNextOrPrevious(null)}
                        >
                          I'll do this later
                        </NavigateButton>
                      </Box>
                    </>
                  )}
                  <NavigateButton
                    color="primary"
                    endIcon={<NavigateNext />}
                    size="medium"
                    variant="contained"
                    onClick={handleClickNextOrPrevious(true)}
                  >
                    {showNext ? "Next" : "Finish"}
                  </NavigateButton>
                </Grid>
              </StepFooterContainerGrid>
            </Grid>
          </Grid>
        </Grid>
        {!isMdDown && (
          <Grid item xs="auto">
            <motion.div
              animate={{ width: rightSideWidth }}
              style={{
                height: "100%",
              }}
              transition={{
                duration: 0.85,
                type: "spring",
                // ease: "easeInOut",
              }}
            >
              <PanelRight>
                <motion.div
                  animate={{
                    opacity: isPopupPreviewVisible ? 0 : 1,
                    // scale: isPopupPreviewVisible ? 0.5 : 1,
                  }}
                  initial={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 0.25,
                    ease: "easeInOut",
                  }}
                >
                  <RightImgWrapper>
                    <Img1 />
                  </RightImgWrapper>
                </motion.div>

                <motion.div
                  animate={{
                    opacity: isPopupPreviewVisible ? 1 : 0,
                    scale: isPopupPreviewVisible ? 1 : 0.5,
                  }}
                  initial={{
                    height: "100%",
                    opacity: 0,
                    scale: 0.5,
                  }}
                  transition={{
                    duration: 0.25,
                    ease: "easeInOut",
                  }}
                >
                  {iframeContent}
                </motion.div>
                <RightStepInfo>
                  <PalTypography color="textSecondary" variant="body2">
                    <PalTypography component="span" fontWeight={"bold"} variant="body2">
                      Introduce Yourself&nbsp;
                    </PalTypography>
                    involves providing information about yourself and your business. Please ensure all details are
                    accurate.
                  </PalTypography>
                </RightStepInfo>
              </PanelRight>
            </motion.div>
          </Grid>
        )}

        {currentStepKey === stepsDefinition.stepChatbot.key && (
          <Drawer keepMounted anchor="right" open={drawerVisibility} onClose={handleClickToggleDrawer}>
            <PanelRight fullHeight>
              <Box height={1} padding={2} position="relative">
                <Box position="absolute" right={1} top={1}>
                  <PalIconButton size="large" onClick={handleClickToggleDrawer}>
                    <Cancel fontSize="large" />
                  </PalIconButton>
                </Box>
                {iframeContent}
              </Box>
            </PanelRight>
          </Drawer>
        )}
      </Grid>
    </StyledDiv>
  );
}

export default Onboarding;

// #endregion onboarding
