import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { isEqual, cloneDeep, throttle } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { List } from "react-virtualized";
import { Col, FormGroup, Label, Row } from "reactstrap";

import { Box, Grid } from "@mui/material";

import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import { chatbotSessionStatus, livechatCallcenterType } from "~constants";
import {
  selectAgentInfo,
  selectCallcenterSettings,
  selectLivechatLoadingStatus,
} from "~store/socket/livechat/selectors";

import SessionListItem from "./SessionListItem";

import "./style.scss";

export default function SessionList({
  sessions,
  selectedSession: selectedSessionProp,
  onClick,
  hideHighlight,
  footerComponent,
  reverseOrder,
  visibleStatusList,
  nonVisibleStatusList,
  showAssignedToMeOption,
}) {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [selectedSession, setSelectedSession] = useState(selectedSessionProp);
  const [showOnlyAssignedToMe, setShowOnlyAssignedToMe] = useState(false);

  const loading = useSelector(selectLivechatLoadingStatus);
  const agentInfo = useSelector(selectAgentInfo);
  const callcenterSettings = useSelector(selectCallcenterSettings);

  useEffect(() => {
    if (containerRef.current) {
      const updateSize = async () => {
        setContainerHeight(containerRef.current.clientHeight);
        setContainerWidth(containerRef.current.clientWidth);
      };

      updateSize();

      const debounceUpdateSize = throttle(updateSize, 10);

      const observer = new ResizeObserver(debounceUpdateSize);
      observer.observe(containerRef.current);

      return () => observer.disconnect();
    }
  }, []);

  useEffect(() => {
    if (!isEqual(selectedSession, selectedSessionProp)) {
      setSelectedSession(selectedSessionProp);
    }
  }, [selectedSessionProp, selectedSession]);

  const visibleSessions = useMemo(() => {
    let filteredSessions = cloneDeep(sessions);

    //TODO. Investigate why there are duplicates if there are any
    const duplicatedSessions = [];
    //remove possible duplicates
    filteredSessions = filteredSessions.filter((session, index, self) => {
      if (self.findIndex((s) => s.id === session.id) === index) {
        return true;
      } else {
        duplicatedSessions.push(session);
        return false;
      }
    });
    if (duplicatedSessions.length) {
      console.warn("[LIVECHAT] Duplicated sessions found and removed", duplicatedSessions);
    }

    if (Array.isArray(visibleStatusList)) {
      filteredSessions = filteredSessions.filter((session) => visibleStatusList.includes(session.session_status));
    }
    if (Array.isArray(nonVisibleStatusList)) {
      filteredSessions = filteredSessions.filter((session) => !nonVisibleStatusList.includes(session.session_status));
    }

    if (callcenterSettings?.callcenter_type === livechatCallcenterType.FIFO) {
      return filteredSessions;
    }
    let currentActiveChattingSessions = [];
    let otherActiveChattingSessions = [];
    for (const session of filteredSessions) {
      if (session.session_status === chatbotSessionStatus.AGENT_CHAT && session?.agent?.id === agentInfo?.id) {
        currentActiveChattingSessions.push(session);
      } else {
        otherActiveChattingSessions.push(session);
      }
    }

    if (reverseOrder) {
      otherActiveChattingSessions = otherActiveChattingSessions.reverse();
    }

    let mergedSessions = [...currentActiveChattingSessions];

    if (!showOnlyAssignedToMe) {
      mergedSessions.push(...otherActiveChattingSessions);
    }
    return mergedSessions;
  }, [
    callcenterSettings?.callcenter_type,
    showOnlyAssignedToMe,
    sessions,
    reverseOrder,
    visibleStatusList,
    nonVisibleStatusList,
    agentInfo?.id,
  ]);

  const handleCheckedShowAssignedToMe = (e) => {
    setShowOnlyAssignedToMe(e.target.checked);
  };

  const SessionListVirtualizedItemRenderer = useCallback(
    ({ index, style }) => {
      const session = visibleSessions[index];
      if (!session) return null;
      return (
        <Box
          key={session.id}
          style={style}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <SessionListItem
            isCurrentAgentChatting={
              session?.agent?.id === agentInfo?.id && session.session_status === chatbotSessionStatus.AGENT_CHAT
            }
            isSelected={selectedSession?.id === session.id}
            session={session}
            onClick={onClick}
          />
        </Box>
      );
    },
    [selectedSession, visibleSessions, agentInfo, onClick]
  );

  return (
    <div ref={containerRef} className="pr-livechat-sessionlist">
      <Row className="scrollbar-container g-0 flex-nowrap flex-column overflow-hidden">
        <div className="col scrollbar-item flex-shrink-1 d-flex flex-column">
          {showAssignedToMeOption && !!sessions?.length && (
            <div className="mt-1 w-100 d-flex align-items-center justify-content-end">
              <FormGroup switch className="d-flex align-items-center me-3">
                <PRInput checked={showOnlyAssignedToMe} type="switch" onChange={handleCheckedShowAssignedToMe} />
                <Label className="p-0" size="md">
                  {t("component.sessionList.showAssignedToMe")}
                </Label>
              </FormGroup>
            </div>
          )}
          <div className="session-group" style={{ flex: "1 0 0%", overflowY: "hidden" }}>
            <PRContainer bare loading={loading} style={{ minHeight: 80 }}>
              <Grid container gap={"10px"} marginBottom={"15px"}>
                {!!visibleSessions.length && (
                  <Grid item xs={12}>
                    <List
                      height={containerHeight - 15}
                      overscanRowCount={20}
                      rowCount={visibleSessions.length}
                      rowHeight={80}
                      rowRenderer={SessionListVirtualizedItemRenderer}
                      width={containerWidth - 2}
                    />
                  </Grid>
                )}
                {!visibleSessions.length && (
                  <Grid item mt={2} xs={12}>
                    <div className="text-center text-muted">
                      {showAssignedToMeOption && showOnlyAssignedToMe
                        ? t("component.sessionList.noActiveSessionAssignedToMe")
                        : t("component.sessionList.noActiveSession")}
                    </div>
                  </Grid>
                )}
              </Grid>
            </PRContainer>
          </div>
        </div>
        <Col xs="auto">{typeof footerComponent === "function" ? footerComponent() : footerComponent}</Col>
      </Row>
    </div>
  );
}
