import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

/**
 * @typedef {object} CallcenterAgent
 * @property {number} id
 * @property {string} name
 * @property {string} status
 * @property {any} categories
 * @property {number} max_active_chat_count
 * @property {number} active_session_count
 * @property {any} active_sessions
 * @property {string[]} prefered_languages
 */

export const initState = {
  /** @type {CallcenterAgent} */
  callcenterAgent: null,
};

const helpdesk = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_CALLCENTER_AGENT:
        draft.callcenterAgent = action.payload;
        break;
    }
  });

export default helpdesk;
