import { useRef, useState } from "react";

import { PalIconButton, PalTooltip } from "@palamar/fe-library";
import { MdClose, MdDescription } from "react-icons/md";

import { Box, CircularProgress } from "@mui/material";

import ImagePreviewModal from "~common/modals/ImagePreviewModal";

import { usePRRefContext } from "./context";

const ChatFilePreviewItem = ({ wide, file, onDelete }) => {
  const distributedRef = usePRRefContext();
  const imgRef = useRef(null);
  const [isImageFailed, setIsImageFailed] = useState(false);

  const handleImageError = () => {
    setIsImageFailed(true);
  };
  const handleClickImagePreview = async () => {
    await ImagePreviewModal.show({
      title: file.file_name,
      image: file.file_url || file.preview,
    });

    setTimeout(() => {
      distributedRef.current.disableScrollOnMessage = false;
    }, 100);
  };

  return (
    <Box
      sx={{
        borderRadius: "10px",
        border: "1px solid #e0e0e0",
        position: "relative",
        backgroundColor: "#00000030",
        overflow: "hidden",
        marginTop: "10px",
        ...(wide
          ? {
              width: "96px",
              height: "96px",
              svg: {
                width: 96,
                height: 96,
              },
            }
          : {
              width: "42px",
              height: "42px",
            }),
      }}
    >
      <PalTooltip placement="top" title={file.file_name}>
        {!isImageFailed ? (
          <Box
            ref={imgRef}
            component="img"
            src={file.file_url || file.preview}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              cursor: "pointer",
            }}
            onClick={handleClickImagePreview}
            onError={handleImageError}
          />
        ) : (
          <Box
            component={"a"}
            download={file.file_name}
            href={file.file_url || file.preview}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              svg: {
                width: "80%",
                height: "auto",
              },
              color: "inherit",
            }}
          >
            <MdDescription />
          </Box>
        )}
      </PalTooltip>
      {file.loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            color: "#fff",
          }}
        >
          <CircularProgress size={18} />
        </Box>
      )}
      {onDelete && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <PalIconButton
            disabled={file.loading}
            sx={{
              backgroundColor: "var(--bs-gray-500)",
              width: "16px",
              height: "16px",
              padding: "2px",
              "&:hover": {
                backgroundColor: "var(--bs-gray-600)",
              },
              cursor: "pointer",
            }}
            onClick={onDelete}
          >
            <MdClose style={{ color: "#fff" }} />
          </PalIconButton>
        </Box>
      )}
    </Box>
  );
};

export default ChatFilePreviewItem;
