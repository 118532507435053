import { useEffect, useMemo, useState } from "react";

import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "reactstrap";

import useLoading from "~common/hooks/useLoading";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import { chatbotPreferredLanguageOptions } from "~constants";
import { getCallcenterAgent, updateCallcenterAgent } from "~store/agents/actions";
import { selectCallcenterAgent } from "~store/agents/selectors";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentProject } from "~store/user/selectors";

function UpdateAgentPreferredLanguagesModalContent({ get, id }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedLanguages, setSelectedLanguages] = useState(null);
  const [loading, q] = useLoading();
  const currentProject = useSelector(selectCurrentProject);
  const popupSettings = useSelector(selectPopupSettingsState);

  const callcenterAgent = useSelector(selectCallcenterAgent);

  useEffect(() => {
    q(dispatch(getCallcenterAgent(currentProject.id, id)));
  }, [dispatch, id, currentProject.id, q]);
  useEffect(() => {
    if (!callcenterAgent) return;
    setSelectedLanguages((callcenterAgent.prefered_languages || []).map((lang) => lang.toUpperCase()));
  }, [callcenterAgent]);

  const handleUpdate = async () => {
    await q(
      dispatch(
        updateCallcenterAgent(currentProject.id, {
          ...callcenterAgent,
          prefered_languages: selectedLanguages.map((lang) => lang.toLowerCase()),
        })
      )
    );
    get(true)();
  };

  const visibleLanguageOptions = useMemo(() => {
    if (!popupSettings?.supported_languages?.length) return chatbotPreferredLanguageOptions;
    const newOptions = chatbotPreferredLanguageOptions.filter((lang) =>
      popupSettings.supported_languages.find(
        (supportedLang) => (supportedLang?.language?.code || "")?.toLowerCase() === lang.value?.toLowerCase()
      )
    );

    //add if previously selected language is not in the supported languages
    if (selectedLanguages?.length) {
      selectedLanguages.forEach((lang) => {
        if (!newOptions.find((option) => option.value === lang)) {
          newOptions.push({ value: lang, label: lang });
        }
      });
    }
    return newOptions;
  }, [popupSettings, selectedLanguages]);
  return (
    <PRModal
      loading={loading}
      submitText={t("common.update")}
      title={t("component.UpdateAgentPreferredLanguages.title")}
      onClick={handleUpdate}
      onClose={get(false)}
    >
      <Label htmlFor="slot-validation-text">{t("component.UpdateAgentPreferredLanguages.preferredLanguages")}:</Label>
      <PRSelect
        isMulti
        isPrimitiveValue
        options={visibleLanguageOptions}
        value={selectedLanguages}
        onChange={setSelectedLanguages}
      />
    </PRModal>
  );
}
const UpdateAgentPreferredLanguagesModal = withCardon(UpdateAgentPreferredLanguagesModalContent, {
  destroyOnHide: true,
});
export default UpdateAgentPreferredLanguagesModal;
