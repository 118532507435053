import { useEffect, useMemo } from "react";

import { PalPage, PalTypography, PalButton, PalContainer, PalTextField, PalSwitch } from "@palamar/fe-library";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import styled from "@emotion/styled";
import { Grid } from "@mui/material";

import useProjectChange from "~common/hooks/useProjectChange";
import PRProfile from "~components/Generic/PRProfile";
import AlertHelper from "~helpers/AlertHelper";
import FileHelper from "~helpers/FileHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import { createOrUpdateCompany, getCompany, setCompany } from "~store/settings/company/actions";
import { selectCompany } from "~store/settings/company/selectors";
import { selectCurrentProject, selectUserInfo } from "~store/user/selectors";

const TitleGridItem = styled(Grid)`
  flex-direction: column;
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const ButtonContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const CompaniesEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const company = useSelector(selectCompany);
  const currentProject = useSelector(selectCurrentProject);
  const userInfo = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const handleClickCancel = () => {
    HistoryHelper.goBack("/settings/companies/", { scope: "dashboard" });
  };

  useEffect(() => {
    if (!id) return;
    dispatch(getCompany(id));
    return () => {
      dispatch(setCompany({}));
    };
    // Page should be redirected to list if selected project is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...company,
      name: company.name || "",
      company_logo: company.company_logo,
      company_logo_file: null,
      address: company.address || "",
      country: company.country || "",
      allowed_mail_domains: company.allowed_mail_domains?.join("\n") || "",
      active: company.active || false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t("component.formik.required").format(t("common.title")))
        .min(3)
        .max(50),
    }),
    onSubmit: async (values) => {
      if (values.company_logo_file) {
        const form = new FormData();
        form.append("id", company.id);
        form.append("name", company.name);
        form.append("company_logo", values.company_logo_file);
        await dispatch(createOrUpdateCompany(form));
        formik.setFieldValue(`company_logo_file`, null);
      }

      const valuesExceptImage = { ...values };
      delete valuesExceptImage.company_logo_file;
      delete valuesExceptImage.company_logo;
      await dispatch(
        createOrUpdateCompany({
          ...valuesExceptImage,
          allowed_mail_domains: valuesExceptImage.allowed_mail_domains
            ?.split("\n")
            .map((item) => item.trim())
            .filter((item) => item !== ""),
        })
      );

      handleClickCancel();
    },
  });

  useProjectChange(() => {
    HistoryHelper.push("/settings/companies/", { scope: "dashboard" });
  }, []);

  const parentName = useMemo(
    () => [
      {
        label: t("dashboard.companies"),
        url: "/settings/companies",
      },
      {
        label: id
          ? t("dashboard.companiesEdit.editMessage")
          : //
            t("dashboard.companiesEdit.createMessage"),
      },
    ],
    [id, t]
  );

  const handleHeaderFileChange = async (file, e) => {
    const img = await FileHelper.getImage(file);
    const width = img.width;
    const height = img.height;
    if (width !== height || width < 144) {
      AlertHelper.showError(t("dashboard.onboarding.logoSizeError"));
      e.preventDefault();
      return;
    }

    // setFiles({ ...files, popup_header_icon_path: [file] });
    formik.setFieldValue("company_logo_file", file);
    const blobUri = URL.createObjectURL(file);
    // dispatch(setPopupSettingsState("popup_header_icon_path", blobUri));
    formik.setFieldValue("company_logo", blobUri);
  };

  const handleMessageTypeChange = (e, value) => {
    formik.setFieldValue("message_type", value);
  };

  const handleChangeActive = (e) => {
    // setFieldValue(key, !e.target.checked);
    formik.setFieldValue("active", e.target.checked);
  };

  return (
    <PalContainer
      // description={t("dashboard.companyEdit.description")}
      name={t("common.settings")}
      parentName={parentName}
    >
      <PalPage>
        <Grid container spacing={2}>
          <TitleGridItem item xs={12}>
            <PalTypography>{t("common.name")}</PalTypography>
            <PalTextField
              error={formik.touched.name && formik.errors.name}
              name="name"
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </TitleGridItem>
          <TitleGridItem item xs={12}>
            <PalTypography>{t("dashboard.projectSettings.logo")}</PalTypography>
            <PRProfile img={formik.values.company_logo} onFileChange={handleHeaderFileChange} />
          </TitleGridItem>
          <TitleGridItem item xs={12}>
            <PalTypography>{t("common.address")}</PalTypography>
            <PalTextField
              error={formik.touched.address && formik.errors.address}
              name="address"
              value={formik.values.address}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </TitleGridItem>
          <TitleGridItem item xs={12}>
            <PalTypography>{t("dashboard.companiesList.allowedMailDomains")}</PalTypography>
            <PalTextField
              multiline
              error={formik.touched.allowed_mail_domains && formik.errors.allowed_mail_domains}
              maxRows={5}
              minRows={3}
              name="allowed_mail_domains"
              value={formik.values.allowed_mail_domains}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </TitleGridItem>
          <TitleGridItem item xs={12}>
            <PalTypography>{t("common.active")}</PalTypography>
            <PalSwitch checked={formik.values.active} onChange={handleChangeActive} />
          </TitleGridItem>
        </Grid>

        <ButtonContainer container>
          <Grid container item spacing={1} xs="auto">
            <Grid item xs={6}>
              <PalButton variant="outlined" onClick={handleClickCancel}>
                {t("common.cancel")}
              </PalButton>
            </Grid>
            <Grid item xs={6}>
              <PalButton variant="contained" onClick={formik.handleSubmit}>
                {id ? t("common.update") : t("common.create")}
              </PalButton>
            </Grid>
          </Grid>
        </ButtonContainer>
      </PalPage>
    </PalContainer>
  );
};

export default CompaniesEdit;
