import { apiUrlAgentLeaderboard, apiUrlAgentList } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setCallcenterAgent = (payload) => ({
  type: at.SET_CALLCENTER_AGENT,
  payload,
});

// ----------------- API -----------------

export const getCallcenterAgent = (projectId, id) => () => {
  const url = `${apiUrlAgentList.get.format(projectId, id)}`;

  return Network.request(url, {
    loading: true,
    onSuccess: setCallcenterAgent,
  });
};

export const getListCallcenterAgent =
  (projectId, options = {}) =>
  () => {
    const url = `${apiUrlAgentList.list.format(projectId)}`;

    return Network.request(url, {
      loading: false,
      ...options,
    });
  };

export const updateCallcenterAgent = (projectId, data) => () => {
  const url = `${apiUrlAgentList.get.format(projectId, data?.id)}`;

  return Network.request(url, {
    method: "PUT",
    data,
    successMsg: true,
    loading: true,
  });
};

export const getAgentLeaderboard = (projectId, query) => () => {
  const url = `${apiUrlAgentLeaderboard.list.format(projectId)}`;

  return Network.request(url, {
    loading: false,
    params: query,
  });
};
