import chroma from "chroma-js";

import {
  popupChatHeightDefault,
  popupIconMarginDefault,
  popupIconSizeDefault,
  popupIconType,
  popupLottieOffsetXDefault,
  popupLottieOffsetYDefault,
  popupPosition,
  popupTriggerType,
} from "~constants";
import { safeProduce as produce } from "~helpers/immer";
import ThemeMapper from "~helpers/ThemeMapper";
import Utils from "~helpers/Utils";

import * as actionTypes from "./actionTypes";

export const initState = {
  settingState: {
    id: 0,
    welcome_form: {
      id: 0,
      /**
       * @type {{
       *   id: number;
       *   form_label: string;
       *   form_key: string;
       *   is_identifier: boolean;
       *   form_item_type: string;
       *   form_item_order: number;
       *   project: number;
       * }[]}
       */
      form_items: [],
      optional: false,
      project: 0,
    },
    popup_title: "",
    popup_welcome_text: "",
    popup_primary_color: "",
    /** @deprecated */
    popup_button_type: "", //DF, IC
    popup_button_color: "",
    /** @deprecated */
    popup_header_icon_type: "", //DF, IC

    popup_bot_icon_type: "", //DF, IC
    popup_bot_icon_path: "",
    popup_urls: {
      DEBUG: "",
      PUBLIC_WEB: "",
      TEST: "",
    },
    erp_filler_method: "", //None
    maintenance: false,
    info_email: "",
    info_email_name: "", //Foo Bar <example@test.com>
    info_email_type: "", //SES
    reply_to_email: "",
    mailer_domain: "",
    mailer_encryption_type: "", //TLS
    mailer_port: 0,
    email_status: "", //VER
    receiver_email: "",
    chatbot_languages: [], //"TR"
    feedback_enabled: false,
    show_queue_order: false,
    show_thumps_up: false,
    ticket_enabled: false,
    direct_to_ticket_enabled: false,
    topic_modelling_enabled: false,
    timeout_times: null,

    chatbot_info: {
      avatar_visibility: null,
      footer_mode: null,
      speech_recognition_mode: null,
      speech_recognition_auto_send: false,
      minimize_existing_Chat: "",
      minimize_behavior: "",
      disable_lazy_load: false,
    },
    system_chatbot_info: {
      enable_footer_customization: false,
      enable_css_customization: false,
    },

    /**
     * @deprecated Use chatbot_list instead
     * @type {{
     *   [key: string]: { id: number; name: string; status: string; smalltalk: { id: number; name: string } };
     * }}
     */
    chatbot_status: {},
    /**
     * @type {{
     *   id: number;
     *   name: string;
     *   status: string;
     *   smalltalk: { id: number; name: string };
     * }[]} ;
     */
    chatbot_list: [],
    welcome_msg: "",
    popup_style_info: {
      contrast_color: "#fffff",
      title_color: "#ffffff",
      primary_light: null,
      primary_dark: null,

      /** @type {keyof import("~constants").popupIconType} */
      popup_header_icon_type: popupIconType.default,
      /** @type {keyof import("~constants").popupIconType} */
      popup_bot_icon_type: popupIconType.default,
      /** @type {keyof import("~constants").popupIconType} */
      popup_icon_type: popupIconType.default,
      /** @type {keyof import("~constants").popupPositionMap} */
      popup_position: popupPosition.bottomRight,

      popup_icon_size: popupIconSizeDefault,

      /** @deprecated Use popup_margin_x and popup_margin_y instead and remove popup_margin on save */
      popup_margin: popupIconMarginDefault,
      popup_margin_x: popupIconMarginDefault,
      popup_margin_y: popupIconMarginDefault,
      chat_height: popupChatHeightDefault,
      lottie_width: popupIconSizeDefault,
      lottie_height: popupIconSizeDefault,
      lottie_offset_x: popupLottieOffsetXDefault,
      lottie_offset_y: popupLottieOffsetYDefault,
      lottie_loop_delay: 0,
      lottie_loop_speed: 1,
      popup_ballon_timeout: 0,
      popup_trigger_type: popupTriggerType.instant,
      popup_trigger_type_payload: 0,
    },

    /**
     * @type {{
     *   key: keyof typeof import("~constants").projectTranslateKey;
     *   platform: keyof typeof import("~constants").projectTimeoutTimes | "default";
     *   language: keyof typeof import("~constants").projectLanguage;
     *   value: string;
     *   format: keyof typeof import("~components/Generic/PRInput").PRTextAreaFormat;
     * }[]}
     */
    static_messages: null, //dynamic field
    project: 0,

    // Also comes from LLM
    genai_analysis_active: false,
  },
};

const popupSettings = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.SET_POPUP_SETTING_STATE:
        const { key, value } = action.payload;
        if (!key) {
          draft.settingState = value;
        } else {
          Utils.setBySelector(draft.settingState, key, value);
        }
        break;

      case actionTypes.RESET_POPUP_SETTING_STATE:
        draft.settingState = initState.settingState;
        break;
      case actionTypes.INIT_POPUP_SETTING_STATE:
        const primaryColor = "#28AAE1";

        const light = chroma(primaryColor).brighten(1.5).hex();
        const dark = chroma(primaryColor).darken(0.7).hex();
        const titleColor = Utils.determineTextColor(primaryColor);
        const contrastColor = Utils.determineTextColor(light);

        const main = primaryColor;
        const contrastText = contrastColor;
        const titleText = "#fff";

        const advancedStyle = ThemeMapper.initializeStyle({
          primary_color: main,
          header_color: titleText,
          header_background_color: main,
          chat_balloon_customer_text_color: contrastText,
          chat_balloon_customer_background_color: main,
          chat_balloon_customer_border: `1px solid ${light}`,
          header_icon_color: titleText,
        });
        //delete specific style bot and agent configuration. these options will be filled using chat_ballon_left_... variants.
        delete advancedStyle.chat_balloon_bot_text_color;
        delete advancedStyle.chat_balloon_bot_background_color;
        delete advancedStyle.chat_balloon_bot_border;

        delete advancedStyle.chat_balloon_agent_text_color;
        delete advancedStyle.chat_balloon_agent_background_color;
        delete advancedStyle.chat_balloon_agent_border;

        const newStyle = {
          ...state.popup_style_info,
          primary_light: light,
          primary_dark: dark,
          contrast_color: contrastColor,
          title_color: titleColor,
          popup_icon_size: popupIconSizeDefault,
          /** @deprecated Use popup_margin_x and popup_margin_y instead and remove popup_margin on save */
          popup_margin: popupIconMarginDefault,
          popup_margin_x: popupIconMarginDefault,
          popup_margin_y: popupIconMarginDefault,
          chat_height: popupChatHeightDefault,
          popup_position: popupPosition.bottomRight,
          lottie_width: popupIconSizeDefault,
          lottie_height: popupIconSizeDefault,
          lottie_offset_x: popupLottieOffsetXDefault,
          lottie_offset_y: popupLottieOffsetYDefault,
          lottie_loop_delay: 0,
          lottie_loop_speed: 1,
          popup_ballon_timeout: 0,
          popup_trigger_type: popupTriggerType.instant,
          popup_trigger_type_payload: 0,
        };
        const newState = {
          ...state,
          popup_primary_color: primaryColor,
          popup_style_info: newStyle,
          chatbot_info: {
            ...state.chatbot_info,
            style: advancedStyle,
          },
        };
        draft = newState;
        break;
    }
  });

export default popupSettings;
