import ReactPlayer from "react-player/lazy";

import SimpleMessage from "../SimpleMessage";

export default function VideoMessage({
  historyId,
  plainView,
  format,
  url,
  position,
  messageTime,
  isSent,
  messageId,
  likeStatus,
  isLast,
  isRag,
  textDetails,
}) {
  const Wrapper = plainView ? ({ children }) => children : SimpleMessage;

  return (
    <Wrapper
      format={format}
      historyId={historyId}
      isLast={isLast}
      isRag={isRag}
      isSent={isSent}
      likeStatus={likeStatus}
      messageId={messageId}
      messageTime={messageTime}
      position={position}
      textDetails={textDetails}
    >
      <ReactPlayer controls height="auto" url={url} width="300px" />
    </Wrapper>
  );
}
