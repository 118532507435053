import { useCallback, useEffect, useMemo, useState } from "react";

import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "reactstrap";

import { Grid } from "@mui/material";

import PRButton from "~components/Generic/PRButton";
import PRInput from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import AlertHelper from "~helpers/AlertHelper";
import { getAgentInfo, getOnlineAgents, wsTransferAgent } from "~store/socket/livechat/actions";
import { selectAgentInfo, selectOnlineAgents } from "~store/socket/livechat/selectors";

function RedirectAgentModalContent({ assignedAgentId, showAssignToMe, get, session }) {
  const dispatch = useDispatch();

  const onlineAgents = useSelector(selectOnlineAgents);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [note, setNote] = useState("");
  const agentInfo = useSelector(selectAgentInfo);

  useEffect(() => {
    dispatch(getAgentInfo());
    dispatch(getOnlineAgents(session.project));
  }, [dispatch, session.project]);

  const handleChangeNote = useCallback((e) => {
    setNote(e.target.value);
  }, []);

  const handleCreate = async () => {
    setLoading(true);
    try {
      await dispatch(wsTransferAgent(session.id, selectedAgent, note));
      AlertHelper.show(t("network.success"), "success");
    } catch (e) {
      AlertHelper.show(t("network.serverError"), "error");
      console.error(e);
    }
    setLoading(false);
    get(true)();
  };

  const onlineAgentsWithoutMe = useMemo(() => {
    if (!Array.isArray(onlineAgents)) return [];
    if (showAssignToMe)
      return onlineAgents.map((agent) => {
        if (agent.id === assignedAgentId) return { ...agent, disabled: true };
        return agent;
      });
    return onlineAgents.filter((agent) => agent.id !== agentInfo?.id);
  }, [assignedAgentId, showAssignToMe, agentInfo?.id, onlineAgents]);

  const handleClickAssignToMe = () => {
    setSelectedAgent(agentInfo.id);
  };
  return (
    <PRModal
      loading={loading}
      submitText={t("component.redirectAgentModal.sent")}
      title={t("component.redirectAgentModal.title")}
      onClick={handleCreate}
      onClose={get(false)}
    >
      <Label className="mt-2">{t("component.redirectAgentModal.targetAgent")}:</Label>
      <Grid container spacing={1}>
        <Grid item xs>
          <PRSelect
            isPrimitiveValue
            labelSelector="name"
            options={onlineAgentsWithoutMe}
            value={selectedAgent}
            valueSelector="id"
            onChange={setSelectedAgent}
          />
        </Grid>
        {showAssignToMe && (
          <Grid item xs="auto">
            <PRButton disabled={loading || assignedAgentId === agentInfo?.id} onClick={handleClickAssignToMe}>
              {t("component.redirectAgentModal.assignToMe")}
            </PRButton>
          </Grid>
        )}
      </Grid>

      <Label className="mt-2">{t("common.note")}:</Label>
      <PRInput value={note} onChange={handleChangeNote} />
    </PRModal>
  );
}
const RedirectAgentModalModal = withCardon(RedirectAgentModalContent, { destroyOnHide: true });
export default RedirectAgentModalModal;
