import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

/**
 * @typedef {object} SettingsObject
 * @property {number} id
 * @property {string} name
 * @property {number} company
 * @property {number} creator
 * @property {object} settings
 * @property {number} settings.id
 * @property {object} settings.welcome_form
 * @property {number} settings.welcome_form.id
 * @property {object[]} settings.welcome_form.form_items
 * @property {number} settings.welcome_form.form_items.id
 * @property {string} settings.welcome_form.form_items.form_label
 * @property {string} settings.welcome_form.form_items.form_key
 * @property {boolean} settings.welcome_form.form_items.is_identifier
 * @property {string} settings.welcome_form.form_items.form_item_type
 * @property {number} settings.welcome_form.form_items.form_item_order
 * @property {boolean} settings.welcome_form.form_items.is_optional
 * @property {number} settings.welcome_form.form_items.project
 * @property {boolean} settings.welcome_form.optional
 * @property {number} settings.welcome_form.project
 * @property {string} settings.popup_title
 * @property {string} settings.popup_welcome_text
 * @property {string} settings.popup_primary_color
 * @property {string} settings.popup_button_type
 * @property {string} settings.popup_button_color
 * @property {null} settings.popup_button_icon_path
 * @property {string} settings.popup_header_icon_type
 * @property {string} settings.footer_logo_type
 * @property {string} settings.popup_bot_icon_type
 * @property {null} settings.popup_header_icon_path
 * @property {null} settings.footer_logo_path
 * @property {null} settings.popup_bot_icon_path
 * @property {string} settings.erp_filler_method
 * @property {string} settings.email_status
 * @property {string[]} settings.chatbot_languages
 * @property {string} settings.welcome_msg
 * @property {object} settings.popup_style_info
 * @property {number} settings.popup_style_info.chat_height
 * @property {string} settings.popup_style_info.title_color
 * @property {number} settings.popup_style_info.lottie_width
 * @property {string} settings.popup_style_info.primary_dark
 * @property {number} settings.popup_style_info.lottie_height
 * @property {string} settings.popup_style_info.primary_light
 * @property {string} settings.popup_style_info.contrast_color
 * @property {number} settings.popup_style_info.popup_margin_x
 * @property {number} settings.popup_style_info.popup_margin_y
 * @property {string} settings.popup_style_info.popup_position
 * @property {number} settings.popup_style_info.lottie_offset_x
 * @property {number} settings.popup_style_info.lottie_offset_y
 * @property {number} settings.popup_style_info.popup_icon_size
 * @property {string} settings.popup_style_info.popup_icon_type
 * @property {number} settings.popup_style_info.lottie_loop_delay
 * @property {number} settings.popup_style_info.lottie_loop_speed
 * @property {string} settings.popup_style_info.popup_trigger_type
 * @property {string} settings.popup_style_info.popup_bot_icon_type
 * @property {number} settings.popup_style_info.popup_ballon_timeout
 * @property {string} settings.popup_style_info.popup_header_icon_type
 * @property {number} settings.popup_style_info.popup_trigger_type_payload
 * @property {object} settings.system_chatbot_info
 * @property {boolean} settings.system_chatbot_info.enable_css_customization
 * @property {boolean} settings.system_chatbot_info.enable_footer_customization
 * @property {object} settings.chatbot_info
 * @property {string} settings.chatbot_info.footer_mode
 * @property {string} settings.chatbot_info.avatar_visibility
 * @property {boolean} settings.chatbot_info.disable_lazy_load
 * @property {string} settings.chatbot_info.minimize_behavior
 * @property {string} settings.chatbot_info.minimize_existing_Chat
 * @property {string} settings.chatbot_info.speech_recognition_mode
 * @property {boolean} settings.chatbot_info.speech_recognition_auto_send
 * @property {string} settings.receiver_email
 * @property {object} settings.popup_urls
 * @property {string} settings.popup_urls.PUBLIC_WEB
 * @property {string} settings.popup_urls.DEBUG
 * @property {string} settings.popup_urls.TEST
 * @property settings.baloon_text
 * @property {boolean} settings.maintenance
 * @property {object[]} settings.chatbot_list
 * @property {number} settings.chatbot_list.id
 * @property {string} settings.chatbot_list.name
 * @property {string} settings.chatbot_list.language
 * @property {boolean} settings.chatbot_list.enabled
 * @property {boolean} settings.chatbot_list.initialized
 * @property {boolean} settings.chatbot_list.smalltalk_enabled
 * @property {object} settings.chatbot_list.smalltalk
 * @property {number} settings.chatbot_list.smalltalk.id
 * @property {string} settings.chatbot_list.smalltalk.name
 * @property {string} settings.chatbot_list.prediction_type
 * @property {object[]} settings.static_messages
 * @property {string} settings.static_messages.key
 * @property {string} settings.static_messages.value
 * @property {string} settings.static_messages.format
 * @property {string} settings.static_messages.language
 * @property {string} settings.static_messages.platform
 * @property {boolean} settings.ticket_enabled
 * @property {boolean} settings.feedback_enabled
 * @property {boolean} settings.direct_to_ticket_enabled
 * @property {boolean} settings.topic_modelling_enabled
 * @property {string} settings.info_email
 * @property {string} settings.info_email_type
 * @property {string} settings.info_email_name
 * @property {string} settings.reply_to_email
 * @property {string} settings.mailer_domain
 * @property {string} settings.mailer_port
 * @property {string} settings.mailer_encryption_type
 * @property {string} settings.mailer_username
 * @property {boolean} settings.show_thumps_up
 * @property {boolean} settings.show_queue_order
 * @property {boolean} settings.chat_label_enabled
 * @property {number} settings.main_bot
 * @property {object[]} supported_languages
 * @property {number} supported_languages.id
 * @property {object} supported_languages.language
 * @property {number} supported_languages.language.id
 * @property {string} supported_languages.language.name
 * @property {string} supported_languages.language.code
 * @property {number} supported_languages.project
 * @property {number} settings.project
 * @property {null} status
 * @property {number} from_template
 * @property {string} main_language
 */

export const initState = {
  /** @type {SettingsObject} */
  project: {},
  projectTemplates: [],
  projectLanguages: [],
};

const popupSettings = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_PROJECT:
        draft.project = action.payload;
        break;
      case at.SET_PROJECT_TEMPLATES:
        draft.projectTemplates = action.payload;
        break;
      case at.SET_PROJECT_LANGUAGES:
        draft.projectLanguages = action.payload;
        break;
    }
  });

export default popupSettings;
