import PRSwitch from "~components/Generic/PRSwitch";

import CompaniesEdit from "./CompaniesEdit";
import CompaniesList from "./CompaniesList";

function Companies() {
  const routes = [
    { path: "/settings/companies/form/:id?", component: CompaniesEdit },
    { path: "/settings/companies/", component: CompaniesList },
    { path: "/settings", to: "/settings/companies", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default Companies;
