import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { PalIconButton, PalTooltip } from "@palamar/fe-library";
import { useTranslation } from "react-i18next";
import { FaWhatsapp } from "react-icons/fa";
import { MdDragHandle, MdExpandMore, MdFullscreen, MdHelp, MdPlayArrow } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Col, FormGroup, Input, Row } from "reactstrap";

import styled from "@emotion/styled";
import { ArrowBack } from "@mui/icons-material";
import { Box, Divider, Grid, Menu, MenuItem, Modal, Typography } from "@mui/material";

import { ReactComponent as ThreeDotIcon } from "~assets/images/icons/livechat-three-dot.svg";
import useBootstrapBreakpoint from "~common/hooks/useBootstrapBreakpoint";
import useQueryParams from "~common/hooks/useQueryParams";
import UpdateAgentNameModal from "~common/modals/UpdateAgentNameModal";
import UpdateAgentPreferredLanguagesModal from "~common/modals/UpdateAgentPreferredLanguages";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRModal from "~components/Generic/PRModal";
import { PRCard } from "~components/Generic/PRPage";
import PRTab from "~components/Generic/PRTab";
import PRTooltip from "~components/Generic/PRTooltip";
import PalButton from "~components/mui/PalButton";
import PalFilter from "~components/PalFilter";
import {
  LS_LIVECHAT_OPTIONS,
  audioList,
  chatbotSessionPlatformOptions,
  chatbotSessionStatus,
  livechatCallcenterType,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import AudioHelper from "~helpers/AudioHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import StorageHelper from "~helpers/StorageHelper";
import store from "~store";
import { selectIsGetterSetter } from "~store/chathistory/selectors";
import { getChannelList } from "~store/settings/channels/actions";
import { selectChannelList } from "~store/settings/channels/selectors";
import {
  getAgentInfo,
  getCallcenterSettings,
  getProjectSettings,
  setLivechatLoadingStatus,
  setLivechatOptions,
  setViewStatus,
  wsGetNextSession,
  wsSendSubscribeLevel,
  wsStatusUpdate,
} from "~store/socket/livechat/actions";
import { syncLivechatOptions } from "~store/socket/livechat/broadcastActions/broadcast";
import { throttleReloadOtherSessions } from "~store/socket/livechat/saga";
import {
  selectAgentInfo,
  selectCallcenterSettings,
  selectLivechatOptions,
  selectOtherSessionsInfo,
  selectSessionCountDelta,
  selectSessionId,
  selectSessions,
} from "~store/socket/livechat/selectors";
import { selectCurrentProject, selectProjects } from "~store/user/selectors";

import LiveSessionChat, { LiveSessionChatModalProvider } from "./LiveSessionChat";
import LiveSessionList from "./LiveSessionList";
import StartNewSessionModal from "./StartNewSessionModal";
import { LiveSessionGetterSetterContent, LiveSessionGetterSetterDrawer } from "../ChatHistory/LiveSessionGetterSetter";

const StyledPanelResizeHandlerWrapper = styled.span`
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  transition: all 0.2s ease-in-out;
  margin: 0px 4px;
  border-radius: 10px;
  &:active,
  &:focus,
  &:hover {
    background-color: #f7f7f7;
  }
  ${(props) =>
    props.collapsed &&
    `
  background-color: #f7f7f7;
  `}
  svg {
    margin: -0.7rem;
    font-size: 1.5rem;
    color: var(--bs-gray-500);
  }
`;

const StyledDiv = styled.div`
  //3xxl max with bootstrap
  /* @media (min-width: 1920px) { */
  /* max-width: 1920px; */
  /* height: calc(var(--vh) * 100 - 52px); */
  height: ${(props) => (props.isFullscreen ? "calc(var(--vh) * 100 - 52px)" : "calc(var(--vh) * 100 - 152px)")};
  overflow: hidden;
  max-width: 100%;
  /* } */
  .form-check-input {
    background-color: var(--bs-danger);
    border-color: var(--bs-danger);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e") !important;

    &:checked {
      background-color: var(--bs-success);
      border-color: var(--bs-success);
    }
  }
  .card {
    margin: 0;
  }
  .card-body {
    padding: 0px;
    position: relative;
  }
  .pr-tab {
    /* &.nav-tabs-custom { */
    /* border: none; */
    /* } */
    flex-wrap: nowrap;

    background-color: #fff;
    /* margin:0px 2px; */
    .nav-link {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      padding-top: 22px;
    }
    .nav-link.active {
      background-color: #fff;
      /* border-bottom: 1px solid #fff; */
      color: rgba(0, 0, 0, 0.85);
      font-size: 13px;
      font-weight: bold;
      &::after {
        background-color: ${(props) => props.theme.palette.secondary.dark};
        height: 4px;
      }
    }
    .nav-item {
      min-width: 50px;
    }
    /* flex: 0 0 auto;
    width: 100%; */
  }
  .list-header {
    flex: 0 0 auto;
    background-color: #fff;
    margin: 4px;
    padding: 5px 15px 0px;
    /* border-radius: 14px; */
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    .pr-tab {
      .nav-link {
        padding-top: 10px;
      }
    }
    .list-top {
      display: flex;

      .MuiAvatar-root {
        /* margin: 21px 16px; */
        margin-right: 16px;
        margin-top: 5px;
        width: 52px;
        height: 52px;
      }
      .control {
        flex: 1 1 auto;
        overflow: hidden;
        .agent-label {
          margin-top: 6px;
          font-size: 16px;
          font-weight: 500;
          /* line-height: 34px; */
          color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .agent-status {
          .form-switch {
            height: 16px;
            margin-top: 0px;
            margin-bottom: 0px;
            min-height: auto;
            input {
              width: 32px;
              height: 16px;
              margin-top: 0px;
              margin-bottom: 0px;
            }
          }
          .checkbox-label {
            margin-left: 10px;
            font-size: 13px;
            font-weight: 300;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }

      .list-actions {
        display: flex;
        gap: 15px;
        svg {
          height: 18px;
          width: 18px;
        }
        .MuiButtonBase-root {
          width: 36px;
          height: 36px;
          background-color: #f7f7f7;
        }
      }
    }
  }
`;

export const TabCircle = ({ count, selected }) => {
  if (!count) return null;
  return (
    <Box
      sx={{
        width: 24,
        height: 24,
        backgroundColor: selected ? "#535353" : "rgba(83, 83, 83, 0.5)",
        color: "#fff",
        fontSize: count <= 99 ? 12 : count <= 999 ? 11 : 9,
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        marginLeft: "4px",
      }}
    >
      {count}
    </Box>
  );
};
let initialSoundPlayed = false;

const StyledPRContainer = styled(PRContainer)`
  & > .container > .container-inner {
    /* height: calc(var(--vh) * 100 - 152px);
    overflow: hidden; */
  }
`;
export default function LiveChat() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [tab, setTab] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [onlyOneTabHasSessions, setOnlyOneTabHasSessions] = useState(false);
  const [chatLabelList, setChatLabelList] = useState([]);
  const isXxlDown = useBootstrapBreakpoint("xxl");
  const isLgDown = useBootstrapBreakpoint("lg");

  const agentInfo = useSelector(selectAgentInfo);
  const livechatOptions = useSelector(selectLivechatOptions);
  const currentProject = useSelector(selectCurrentProject);
  const projects = useSelector(selectProjects);
  const sessions = useSelector(selectSessions);
  const channels = useSelector(selectChannelList);
  const otherSessionsInfo = useSelector(selectOtherSessionsInfo);
  const callcenterSettings = useSelector(selectCallcenterSettings);
  const sessionCountDelta = useSelector(selectSessionCountDelta);
  const isSessionInfo = useSelector(selectIsGetterSetter);
  const sessionId = useSelector(selectSessionId);
  const open = Boolean(anchorEl);
  const livechatListPanelRef = useRef(null);
  const livechatChatPanelRef = useRef(null);
  const getterSetterPanelRef = useRef(null);
  const [getterSetterPanelCollapsed, setGetterSetterPanelCollapsed] = useState(false);
  // const [callcenterType, setCallcenterType] = useState(callcenterSettings?.callcenter_type);

  const isAgentAvailable = agentInfo?.status === "AV";

  // useEffect(() => {
  //   if (livechatOptions?.type) {
  //     setCallcenterType(callcenterSettings?.callcenter_type);
  //   }
  // }, [callcenterSettings?.callcenter_type]);
  // const isAdminLiveAgent = [userRole.admin].some((role) => currentProject?.permissions?.includes(role));

  const livechatMode = useMemo(() => {
    // if (isAdminLiveAgent) {
    //   return livechatOptions?.type || callcenterSettings?.callcenter_type || livechatCallcenterType.FIFO;
    // }
    return callcenterSettings?.callcenter_type || livechatCallcenterType.FIFO;
  }, [callcenterSettings?.callcenter_type]);

  const activeProjectFilterData = useMemo(() => {
    const filterData = livechatOptions?.filters?.[currentProject?.id] || [];
    return filterData;
  }, [livechatOptions?.filters, currentProject?.id]);

  // useEffect(() => {
  //   (async () => {
  //     const response = await dispatch(getChatLabelList(currentProject?.id));
  //     setChatLabelList(response?.results || []);
  //   })();
  // }, [dispatch, currentProject?.id]);

  useEffect(() => {
    (async () => {
      if (queryParams.fullscreen === "true") {
        setIsFullscreen(true);

        const searchParams = new URLSearchParams(window.location.search);
        // searchParams.delete("fullscreen");

        HistoryHelper.push({
          pathname: window.location.pathname,
          search: searchParams.toString(),
        });
      } else {
        setIsFullscreen(false);
      }
    })();
  }, [queryParams?.fullscreen]);

  useEffect(() => {
    if (!agentInfo?.id) {
      dispatch(getAgentInfo());
    }
    dispatch(setViewStatus("visible"));
    return () => {
      dispatch(setViewStatus("hidden"));
    };
  }, [dispatch, agentInfo?.id]);

  useEffect(() => {
    dispatch(getProjectSettings());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getChannelList(currentProject?.id));
    dispatch(getCallcenterSettings(currentProject?.id));
  }, [dispatch, currentProject?.id]);

  const isWhatsappChannelAvailable = useMemo(() => {
    return true;
  }, [channels]);

  const handleAgentAvailable = (e) => {
    const value = e.target.checked;
    dispatch(wsStatusUpdate(value));
    // dispatch(
    //   updateAgentInfo({
    //     ...agentInfo,
    //     status: value ? "AV" : "UN",
    //   })
    // );
  };

  const setLivechatOption = async (key, value) => {
    const livechatOptionsStore = selectLivechatOptions(store.getState());
    const newOptions = {
      ...livechatOptionsStore,
      [key]: value,
    };
    await StorageHelper.set(LS_LIVECHAT_OPTIONS, newOptions);
    syncLivechatOptions(newOptions);
    dispatch(setLivechatOptions(newOptions));
  };

  // const handleToggleOption = (key) => async (e) => {
  //   const value = e.target.checked;
  //   await setLivechatOption(key, value);
  // };

  const handleTestSound = async (e) => {
    e.stopPropagation();
    Promise.all([AudioHelper.get(audioList.agentWaitingAlert)]).then(async ([audio]) => {
      audio.replay();
    });
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };
  const handleClose = (key, value) => async (e) => {
    // if (key === "livechat") {
    //   handleAgentAvailable({ target: { checked: !isAgentAvailable } });
    //   return;
    // }
    if (key === "alert") {
      setLivechatOption(key, !livechatOptions.alert);
      if (!livechatOptions.alert && !initialSoundPlayed) {
        //Play initial blank sounds to enable sound while tab is not focused.
        Promise.all([AudioHelper.get(audioList.noSound)]).then(async ([audio]) => {
          audio.replay();
          initialSoundPlayed = true;
        });
      }
    } else if (key === "highlight") {
      setLivechatOption(key, !livechatOptions.highlight);
    } else if (key === "continuousAlert") {
      setLivechatOption(key, !livechatOptions.continuousAlert);
    } else if (key === "agent") {
      UpdateAgentNameModal.show();
      setAnchorEl(null);
    } else if (key === "preferredLanguages") {
      UpdateAgentPreferredLanguagesModal.show({ id: agentInfo?.id });
      setAnchorEl(null);
    } else if (key === "newSession") {
      StartNewSessionModal.show();
      setAnchorEl(null);
    } else {
      setAnchorEl(null);
    }
  };
  const handleChangeFilter = (filterItems, filterQuery) => {
    dispatch(setLivechatLoadingStatus(true));
    const queryObj = (filterItems || []).reduce((acc, item) => {
      if (item.type === "text") {
        acc[item.key] = item.value[0];
      } else {
        acc[item.key] = item.value;
      }
      return acc;
    }, {});

    const newFilterOptions = {
      ...livechatOptions?.filters,
      [currentProject?.id]: filterItems,
    };
    setLivechatOption("filters", newFilterOptions);

    dispatch(wsSendSubscribeLevel("all", queryObj));
  };

  useEffect(() => {
    if (projects?.length > 1) {
      const projectFilterObj = {
        type: "options",
        key: "project_ids",
        label: "Projects",
        multiple: true,
        value: [currentProject?.id],
        lookupKey: "in",
        lookupKeys: ["in"],
        defaultLookupKey: "in",
      };

      handleChangeFilter([projectFilterObj]);
    }
  }, [currentProject?.id, projects?.length]);

  // const handleChangeCallcenterType = async (value) => {
  //   // setCallcenterType(e.value);
  //   await setLivechatOption("type", value);
  //   if (value === livechatCallcenterType.Pool) {
  //     throttleReloadOtherSessions();
  //   } else {
  //     handleChangeFilter([]);
  //     dispatch(setLivechatLoadingStatus(false));
  //   }

  //   setAnchorEl(null);
  // };

  const handleOpenSettings = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const { agentChattingCount, agentWaitingCount, chatSessionsCount, isOnlyOneTabHasSessions } = useMemo(() => {
    let agentChattingCount = 0;
    let agentWaitingCount = 0;
    let chatSessionsCount = 0;
    for (const session of sessions) {
      if ([chatbotSessionStatus.AGENT_CHAT].includes(session.session_status)) {
        agentChattingCount++;
      } else if (
        [chatbotSessionStatus.AGENT_WAIT, chatbotSessionStatus.BOT_CHAT_AGENT_WAIT, chatbotSessionStatus.ESW].includes(
          session.session_status
        )
      ) {
        agentWaitingCount++;
        //TODO: temporary. normally should only else directive is enough.
      } else if (![chatbotSessionStatus.ESW, chatbotSessionStatus.ESR].includes(session.session_status)) {
        chatSessionsCount++;
      }
    }
    const isOnlyOneTabHasSessions = [agentChattingCount, agentWaitingCount, chatSessionsCount].findIndex(
      (item) => item && sessions.length == item
    );

    let otherSessionCount = otherSessionsInfo?.count || chatSessionsCount;
    // if (livechatMode === livechatCallcenterType.Pool) {
    //   otherSessionCount = chatSessionsCount;
    // }
    return {
      agentChattingCount,
      agentWaitingCount,
      // chatSessionsCount,
      chatSessionsCount: otherSessionCount,
      isOnlyOneTabHasSessions,
    };
  }, [sessions, otherSessionsInfo?.count, livechatMode]);

  useEffect(() => {
    if (onlyOneTabHasSessions !== isOnlyOneTabHasSessions) {
      setOnlyOneTabHasSessions(isOnlyOneTabHasSessions);
      if (isOnlyOneTabHasSessions > -1) {
        if (tab === 2 && !isAgentAvailable) return;
        setTab(isOnlyOneTabHasSessions + 1);
        if (tab === 3) {
          throttleReloadOtherSessions();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnlyOneTabHasSessions, onlyOneTabHasSessions]);

  const { visibleStatusList, nonVisibleStatusList } = useMemo(() => {
    let visibleStatusList, nonVisibleStatusList;
    if (tab === 1 || livechatMode === livechatCallcenterType.FIFO) {
      visibleStatusList = [chatbotSessionStatus.AGENT_CHAT];
    } else if (tab === 2) {
      visibleStatusList = [
        chatbotSessionStatus.AGENT_WAIT,
        chatbotSessionStatus.BOT_CHAT_AGENT_WAIT,
        chatbotSessionStatus.ESW,
      ];
    } else {
      nonVisibleStatusList = [
        chatbotSessionStatus.AGENT_CHAT,
        chatbotSessionStatus.AGENT_WAIT,
        chatbotSessionStatus.BOT_CHAT_AGENT_WAIT,
        chatbotSessionStatus.ESW,
      ];
    }
    return {
      visibleStatusList,
      nonVisibleStatusList,
    };
  }, [tab, livechatMode]);

  const livechatFilters = useMemo(
    () =>
      [
        projects?.length > 1 && {
          //TODO: check if could be removed
          // onFetch: async (search) => {
          //   return projects.map((item) => ({
          //     label: item.name,
          //     value: item.id,
          //   }));
          // },
          key: "project_ids",
          type: "options",
          label: t("dashboard.livechat.projects"),
          options: projects.map((item) => ({
            label: item.name,
            value: item.id,
          })),
          lookup_keys: ["in"],
          default_lookup_key: "in",
          multiple: true,
          // localFilter: true,
          order: 0,
        },
        {
          key: "platforms",
          type: "options",
          label: t("dashboard.livechat.platform"),
          options: chatbotSessionPlatformOptions,
          lookup_keys: ["exact"],
          default_lookup_key: "exact",
          multiple: true,
          // localFilter: true,
          order: 1,
        },
        {
          type: "text",
          key: "phone_number",
          label: t("common.phoneNumber"),
          lookup_keys: ["icontains"],
          default_lookup_key: "icontains",
          order: 2,
        },
        {
          type: "text",
          key: "email",
          label: t("common.email"),
          lookup_keys: ["icontains"],
          default_lookup_key: "icontains",
          order: 3,
        },
        {
          type: "text",
          key: "full_name",
          label: t("common.fullName"),
          lookup_keys: ["icontains"],
          default_lookup_key: "icontains",
          order: 4,
        },
        {
          type: "text",
          key: "message_content",
          label: t("dashboard.livechat.messageContent"),
          lookup_keys: ["icontains"],
          default_lookup_key: "icontains",
          order: 5,
        },
        // {
        //   key: "label",
        //   type: "options",
        //   label: t("common.label"),
        //   options: chatLabelList.map((item) => ({
        //     label: item.key,
        //     value: item.id,
        //   })),
        //   lookup_keys: ["in"],
        //   default_lookup_key: "in",
        //   multiple: true,
        //   order: 6,
        // },
      ].filter(Boolean),
    [projects, t, chatLabelList]
  );
  const handleClickChatAction = useCallback(
    (action, session) => {
      if (action === "take") {
        if (tab === 3) {
          throttleReloadOtherSessions();
        }
        setTab(1);
      }
    },
    [tab]
  );
  const actions = useMemo(() => {
    return [
      isWhatsappChannelAvailable && {
        tooltipText: t("dashboard.livechat.whatsappButtonTooltip"),
        icon: FaWhatsapp,
        color: "primary",
        link: `/contactcenter/livechat/whatsapp-template-messages/`,
      },
      {
        tooltipText: t("dashboard.livechat.fullscreenButtonTooltip"),
        icon: MdFullscreen,
        color: "primary",
        onClick: () => {
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set("fullscreen", "true");

          HistoryHelper.push({
            pathname: window.location.pathname,
            search: searchParams.toString(),
          });
        },
      },
    ].filter(Boolean);
  }, [isWhatsappChannelAvailable, t]);

  const handleGetNewSession = () => {
    if (sessionCountDelta?.agent_chatting_count > (agentInfo?.max_active_chat_count || 1)) {
      AlertHelper.showWarning(
        t("dashboard.livechat.maxActiveChatCountAlert").format((agentInfo?.max_active_chat_count || 1).toString())
      );
      return;
    }
    dispatch(
      wsGetNextSession({
        project_id: currentProject?.id,
      })
    );
  };

  const handleClickCloseFullscreen = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("fullscreen");

    HistoryHelper.push({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
    setIsFullscreen(false);
  };

  const handleGetterSetterCollapsed = (collapsed) => {
    setGetterSetterPanelCollapsed(collapsed);
  };

  const handleClickArrangeLivechatPanel = (e) => {
    //double clicked
    if (e.detail === 2) {
      const isGetterSetterVisible = !!isSessionInfo && !isXxlDown;
      if (isGetterSetterVisible && (!getterSetterPanelCollapsed || !getterSetterPanelRef.current.getCollapsed())) {
        getterSetterPanelRef.current.collapse();
        setGetterSetterPanelCollapsed(true);
      }
      const panelCount = 2 + (isGetterSetterVisible ? 1 : 0);
      const centerRatio = 100 / panelCount;
      livechatListPanelRef.current.resize(centerRatio);
    }
  };
  const handleToggleCollapseExpanded = (e) => {
    //double clicked
    if (e.detail === 2) {
      if (getterSetterPanelCollapsed) {
        getterSetterPanelRef.current.expand();
      } else {
        getterSetterPanelRef.current.collapse();
      }
      setGetterSetterPanelCollapsed(!getterSetterPanelCollapsed);
    }
  };
  return (
    <StyledPRContainer
      maxWidth
      actions={actions}
      description={t("dashboard.livechat.description")}
      name={t("common.contactCenter")}
      parentName={t("common.livechat")}
    >
      <LiveSessionGetterSetterDrawer />
      <LiveSessionChatModalProvider />
      <LiveChatFullscreenModal open={isFullscreen} onClose={handleClickCloseFullscreen}>
        <StyledDiv isFullscreen={isFullscreen}>
          <PanelGroup autoSaveId="livechat-panel" direction="horizontal">
            <Panel ref={livechatListPanelRef} minSize={20} order={1}>
              <PRCard className="h-100  overflow-hidden" innerClassName="d-flex flex-column h-100">
                <div className="list-header">
                  <div className="list-top">
                    <Box alignItems="center" display="flex" width="100%">
                      <div className="agent-status">
                        <FormGroup switch>
                          <Input checked={isAgentAvailable} type="switch" onChange={handleAgentAvailable} />
                          <div className="checkbox-label">
                            {isAgentAvailable ? t("dashboard.livechat.available") : t("dashboard.livechat.unavailable")}
                          </div>
                        </FormGroup>
                      </div>
                    </Box>
                    <div className="list-actions">
                      {livechatMode === livechatCallcenterType.Pool && (
                        <PalFilter
                          filters={livechatFilters}
                          value={activeProjectFilterData}
                          onChange={handleChangeFilter}
                        />
                      )}

                      {/* <PalIconButton>
                      <SettingsIcon />
                    </PalIconButton> */}
                      <PalIconButton onClick={handleOpenSettings}>
                        <ThreeDotIcon />
                      </PalIconButton>
                      <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        open={open}
                        slotProps={{
                          paper: {
                            elevation: 0,
                            sx: {
                              ".MuiMenuItem-root": {
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "28px",
                              },
                              "&.MuiPaper-root": {
                                padding: "1.5px 25px",
                                borderRadius: "8px",
                                boxShadow: "2px 2px 12px 0 rgba(0, 0, 0, 0.08)",
                                border: "solid 1px #f8f8f8",
                                backgroundColor: "#fff",
                                ".MuiButtonBase-root": {
                                  padding: "10px 6px",
                                },
                                ".MuiDivider-root": {
                                  margin: "4px 0px",
                                },
                              },
                            },
                          },
                        }}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        onClose={handleClose()}
                      >
                        {isWhatsappChannelAvailable && (
                          <MenuItem onClick={handleClose("newSession")}>{t("dashboard.livechat.newSession")}</MenuItem>
                        )}
                        {isWhatsappChannelAvailable && <Divider />}
                        <MenuItem onClick={handleClose("agent")}>{t("dashboard.livechat.editAgentName")}</MenuItem>

                        <MenuItem onClick={handleClose("preferredLanguages")}>
                          {t("dashboard.livechat.editPreferredLanguages")}
                        </MenuItem>
                        <Divider />
                        {/* <MenuItem onClick={handleClose("highlight")}>
                        <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
                          <span className="me-3">Session Highlight</span>
                          <FormGroup switch>
                            <Input readOnly checked={livechatOptions.highlight} type="switch" />
                          </FormGroup>
                        </Box>
                      </MenuItem> */}
                        <MenuItem onClick={handleClose("alert")}>
                          <Grid container alignItems="center" display="flex">
                            <Grid item xs>
                              <span className="me-3">{t("dashboard.livechat.alerts")}</span>
                            </Grid>

                            <Grid
                              item
                              alignItems="center"
                              display="flex"
                              xs="auto"
                              onMouseDown={handleStopPropagation}
                              onMouseEnter={handleStopPropagation}
                              onMouseLeave={handleStopPropagation}
                            >
                              <PalTooltip title={t("dashboard.livechat.testSound")}>
                                <PalIconButton
                                  size="small"
                                  sx={{
                                    "&.MuiButtonBase-root": {
                                      padding: "8px !important",
                                    },
                                  }}
                                  onClick={handleTestSound}
                                >
                                  <MdPlayArrow className="fs-5" />
                                </PalIconButton>
                              </PalTooltip>
                            </Grid>
                            <Grid item alignItems="center" display="flex" xs="auto">
                              <FormGroup switch>
                                <Input readOnly checked={livechatOptions.alert} type="switch" />
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </MenuItem>
                        <MenuItem disabled={!livechatOptions.alert} onClick={handleClose("continuousAlert")}>
                          <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
                            <span className="me-3">
                              {t("dashboard.livechat.continuousAlerts")}
                              <PRTooltip title={t("dashboard.livechat.continuousAlertsTooltip")}>
                                <span>
                                  <MdHelp className="fs-5 ms-1" />
                                </span>
                              </PRTooltip>
                            </span>
                            <FormGroup switch>
                              <Input readOnly checked={livechatOptions.continuousAlert} type="switch" />
                            </FormGroup>
                          </Box>
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>

                  {livechatMode === livechatCallcenterType.FIFO && (
                    <Grid container py={1} spacing={2}>
                      <Grid item xs={6}>
                        <Typography>
                          {t("dashboard.livechat.allSessions")}: {sessionCountDelta?.all_sessions_count}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>
                          {t("dashboard.livechat.waitingSessions")}: {sessionCountDelta?.agent_waiting_count || 0}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <PalButton
                          fullWidth
                          disabled={!sessionCountDelta?.agent_waiting_count || !isAgentAvailable}
                          size="small"
                          variant="outlined"
                          onClick={handleGetNewSession}
                        >
                          {t("dashboard.livechat.getNewSession")}
                        </PalButton>
                      </Grid>
                    </Grid>
                  )}
                  {/* <PalFilter onChange={(filters) => console.log("onChange filters", filters)} /> */}
                  {livechatMode === livechatCallcenterType.Pool && (
                    <PRTab
                      fullWidth
                      noUnderline
                      tab={tab}
                      tabList={[
                        {
                          id: 1,
                          label: (
                            <Row className="g-0 overflow-hidden align-items-center">
                              <Col xs className="text-truncate">
                                {t("dashboard.livechat.agentChatting")}
                              </Col>
                              <Col xs="auto">
                                <TabCircle count={agentChattingCount} selected={tab === 1} />
                              </Col>
                            </Row>
                          ),
                        },
                        {
                          id: 2,
                          label: (
                            <Row className="g-0 overflow-hidden align-items-center">
                              <Col xs className="text-truncate">
                                {t("dashboard.livechat.agentWaiting")}
                              </Col>
                              <Col xs="auto">
                                <TabCircle count={agentWaitingCount} selected={tab === 2} />
                              </Col>
                            </Row>
                          ),
                        },
                        {
                          id: 3,
                          label: (
                            <Row className="g-0 overflow-hidden align-items-center">
                              <Col xs className="text-truncate">
                                {t("dashboard.livechat.chatSessions")}
                              </Col>
                              <Col xs="auto">
                                <TabCircle count={chatSessionsCount} selected={tab === 3} />
                              </Col>
                            </Row>
                          ),
                        },
                      ]}
                      onChange={setTab}
                    />
                  )}
                </div>

                <LiveSessionList
                  nonVisibleStatusList={nonVisibleStatusList}
                  showAssignedToMeOption={tab === 1 && livechatMode === livechatCallcenterType.Pool}
                  visibleStatusList={visibleStatusList}
                />
              </PRCard>
            </Panel>
            {!isLgDown && (
              <>
                <PanelResizeHandle>
                  <StyledPanelResizeHandlerWrapper onClick={handleClickArrangeLivechatPanel}>
                    <MdDragHandle className="rotate-90" />
                  </StyledPanelResizeHandlerWrapper>
                </PanelResizeHandle>
                <Panel ref={livechatChatPanelRef} minSize={20} order={2}>
                  <LiveSessionChat onClickAction={handleClickChatAction} />
                </Panel>
              </>
            )}

            {isSessionInfo && !isXxlDown && (
              <>
                <PanelResizeHandle>
                  <StyledPanelResizeHandlerWrapper
                    collapsed={getterSetterPanelCollapsed}
                    onClick={handleToggleCollapseExpanded}
                  >
                    {getterSetterPanelCollapsed ? (
                      <MdExpandMore className="rotate-90" />
                    ) : (
                      <MdDragHandle className="rotate-90" />
                    )}
                  </StyledPanelResizeHandlerWrapper>
                </PanelResizeHandle>
                <Panel
                  ref={getterSetterPanelRef}
                  collapsible
                  defaultSize={20}
                  minSize={15}
                  order={3}
                  onCollapse={handleGetterSetterCollapsed}
                >
                  <LiveSessionGetterSetterContent projectId={currentProject?.id} sessionId={sessionId} />
                </Panel>
              </>
            )}
          </PanelGroup>
        </StyledDiv>
      </LiveChatFullscreenModal>
    </StyledPRContainer>
  );
}

const StyledLivechatFullscreenDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
  height: 100%;
  padding: 5px;
  z-index: 1405;

  .pr-livechat-sessionlist {
    .scrollbar-container {
      /* height: calc(var(--vh) * 100 - 152px); */
      /* overflow: hidden;
      ${({ historyMode }) => historyMode && "max-height: calc(var(--vh) * 100 - 146px);"} */
    }
  }
  .chat-wrapper {
    .chat {
      .chat-area {
        /* height: calc(100vh - 310px) !important; */
        /* height: ${({ historyMode }) =>
          historyMode ? "calc(var(--vh) * 100 - 210px)" : "calc(var(--vh) * 100 - 310px)"} !important; */
        overflow-y: scroll;
      }
    }
  }
`;

const LiveChatFullscreenButton = styled(PRButton)`
  position: fixed;
  right: 5px;
  bottom: 5px;
`;

export function FullScreenChatModal({ onClose, children, ...rest }) {
  const { t } = useTranslation();
  return (
    <Modal
      open={true}
      sx={{
        zIndex: 1250,
      }}
      onClose={onClose}
      {...rest}
    >
      <Box
        backgroundColor={"#f7f7f7"}
        height={"100%"}
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          alignItems="center"
          backgroundColor={"#f1f1f1"}
          display="flex"
          height={"36px"}
          justifyContent="flex-start"
          sx={{
            borderBottom: "1px solid var(--bs-gray-400);",
            px: 1,
            flex: "0 0 auto",
          }}
        >
          <PalButton color="primary" startIcon={<ArrowBack />} variant="text" onClick={onClose}>
            {t("common.back")}
          </PalButton>
        </Box>

        <Box
          sx={{
            flex: "1 1 0%",
            overflow: "hidden",
          }}
        >
          {children}
        </Box>
      </Box>
    </Modal>
  );
}
export function LiveChatFullscreenModal({ historyMode, onClose, children, open }) {
  const { t } = useTranslation();
  if (!open) return children;
  return (
    <PRModal fullscreen noPadding modalZIndex={1250} submitText="" onClose={onClose}>
      <StyledLivechatFullscreenDiv historyMode={historyMode}>
        {children}

        <Row className="g-2">
          <Col className="justify-content-end w-100 d-flex pt-2">
            <LiveChatFullscreenButton onClick={onClose}>{t("common.close")}</LiveChatFullscreenButton>
          </Col>
        </Row>
      </StyledLivechatFullscreenDiv>
    </PRModal>
  );
}
