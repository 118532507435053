import * as Sentry from "@sentry/react";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";

import rootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25, // Daha iyi performans için sınır koy
      actionsDenylist: [
        "@SOCKET/CHATBOT/WS_OUT_PING",
        "@SOCKET/CHATBOT/SET_LAST_MESSAGE_TIME",
        "@SOCKET/CHATBOT/SET_LAST_OUTGOING_MSG",
        "@SOCKET/CHATBOT/SET_PONG",
        "@SOCKET/CHATBOT/WS_IN_PONG",
        "@SOCKET/LIVECHAT/SET_LAST_MESSAGE_TIME",
        "@SOCKET/LIVECHAT/SET_LAST_OUTGOING_MSG",
        "@SOCKET/LIVECHAT/SET_PONG",
      ],
      maxAge: 500,
    })
  : compose;

/** @type {import("redux").Store} */
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware, thunk), sentryReduxEnhancer));

sagaMiddleware.run(rootSaga);

export default store;
