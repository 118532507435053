import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import { Col, Row } from "reactstrap";

import { Zoom } from "@mui/material";

import useQueryParams from "~common/hooks/useQueryParams";
import ChatTyping from "~components/ChatTyping";
import { chatbotInputRequestType } from "~constants";
import DateHelper from "~helpers/DateHelper";

import CarouselMessage from "./CarouselMessage";
import ChatProfileImage from "./ChatProfileImage";
import ChoicesMessage from "./ChoicesMessage";
import MessageFile from "./FileMessage";
import ImageMessage from "./ImageMessage";
import InputRequestMessage from "./InputRequestMessage";
import LocationMessage from "./LocationMessage";
import NotificationMessage from "./NotificationMessage";
import SimpleMessage from "./SimpleMessage";
import VideoMessage from "./VideoMessage";
import { scrollChatToBottom } from "../ChatUtils";
import { domChatContainerId, domChatScrollContainerId } from "../constants";
import {
  usePRAnimationContext,
  usePRMessagesContext,
  usePRRecognitionContext,
  usePRRefContext,
  usePRScrollStateContext,
  usePRTypingContext,
} from "../context";

const MessageGroup = memo(function MessageGroup({
  disableAvatar,
  isLast,
  order,
  messageList,
  reverseChat,
  agentLogo,
  botLogo,
  botJoinedLogo,
}) {
  const [containerRef, setContainerRef] = useState(null);
  const handleRef = useCallback((ref) => {
    setContainerRef(ref);
  }, []);

  const imageRef = useRef(null);
  const messages = messageList?.map?.((message, index, arr) => {
    return (
      <GenericMessage
        key={`${order}-${index}-${message?.position}`}
        agentLogo={agentLogo}
        botJoinedLogo={botJoinedLogo}
        botLogo={botLogo}
        disableAvatar={disableAvatar}
        footerComponent={message?.footerComponent}
        id={`${order}-${index}-${message?.position}`}
        index={index}
        isLast={isLast && index === arr.length - 1}
        isLastInGroup={index === arr.length - 1}
        isSent={!!message?.isSent}
        length={messageList.length}
        message={message}
        reverseChat={reverseChat}
      />
    );
  });
  // const lastMessage = messageList?.[messageList.length - 1];

  useEffect(() => {
    if (containerRef) {
      if (imageRef.current) {
        imageRef?.current?.style?.setProperty("margin-top", `${containerRef?.clientHeight - 32}px`);
      }
      const observer = new ResizeObserver((entries) => {
        const { height } = entries[0].contentRect;
        if (height > 5000) {
          // To prevent infinite loop for unexpected cases
          return;
        }
        imageRef?.current?.style?.setProperty("margin-top", `${height - 32}px`);
      });
      observer.observe(containerRef);
      return () => observer.disconnect();
    }
  }, [containerRef]);

  const { isSingleGroup, component } = messageList?.[0] || {};
  const RenderComponent = isSingleGroup ? component || "div" : "div";
  return (
    <RenderComponent
      ref={handleRef}
      className="d-flex flex-column"
      style={{
        gap: 15,
      }}
    >
      {messages}
    </RenderComponent>
  );
});

function roundToUpper(num) {
  if (num <= 0) {
    return 0;
  } else if (num <= 10) {
    return Math.ceil(num / 10) * 10;
  } else if (num <= 100) {
    return Math.ceil(num / 50) * 50;
  } else {
    return Math.ceil(num / 1000) * 1000;
  }
}

const GenericMessage = memo(function GenericMessage({
  length,
  index,
  id,
  message,
  reverseChat,
  isSent,
  footerComponent,
  isLast,
  isLastInGroup,
  disableAvatar,
  botJoinedLogo,
  agentLogo,
  botLogo,
}) {
  let content;
  const distributedRef = usePRRefContext();
  const { showAppearAnimation } = usePRAnimationContext();

  const { messageId } = useQueryParams();

  if (message.type === "text") {
    content = (
      <SimpleMessage
        attachments={message.attachments}
        footerComponent={footerComponent}
        format={message.format}
        historyId={message.historyId}
        isLast={isLast}
        isRag={message.isRag}
        isSent={isSent}
        isSuggestion={message.isSuggestion}
        likeStatus={message.likeStatus}
        messageId={message.historyId || message.id}
        messageTime={message.messageTime}
        position={message.position}
        reverseChat={reverseChat}
        sender={message.sender}
        text={message.text}
        textDetails={message.textDetails}
        trendyolMsg={message.trendyolMsg}
      />
    );
  } else if (message.type === "typing") {
    content = (
      <SimpleMessage
        historyId={message.historyId}
        isRag={message.isRag}
        messageId={message.historyId || message.id}
        messageTime={message.messageTime}
        position={message.position}
        sender={message.sender}
        // trendyolMsg={message.trendyolMsg}
        // textDetails={message.textDetails}
      >
        <ChatTyping />
      </SimpleMessage>
    );
  } else if (message.type === "video" || message.imageUrl?.endsWith(".mp4")) {
    content = (
      <VideoMessage
        fileName={message.fileName}
        format={message.format}
        historyId={message.historyId}
        isLast={isLast}
        isRag={message.isRag}
        isSent={isSent}
        likeStatus={message.likeStatus}
        messageId={message.historyId || message.id}
        messageTime={message.messageTime}
        position={message.position}
        sender={message.sender}
        text={message.text}
        textDetails={message.textDetails}
        url={message.imageUrl}
      />
    );
  } else if (message.type === "image") {
    content = (
      <ImageMessage
        fileName={message.fileName}
        files={message.files}
        format={message.format}
        historyId={message.historyId}
        imageUrl={message.imageUrl}
        isLast={isLast}
        isRag={message.isRag}
        isSent={isSent}
        isSuggestion={message.isSuggestion}
        likeStatus={message.likeStatus}
        messageId={message.historyId || message.id}
        messageTime={message.messageTime}
        position={message.position}
        sender={message.sender}
        text={message.text}
        textDetails={message.textDetails}
      />
    );
  } else if (message.type === "location") {
    content = (
      <LocationMessage
        format={message.format}
        historyId={message.historyId}
        isLast={isLast}
        isRag={message.isRag}
        isSent={isSent}
        likeStatus={message.likeStatus}
        locationInfo={message.locationInfo}
        messageId={message.historyId || message.id}
        messageTime={message.messageTime}
        position={message.position}
        sender={message.sender}
      />
    );
  } else if (
    message.type === "inputRequest" &&
    message.inputFormat.type === chatbotInputRequestType.location &&
    message.inputFormat?.data?.machine_data
  ) {
    content = (
      <LocationMessage
        format={message.format}
        historyId={message.historyId}
        isLast={isLast}
        isRag={message.isRag}
        isSent={isSent}
        likeStatus={message.likeStatus}
        locationInfo={message.inputFormat?.data?.machine_data}
        messageId={message.id}
        messageTime={message.messageTime}
        position={message.position}
        sender={message.sender}
      />
    );
  } else if (message.type === "choice") {
    content = (
      <ChoicesMessage
        choices={message.choices}
        format={message.format}
        isLast={isLast}
        isRag={message.isRag}
        isSent={isSent}
        isSuggestion={message.isSuggestion}
        messageTime={message.messageTime}
        position={message.position}
        sender={message.sender}
        text={message.text}
        textDetails={message.textDetails}
      />
    );
  } else if (message.type === "dynamic_carousel") {
    content = (
      <CarouselMessage
        carouselData={message.carouselData}
        format={message.format}
        isLast={isLast}
        isRag={message.isRag}
        isSent={isSent}
        messageTime={message.messageTime}
        position={message.position}
        sender={message.sender}
        text={message.text}
      />
    );
  } else if (message.type === "notification") {
    content = <NotificationMessage messageTime={message.messageTime} text={message.text} />;
  } else if (message.type === "inputRequest") {
    content = (
      <InputRequestMessage
        fileList={message.fileList}
        fileName={message.fileName}
        fileUrl={message.fileUrl}
        historyId={message.historyId}
        inputFormat={message.inputFormat}
        isLast={isLast}
        isRag={message.isRag}
        isSent={isSent}
        loading={message.loading}
        messageId={message.historyId || message.id}
        messageTime={message.messageTime}
        position={message.position}
        reverseChat={reverseChat}
        sender={message.sender}
        text={message.text}
        type={message.type}
      />
    );
  } else if (message.type === "file") {
    content = (
      <MessageFile
        files={message.files}
        historyId={message.historyId}
        isLast={isLast}
        isRag={message.isRag}
        isSent={message.isSent}
        likeStatus={message.likeStatus}
        messageId={message.historyId || message.id}
        messageTime={message.messageTime}
        position={message.position}
        sender={message.sender}
        text={message.text}
        type={message.type}
      />
    );
  } else if (message.type === "custom") {
    const isReact = typeof message.render === "object" && message.render !== null && message.render.$$typeof;
    if (isReact) {
      content = message.render;
    } else if (typeof message.render === "function") {
      content = message.render({ distributedRef: distributedRef });
    } else {
      console.error("Invalid custom message. Please check your 'render' prop", message);
    }
  }
  const zIndex = roundToUpper(length) - index + 10;
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        const dom = document.getElementById(id);
        if (dom) {
          dom.scrollIntoView({ behavior: "smooth", block: "center" });
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        }
      }
    };

    if (messageId === message?.historyId?.toString()) {
      if (!document.hidden) {
        const dom = document.getElementById(id);
        if (dom) {
          dom.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      } else {
        document.addEventListener("visibilitychange", handleVisibilityChange);
      }
    }

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [messageId, message.historyId, id]);

  return (
    <Col
      id={id}
      style={{
        display: "flex",
        flexDirection: message?.position === "left" ? "row" : "row-reverse",
        alignItems: "center",
        ...(message?.position === "center" && { justifyContent: "center" }),
        zIndex: zIndex,
        maxWidth: "100%",
      }}
      xs="12"
    >
      <Row className="g-0 align-items-end" style={{ maxWidth: "100%" }}>
        <Zoom
          appear={isLast && showAppearAnimation && message.type !== "typing"}
          in={true}
          style={{
            transformOrigin: "center " + message?.position,
          }}
          timeout={150}
        >
          <span>
            <Row className="g-0 gap-2 align-items-end" style={{ maxWidth: "100%" }}>
              {message?.position === "left" && !reverseChat && !disableAvatar && (
                <Col xs="auto">
                  <ChatProfileImage
                    agentLogo={agentLogo}
                    botJoinedLogo={botJoinedLogo}
                    botLogo={botLogo}
                    message={message}
                    visible={isLastInGroup}
                  />
                </Col>
              )}
              <Col
                xs
                style={{
                  overflowX: "hidden",
                }}
              >
                {content}
              </Col>
              {message?.position === "right" && reverseChat && !disableAvatar && (
                <Col xs="auto">
                  <ChatProfileImage
                    agentLogo={agentLogo}
                    botJoinedLogo={botJoinedLogo}
                    botLogo={botLogo}
                    message={message}
                    visible={isLastInGroup}
                  />
                </Col>
              )}
            </Row>

            {/* <div>{content}</div> */}
          </span>
        </Zoom>
      </Row>
    </Col>
  );
});

let typingMessage;
export default function InlineMessages({ disableAvatar, senderType, reverseChat, agentLogo, botLogo, botJoinedLogo }) {
  const { messageId } = useQueryParams();
  const messages = usePRMessagesContext();
  const typing = usePRTypingContext();
  const recognition = usePRRecognitionContext();
  const isScrollingRef = useRef(false);
  const [windowsHeight, setWindowsHeight] = useState();
  const { state: scrollState, setState: setScrollState } = usePRScrollStateContext();
  const [firstScroll, setFirstScroll] = useState(!!messageId);

  const [groupedMessages, setGroupedMessages] = useState([...messages]);
  const distributedRef = usePRRefContext();
  const leftMessages = useMemo(
    () => messages?.filter((message) => message.position === "left" && !message.skipMessageScrollCounter) || [],
    [messages]
  );

  useEffect(() => {
    const chatContainerRef = document.getElementById(domChatScrollContainerId);
    const handleScroll = (e) => {
      if (isScrollingRef.current) {
        setScrollState((prevState) => ({ ...prevState, lastScrollTop: chatContainerRef.scrollTop }));
        return;
      }
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef;
      const atBottom = scrollHeight - scrollTop < clientHeight + 15;
      const scrollingUp = scrollTop < scrollState.lastScrollTop;

      if (atBottom) {
        setScrollState({ lockedToBottom: true, lastScrollTop: scrollTop });
      } else if (scrollingUp && scrollState.lockedToBottom) {
        setScrollState({
          lockedToBottom: false,
          lastScrollTop: scrollTop,
          receivedMessageCountAtLocked: leftMessages.length,
        });
      } else {
        setScrollState((prevState) => ({ ...prevState, lastScrollTop: scrollTop }));
      }
    };

    const chatContainer = chatContainerRef;
    chatContainer.addEventListener("scroll", handleScroll);

    return () => {
      chatContainer.removeEventListener("scroll", handleScroll);
    };
  }, [scrollState.lockedToBottom, scrollState.lastScrollTop]);

  useEffect(() => {
    //Update the state to force the scroll to bottom if scroll is locked to bottom
    const handleResize = debounce(() => {
      setWindowsHeight(window.innerHeight);
    }, 300);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (scrollState.lockedToBottom && scrollState.scrollOnMessage) {
      let cancelled = false;
      let isInitCalled = false;
      setTimeout(() => {
        if (distributedRef.current.disableScrollOnMessage || cancelled || isInitCalled || messageId) return;
        isScrollingRef.current = true;
        // console.log(" scrollChatToBottom('instant') init");
        scrollChatToBottom("instant").then(() => {
          isScrollingRef.current = false;
          isInitCalled = true;
        });
      }, 200);

      const dom = document.getElementById(domChatContainerId);

      const observer = new ResizeObserver(() => {
        if (distributedRef.current.disableScrollOnMessage || cancelled || messageId) return;
        isScrollingRef.current = true;
        // console.log(" scrollChatToBottom('instant') modify");
        scrollChatToBottom("instant").then(() => {
          isScrollingRef.current = false;
          isInitCalled = true;
        });
      });
      observer.observe(dom);
      return () => {
        observer.disconnect();
        cancelled = true;
      };
    }
  }, [messages, scrollState.scrollOnMessage, scrollState.scrollOnMessage, innerHeight, messageId]);

  useEffect(() => {
    const messageList = [...messages];

    if (typing) {
      typingMessage = {
        type: "typing",
        position: "left",
        sender_type: senderType,
        messageTime: DateHelper.getDateTime(),
      };
      messageList.push(typingMessage);
    }
    if (recognition) {
      typingMessage = {
        type: "recognition",
        position: "center",
        sender_type: senderType,
        messageTime: DateHelper.getDateTime(),
      };
      messageList.push(typingMessage);
    }
    const positionAndReplierGroupedMessages = [];

    const firstNonSingleMessage = messageList.find((message) => !message.isSingleGroup);
    let tempPosition = firstNonSingleMessage?.position;
    let tempSender = firstNonSingleMessage?.sender_type;
    let tempGroup = [];

    for (const message of messageList) {
      // Regroup messages if length is greater than 10 to better observable
      if (message.isSingleGroup) {
        positionAndReplierGroupedMessages.push([message]);
      } else if (
        message.position === tempPosition &&
        (message.sender_type === tempSender || message === typingMessage)
      ) {
        tempGroup.push(message);
      } else {
        positionAndReplierGroupedMessages.push(tempGroup);
        tempGroup = [message];
        tempPosition = message.position;
        tempSender = message.sender_type;
      }
    }
    positionAndReplierGroupedMessages.push(tempGroup);

    setGroupedMessages(positionAndReplierGroupedMessages);
  }, [typing, messages, senderType, recognition]);

  return (
    <Row
      className="g-0 "
      id={domChatContainerId}
      style={{
        rowGap: "20px",
      }}
    >
      {groupedMessages.map((messageList, index, arr) => {
        return (
          <MessageGroup
            key={index}
            agentLogo={agentLogo}
            botJoinedLogo={botJoinedLogo}
            botLogo={botLogo}
            disableAvatar={disableAvatar}
            id={`${index}-${messageList?.position}`}
            isLast={index === arr.length - 1}
            messageList={messageList}
            order={index}
            reverseChat={reverseChat}
          />
        );
      })}
    </Row>
  );
}
