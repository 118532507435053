import PRSwitch from "~components/Generic/PRSwitch";
import Channels from "~pages/Integration/Channels";
import ECommerce from "~pages/Integration/ECommerce";

function Integration() {
  const routes = [
    { path: "/integration/channels/", component: Channels },
    { path: "/integration/e-commerce/", component: ECommerce },
    // { path: "/integration/module/", component: Module },
    // { path: "/integration/module-integration/", component: ModuleIntegration },
    // { path: "/integration", to: "/integration/module", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default Integration;
