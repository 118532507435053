import { useFormik } from "formik";
import Drawer from "rc-drawer";
import { useTranslation } from "react-i18next";
import { MdChevronLeft, MdChevronRight, MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import styled from "@emotion/styled";
import { Collapse } from "@mui/material";

import useBootstrapBreakpoint from "~common/hooks/useBootstrapBreakpoint";
import { useStorage } from "~common/hooks/useStorage";
import PRAccordion, { PRAccordionItem } from "~components/Generic/PRAccordion";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRDate from "~components/Generic/PRDate";
import PRImg from "~components/Generic/PRImg";
import PRInput from "~components/Generic/PRInput";
import { PRCard } from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import DateHelper from "~helpers/DateHelper";
import { setGetterSetterAction, setIsGetterSetterDrawerOpen, setSessionGetterSetter } from "~store/chathistory/actions";
import { selectIsGetterSetterDrawerOpen, selectSessionGetterSetter } from "~store/chathistory/selectors";

// Types: text, email, number, boolean, object, select, date, profile, button

const StyledRow = styled(Row)`
  /* @media (min-width: 1400px) {
    height: calc(100vh - 310px);
  }
  @media (max-width: 1399px) {
    height: calc(100% - 20px);
  } */
  /* margin: 10px -2px; */
  overflow-y: auto;
`;

const StyledCard = styled(PRCard)`
  /* .card { */
  /* ${({ isXl }) => (isXl ? "height: 100%;" : "")} */
  /* overflow-y: auto; */

  /* @media (max-width: 1400px) and (min-width: 993px) {
    height: calc(100vh - 100px);
  } */
  /* @media (max-width: 992px) { */
  /* height: 100vh; */
  /* } */
  /* padding-left: 0px;
  padding-right: 8px; */
  /* @media (min-width: 1400px) {
    width: 20vw;
    padding-left: 0;
  } */

  height: 100%;
  padding: 12px;
  .card-body {
    flex-direction: column;
    align-items: stretch;
  }
  .accordion-item {
    --bs-accordion-border-color: #ddd;
  }
  .accordion-body {
    padding-left: 8px;
    padding-right: 8px;
  }
  .accordion-header {
    & > .accordion-button {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  /* } */
`;

const headerHeight = 72; // $header-height: 100px;
const headerHeightSm = 54; //  $header-height-sm: 54px;

const StyledDrawer = styled(Drawer)`
  position: fixed;
  top: 0;
  /* ${({ isLg }) => (isLg ? "" : "margin-top: 100px; height: calc(100vh - 100px);")} */
  /* @media (max-width: 12px) { */
  right: 0;
  /* width: ${({ isSm }) => (isSm ? "100%" : "420px")}; */
  @media (max-width: 1400px) and (min-width: 993px) {
    width: 50%;
    margin-top: ${headerHeight}px;
    height: calc(100vh - ${headerHeight}px);
  }
  @media (max-width: 992px) {
    width: 100vw;
  }
  .guide {
    height: 100%;
  }
  .card {
    height: calc(100% - 60px);
    margin-bottom: 0;
  }
  .card-body {
    height: 100%;
  }
  /* .rc-drawer-content-wrapper { */
  box-shadow: 32px 50px 30px 35px var(--bs-gray-500);
  /* } */
`;

const StyledCollapseCard = styled(PRCard)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  .card-body {
    display: flex;
    flex-direction: row;
  }
`;

const StyledButton = styled(PRButton)`
  height: calc(100% - 16px);
  width: 12px;
  border: none;
  border-radius: 14px;
  background-color: var(--bs-body-light);
  margin: 8px;
  padding-left: 8px;
  padding-right: 8px;

  /* ${({ isOpen }) => (isOpen ? `border-top-right-radius: 0; border-bottom-right-radius: 0;` : ``)} */
  //On hoover, change the background color to light gray
  &:hover {
    /* border: solid 1px #ddd; */
    background-color: white;
    color: var(--bs-btn-color);
  }
`;

// const

export function LiveSessionGetterSetterContent({ sessionId, projectId }) {
  const input = useSelector(selectSessionGetterSetter);
  const dispatch = useDispatch();

  const handleBlockChange = (index) => (newBlock) => {
    const newGetterSetter = [...input];
    newGetterSetter[index] = newBlock;
    dispatch(setSessionGetterSetter(newGetterSetter));
  };

  const onButtonClick = async (action) => {
    sendAction(action);
  };

  const sendAction = async (action) => {
    const body = {
      info_blocks: input,
      action,
    };
    await dispatch(setGetterSetterAction(projectId, sessionId, body));
  };

  if (!input?.length || !input?.map) return null;

  return (
    <StyledCard>
      <StyledRow className="gx-0">
        <Col>
          <PRContainer bare>
            <PRAccordion>
              {input?.map((item, index) => (
                <LiveSessionGetterSetterBlock
                  key={index}
                  block={item}
                  onChange={handleBlockChange(index)}
                  onClick={onButtonClick}
                />
              ))}
            </PRAccordion>
          </PRContainer>
        </Col>
      </StyledRow>
    </StyledCard>
  );
}

export function LiveSessionGetterSetterDrawer({ sessionId, projectId }) {
  const input = useSelector(selectSessionGetterSetter);
  const dispatch = useDispatch();
  const isLg = useBootstrapBreakpoint("lg");
  const { t } = useTranslation();
  const isGetterSetterDrawerOpen = useSelector(selectIsGetterSetterDrawerOpen);

  const closeGetterSetter = () => {
    dispatch(setIsGetterSetterDrawerOpen(false));
  };

  if (!input?.length || !input?.map) return null;

  if (!isGetterSetterDrawerOpen) return null;
  return (
    <StyledDrawer
      destroyOnClose
      open={isGetterSetterDrawerOpen}
      rootClassName=""
      zIndex={isLg ? 1260 : 1050}
      onClose={closeGetterSetter}
    >
      <div className="guide">
        <Row className="g-0 guide-title">
          <Col xs="auto">
            <h3 className="mb-0 d-flex align-items-center">{t("component.getterSetter.sessionInfo")}</h3>
          </Col>
          <Col xs="auto">
            <PRButton
              noBorder
              outline
              className="d-flex align-items-center"
              color="dark"
              icon={MdClose}
              onClick={closeGetterSetter}
            />
          </Col>
        </Row>
        <LiveSessionGetterSetterContent projectId={projectId} sessionId={sessionId} />
      </div>
    </StyledDrawer>
  );
}

export default function LiveSessionGetterSetter({ sessionId, projectId }) {
  const input = useSelector(selectSessionGetterSetter);
  const dispatch = useDispatch();
  const isXxl = useBootstrapBreakpoint("xxl");
  const isLg = useBootstrapBreakpoint("lg");
  const { t } = useTranslation();
  const [isGetterSetterCollapseOpen, setIsGetterSetterCollapseOpen] = useStorage("isGetterSetterCollapseOpen", false);
  const isGetterSetterDrawerOpen = useSelector(selectIsGetterSetterDrawerOpen);

  const closeGetterSetter = () => {
    dispatch(setIsGetterSetterDrawerOpen(false));
  };

  const handleCollapseClick = () => {
    setIsGetterSetterCollapseOpen(!isGetterSetterCollapseOpen);
  };

  // useEffect(() => {
  //   console.log("input: ", input);
  // }, [input]);

  if (!input?.length || !input?.map) return null;

  if (isXxl) {
    if (!isGetterSetterDrawerOpen) return null;
    return (
      <StyledDrawer
        destroyOnClose
        open={isGetterSetterDrawerOpen}
        rootClassName=""
        zIndex={isLg ? 1260 : 1050}
        onClose={closeGetterSetter}
      >
        <div className="guide">
          <Row className="g-0 guide-title">
            <Col xs="auto">
              <h3 className="mb-0 d-flex align-items-center">{t("component.getterSetter.sessionInfo")}</h3>
            </Col>
            <Col xs="auto">
              <PRButton
                noBorder
                outline
                className="d-flex align-items-center"
                color="dark"
                icon={MdClose}
                onClick={closeGetterSetter}
              />
            </Col>
          </Row>
          <LiveSessionGetterSetterContent projectId={projectId} sessionId={sessionId} />
        </div>
      </StyledDrawer>
    );
  }
  return (
    <StyledCollapseCard>
      <StyledButton outline color="dark" isOpen={isGetterSetterCollapseOpen} onClick={handleCollapseClick}>
        {isGetterSetterCollapseOpen ? <MdChevronRight /> : <MdChevronLeft />}
      </StyledButton>
      <Collapse in={isGetterSetterCollapseOpen} orientation="horizontal">
        <LiveSessionGetterSetterContent projectId={projectId} sessionId={sessionId} />
      </Collapse>
    </StyledCollapseCard>
  );
}

function LiveSessionGetterSetterBlock({ block, onChange, onClick }) {
  const onItemChange = (index) => (value) => {
    const newBlock = { ...block, fields: [...block.fields] };
    newBlock.fields[index] = { ...newBlock.fields[index], value };
    onChange(newBlock);
  };
  const onButtonClick = (action) => {
    onClick(action);
  };

  return (
    <PRAccordionItem title={block.block_name}>
      {block.fields.map((item, index) => (
        <LiveSessionGetterSetterItem key={index} item={item} onChange={onItemChange(index)} onClick={onButtonClick} />
      ))}
    </PRAccordionItem>
  );
}

const StyledDiv = styled.div`
  display: flex;
  align-items: left;
  flex-direction: ${({ isCheckbox }) => (isCheckbox ? "row" : "column")};
  margin-bottom: 10px;
  //Take a parameter named isCheckbox and if it is true, change the flex-direction to row
`;

const StyledInput = styled(PRInput)`
  margin-left: ${(props) => (props.type === "checkbox" ? "10px" : "0px")};
`;

function LiveSessionGetterSetterItem({ item, onChange, onClick }) {
  const type = item.type.toLowerCase();
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      field: item.field,
      label: item.label,
      value: item.type === "date" ? item.value.toString().slice(0, -1) + ".00Z" : item.value, //TODO: change this after the bug in the backend is fixed
      type: item.type,
      options: item.options,
      style: item.style,
      read_only: item.read_only,
      action: item.action,
    },
    validationSchema: Yup.object({
      field: Yup.string().required(t("common.required")),
      label: Yup.string().required(t("common.required")),
      value: Yup.mixed()
        .required(t("common.required"))
        .when("type", {
          is: "number",
          then: Yup.number().required(t("common.required")),
        })
        .when("type", {
          is: "date",
          then: Yup.date().required(t("common.required")),
        })
        .when("type", {
          is: "boolean",
          then: Yup.boolean().required(t("common.required")),
        })
        .when("type", {
          is: "email",
          then: Yup.string().email(t("formik.invalid.email")).required(t("common.required")),
        })
        .when("type", {
          is: Yup.string().oneOf(["select", "text"]),
          then: Yup.string().required(t("common.required")),
        })
        .when("type", {
          is: "profile",
          then: Yup.object().required(t("common.required")),
        }),
      type: Yup.string()
        .required(t("common.required"))
        .equals(
          ["text", "email", "number", "boolean", "select", "date", "profile", "button"],
          t("component.getterSetter.invalidType")
        ),
      options: Yup.object()
        .nullable()
        .when("type", {
          is: "select",
          then: Yup.array().of(
            Yup.object({
              label: Yup.string().required(t("common.required")),
              value: Yup.string().required(t("common.required")),
            })
          ),
        }),
      style: Yup.object(),
      read_only: Yup.boolean().required(t("common.required")),
      isPrimitiveValue: Yup.boolean().required(t("common.required")),
      action: Yup.string()
        .nullable()
        .when("type", {
          is: "button",
          then: Yup.string().required(t("common.required")),
        }),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });
  const handleInputChange = (value) => {
    // console.log(value);
    if (formik.type === "date") {
      value = DateHelper.format(value);
    }
    formik.setFieldValue("value", value);
    onChange(value);
  };

  const onButtonClick = () => {
    onClick(formik.values.action);
  };

  let content = null;
  switch (type) {
    case "date":
      content = (
        <>
          <PRDate
            fullWidth
            disabled={formik.values.read_only}
            invalid={formik.errors.value}
            label={formik.values.label}
            value={DateHelper.getDateTime(formik.values.value)}
            onChange={handleInputChange}
          />
        </>
      );
      break;
    case "number":
      content = (
        <>
          <Label>{formik.values.label}</Label>
          <StyledInput
            disabled={formik.values.read_only}
            readOnly={formik.values.read_only}
            type={type}
            value={formik.values.value}
            onChange={(e) => handleInputChange(e.target.value)}
          />
        </>
      );
      break;
    case "text":
    case "email":
      content = (
        <>
          <Label>{formik.values.label}</Label>
          <StyledInput
            disabled={formik.values.read_only}
            invalid={formik.errors.value}
            readOnly={formik.values.read_only}
            type={type}
            value={formik.values.value}
            onChange={(e) => handleInputChange(e.target.value)}
          />
        </>
      );
      break;
    case "boolean":
      content = (
        <>
          <Label>{formik.values.label}</Label>
          <StyledInput
            isPrimitiveValue
            checked={formik.values.value}
            invalid={formik.errors.value}
            readOnly={formik.values.read_only}
            type="checkbox"
            onChange={(e) => handleInputChange(e.target.checked)}
          />
        </>
      );
      break;
    case "select":
      content = (
        <>
          <Label>{formik.values.label}</Label>
          <PRSelect
            isPrimitiveValue
            invalid={formik.errors.value}
            options={formik.values.options}
            value={formik.values.value}
            onChange={handleInputChange}
          />
        </>
      );
      break;
    case "profile":
      content = (
        <>
          <Label>{formik.values.label}</Label>
          <div>
            <PRImg
              alt={"image" + formik.values.value.name}
              src={formik.values.value.profile_image}
              style={{ height: "100px" }}
            />
            <span>{formik.values.value.name}</span>
          </div>
        </>
      );
      break;
    case "button":
      content = <PRButton onClick={onButtonClick}>{formik.values.label}</PRButton>;
      break;
    default:
      content = null;
  }
  return <StyledDiv isCheckbox={type === "boolean"}>{content}</StyledDiv>;
}
